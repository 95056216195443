//add dinamic tables
import axios from 'axios';
import api_idb from '@/api/idb'

        const state = {

        };

const getters = {};

const mutations = {

};

const actions = {

    async  vuex_set__dynamic_desks( {commit}, conf) {

        let table = {};
        table['table_name'] = conf['table_name'];
        
        table['table_data'] = {};
        table['_routes_'] = conf['_routes_'];



        //добавить в несколько секций....
        for (const [_routes_key, _routes_value] of Object.entries(conf['_routes_'])) { 
            table['_route_']=_routes_value;
            
            for (const [section_key, section_value] of Object.entries(conf['_sections_'])) {
                table['_section_'] = section_value;
                
                commit('setvx__new_is_required_sections_desks', table, {root: true});
            }
            
            commit('setvx__new_blocked_actions_desks', table, {root: true});
        }
        
        
        





    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}