import axios from 'axios';
import api_idb from '@/api/idb' 
 
const state = {
        
    };
  

const getters = { 
   
    
}; 
 
  
const mutations = {
  
  
    
};
  
const actions = {
    
    
    vuex_set__is_loaded_tables__load_status( {commit}, conf) { 
        commit('setvx__is_loaded_tables__load_status', conf, {root: true}); 
    },
    
       
    
     //ОТРАБОТАЕТ АСИНХРОННО   - можно запустить в паралель подгрузку многих таблиц, ну и что, что будет несколько запросов.
   async vuex_set__json_data__version_ls_or_back ({ commit }, conf) { 
                    
         let table_name=conf['table_name'];   
         let table_module=conf['table_module'];
         let table_version=conf['table_version'];
         
                    
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']=table_name;
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        
         
        let params=conf['params']  //link
        
     
    
        //проверим данные в api_idb    //IndexedDB=IDB 
        let table={};  //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        table = await api_idb.loadTable(table_name) 
        table['table_module']=table_module;
        table['is_loaded_tables']={};        
        table['is_loaded_tables']['load_status']=false;
        table['is_loaded_tables']['error_send']=false;
        table['is_loaded_tables']['error_api']=false;
        //table['is_loaded_tables']['error_idb_load']=false; устанавливается во время загрузки таблицы из Хранилища
        table['is_loaded_tables']['error_idb_save']=false;
      
     
      
        //если данных в IDB нету ...         "version_timestamp": "1654272997",
        if (
                !table.hasOwnProperty('table_name') || 
                !table.hasOwnProperty('table_data') || 
                !table['table_data'].hasOwnProperty('version_timestamp') || 
                table['table_data']['version_timestamp'] !== table_version
                )
        {
          
         //блокировка флага в списке флогов в разрезе страницы
           //cdata['flags']['is_action'][login_flag_name] = true;      
                   
           let response_data={};        
               
            console.log(conf['server_cloud']+conf['params']['link']);
          await axios.get(conf['server_cloud']+conf['params']['link'], params)
                            .then(response =>
                            {
                               // console.log(response['data']); 
                                 console.log("dyntab_"+table_name+" LOAD_FROM_BACK");  
                                response_data=response['data'];
                                 
                                table['is_loaded_tables']['load_status']=true;  
                               
                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                //cdata['flags']['is_error']['sending'] = true;
                                table['is_loaded_tables']['error_send']=true;
                                
                                //cdata['flags']['is_action'][login_flag_name] = false;

                            }).then(function () { });          
               
             //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
             //console.log("load_status="+table['is_loaded_tables']['load_status']);
             
             table['table_name']=table_name;
             
             if (table['is_loaded_tables']['load_status'])
             {
                //let table_data=response_data;
                
                table['table_data']=response_data;
              
                //console.log(table);
                
                try {
                    await api_idb.saveTable(table) 
                    
                    console.log(table_name+", SAVE_TO_STORE");
                    commit('setvx__json', table, { root: true }) 
                   
                  } catch (error) {
                    table['is_loaded_tables']['error_idb_save']=true; 
                    console.log("Ошибка при сохранении данных в store"); 
                    console.error(error);
                  }
                   
            }
          
            
        }
        else
        { 
            //console.log("WAIT 2 sec"); 
            await new Promise(res => { setTimeout(res, 2000); });
            console.log("WAIT 2 sec - FIN");
            
            table['is_loaded_tables']['load_status']=true;
            // console.log(table); 
            //если данные в api_db есть, подтянем
            console.log("dyntab_"+table_name+", JSON_LOAD_FROM_STORE");
         //   console.log(table);
            //this.state.is_loaded_tables[table_name]=true;
            commit('setvx__json', table, { root: true });
           
          
        }
       
     
      //  console.log(table);
       commit('setvx__flag_a_data', table, { root: true })
       
       
       //работа с таблицей завершена
       element['is_blocked']=false;
       commit('setvx__flag_blocked_action', element, { root: true });
      
  //  console.log("step_orger+++fin_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));
  }, 
   
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  
    
   //ОТРАБОТАЕТ АСИНХРОННО   - можно запустить в паралель подгрузку многих таблиц, ну и что, что будет несколько запросов.
   async vuex_set__json_data__ls_or_back ({ commit }, conf) { 
                    
         let table_name=conf['table_name'];  
         let table_module=conf['table_module'];
                    
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']=table_name;
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        
         
        let params=conf['params']  //link
        
     
    
        //проверим данные в api_idb    //IndexedDB=IDB 
        let table={};  //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        table = await api_idb.loadTable(table_name) 
        table['table_module']=table_module;
        table['is_loaded_tables']={};        
        table['is_loaded_tables']['load_status']=false;
        table['is_loaded_tables']['error_send']=false;
        table['is_loaded_tables']['error_api']=false;
        //table['is_loaded_tables']['error_idb_load']=false; устанавливается во время загрузки таблицы из Хранилища
        table['is_loaded_tables']['error_idb_save']=false;
      
        //если данных в IDB нету ...
        if (!table.hasOwnProperty('table_name'))
        {
         
         //блокировка флага в списке флогов в разрезе страницы
           //cdata['flags']['is_action'][login_flag_name] = true;      
                   
           let response_data={};        
               
           // console.log(conf['server_cloud']+conf['params']['link']);
          await axios.get(conf['server_cloud']+conf['params']['link'], params)
                            .then(response =>
                            {
                              //  console.log(response['data']); 
                                 console.log(table_name+" LOAD_FROM_BACK");  
                                response_data=response['data'];
                                 
                                table['is_loaded_tables']['load_status']=true;  
                               
                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                //cdata['flags']['is_error']['sending'] = true;
                                table['is_loaded_tables']['error_send']=true;
                                
                                //cdata['flags']['is_action'][login_flag_name] = false;

                            }).then(function () { });          
               
             //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
             //console.log("load_status="+table['is_loaded_tables']['load_status']);
             
             table['table_name']=table_name;
             
             if (table['is_loaded_tables']['load_status'])
             {
                //let table_data=response_data;
                
                table['table_data']=response_data;
              
                //console.log(table);
                
                try {
                    await api_idb.saveTable(table) 
                    
                    console.log(table_name+", SAVE_TO_STORE");
                    commit('setvx__json', table, { root: true }) 
                   
                  } catch (error) {
                    table['is_loaded_tables']['error_idb_save']=true; 
                    console.log("Ошибка при сохранении данных в store"); 
                    console.error(error);
                  }
                   
            }
          
            
        }
        else
        { 
            //console.log("WAIT 1 sec"); 
            await new Promise(res => { setTimeout(res, 1000); });
            //console.log("WAIT 1 sec - FIN");
            
            table['is_loaded_tables']['load_status']=true;
            
            //если данные в api_db есть, подтянем
            console.log(table_name+", JSON_LOAD_FROM_STORE");
           
            //this.state.is_loaded_tables[table_name]=true;
            commit('setvx__json', table, { root: true });
           
          
        }
       
     
      //  console.log(table);
       commit('setvx__flag_a_data', table, { root: true })
       
       
       //работа с таблицей завершена
       element['is_blocked']=false;
       commit('setvx__flag_blocked_action', element, { root: true });
      
    //console.log("step_orger+++fin_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));
  }, 
   
   
   
   
   
   
    //ОТРАБОТАЕТ АСИНХРОННО   - можно запустить в паралель подгрузку многих таблиц, ну и что, что будет несколько запросов.
   async vuex_set__json_data__back ({ commit }, conf) { 
                    
         let table_name=conf['table_name'];  
         let table_module=conf['table_module'];
                    
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']=table_name;
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        
         
        let params=conf['params']  //link
        
     
    
        //проверим данные в api_idb    //IndexedDB=IDB 
        let table={};  //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        //table = await api_idb.loadTable(table_name)  ТОЛЬКО С БЭКА!!! НЕ ГРУЗИМ ОТСЮДА!
        table['table_module']=table_module;
        table['is_loaded_tables']={};        
        table['is_loaded_tables']['load_status']=false;
        table['is_loaded_tables']['error_send']=false;
        table['is_loaded_tables']['error_api']=false;
        table['is_loaded_tables']['error_idb_load']=false;
        table['is_loaded_tables']['error_idb_save']=false;
      
        
         
         //блокировка флага в списке флогов в разрезе страницы
           //cdata['flags']['is_action'][login_flag_name] = true;      
                   
           let response_data={};        
               
           console.log(conf['server_cloud']+conf['params']['link']);     
           console.log(params);         
               
           // console.log(conf['server_cloud']+conf['params']['link']);
          await axios.get(conf['server_cloud']+conf['params']['link'], params)
                            .then(response =>
                            {
                                
                                
                                
                                console.log(response['data']); 
                                 console.log(table_name+" LOAD_FROM_BACK"); 
                                response_data=response['data'];
                                 
                                table['is_loaded_tables']['load_status']=true;  
                               
                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                //cdata['flags']['is_error']['sending'] = true;
                                table['is_loaded_tables']['error_send']=true;
                                
                                //cdata['flags']['is_action'][login_flag_name] = false;

                            }).then(function () { });          
               
             //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
             //console.log("load_status="+table['is_loaded_tables']['load_status']);
             
             table['table_name']=table_name;
             
             if (table['is_loaded_tables']['load_status'])
             {
                //let table_data=response_data;
                
                table['table_data']=response_data;
              
                //console.log(table);
                
                try {
                    await api_idb.saveTable(table) 
                    
                    console.log(table_name+", SAVE_TO_STORE");
                    commit('setvx__json', table, { root: true }) 
                   
                  } catch (error) {
                    table['is_loaded_tables']['error_idb_save']=true; 
                    console.log("Ошибка при сохранении данных в store"); 
                    console.error(error);
                  }
                   
            }
          
         //console.log("WAIT 1 sec");
         await new Promise(res => { setTimeout(res, 1000); });
         console.log("WAIT 1 sec");
     
      //  console.log(table);
       commit('setvx__flag_a_data', table, { root: true })
       
       
       //работа с таблицей завершена
       element['is_blocked']=false;
       commit('setvx__flag_blocked_action', element, { root: true });
      
    //console.log("step_orger+++fin_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));
  }, 
   














    
   
   
   
  
    
};
 
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

