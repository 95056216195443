import Vue from 'vue';
import Vuex from 'vuex';
import api_idb from '@/api/idb'
import axios from 'axios';

import _diyamic_tables from '@/store/modules/_diyamic_tables';
import _system from '@/store/modules/_system';
import _stress from '@/store/modules/_stress';
import _auth from '@/store/modules/_auth';
import _data from '@/store/modules/_data';
import _json from '@/store/modules/_json';
import _business_logic from '@/store/modules/_business_logic';


Vue.use(Vuex)


let state = {

    answer:{},
    
    page_v: 0,
    cnt_loaded: 0,
//    lock: {},
    is_all_stage_fin: false,
    _route_: '',

    game_mode: 'regular',

    timers:{
        is_active_timer_check_shift: false,
    },


    curr_event: {
        _name: "",
        _game_type: "",
        _id: "",
        event: {},
        event_timings: {
            curr_timestamp: 0,
            delta_s: 0,
            game_types: { 
                
            }
        }


    },

    slides: {

    },

//DESK
    current_deskweek: "",

    state_tables: {

        state_manage: {
            json_version: {},
            events_list: {},
            shift_list: {},
        },

        state_rg: {
            rg_learning: {},

        },

        state_st: {
            st_cards: {}, //в разрезе раскладки 
        },

        state_auth: {
            r_auth: {},
        },

        state_test: {
            
            test3: {},
            a_test2: {},
            a_test: {},
            
            

        },

        state_stress: {

            a_stress: {},

        },

        state_layouts: {

            json_layouts: {},

        },
        
        state_game_settings: {
            level_props__solve_limit: {},
            level_props__exp_by_tasks: {},
            level_props__gold_by_tasks: {},
            level_props__joker_by_tasks: {},
            
            global__level_settings: {},
            global__inventory_glossary:{},
            
            global__profession_list: {},
        },
        
        state_game_stats: {
            current_exp: {},
            
            player_inventory: {},
            
            player_professions: {},
            
            quest_manager: {},
        },

        state_desks: {

            //layout 0000
            json_layout_0000__pos_01: {},
            json_layout_0000__pos_02: {},
            json_layout_0000__pos_03: {},
            json_layout_0000__pos_04: {},
            json_layout_0000__pos_05: {},
            json_layout_0000__pos_06: {},

            //layout 0001
            json_layout_0001__pos_01: {},
            json_layout_0001__pos_02: {},
            json_layout_0001__pos_03: {},
            json_layout_0001__pos_04: {},
            json_layout_0001__pos_05: {},
            json_layout_0001__pos_06: {},

            //layout 0002
            json_layout_0002__pos_01: {},
            json_layout_0002__pos_02: {},
            json_layout_0002__pos_03: {},
            json_layout_0002__pos_04: {},
            json_layout_0002__pos_05: {},
            json_layout_0002__pos_06: {},

            //layout 0003
            json_layout_0003__pos_01: {},
            json_layout_0003__pos_02: {},
            json_layout_0003__pos_03: {},
            json_layout_0003__pos_04: {},
            json_layout_0003__pos_05: {},
            json_layout_0003__pos_06: {},
            
            //layout 0004
            json_layout_0004__pos_01: {},
            json_layout_0004__pos_02: {},
            json_layout_0004__pos_03: {},
            json_layout_0004__pos_04: {},
            json_layout_0004__pos_05: {},
            json_layout_0004__pos_06: {},
            
            //layout 0005
            json_layout_0005__pos_01: {},
            json_layout_0005__pos_02: {},
            json_layout_0005__pos_03: {},
            json_layout_0005__pos_04: {},
            json_layout_0005__pos_05: {},
            json_layout_0005__pos_06: {},

        },
        
        state_books:{
            json_catalog: {}
        },
        
        state_cards: {
            json_cards: {}
        },

    },

    is_loaded_tables: {

        //auth.js
        r_auth: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //data.js
        a_test: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        a_test2: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        test3: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //json.js
        json_layouts: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //rg
        rg_learning: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //st
        st_cards: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        // СЛИШКОМ БОЛЬШОЙ в разрезе раскладки st_tasks: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},


        //manage
        json_version: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        events_list: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        shift_list:  {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

      

        //DESKS
        //layout 0000
        json_layout_0000__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0000__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0000__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0000__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0000__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0000__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //layout 0001
        json_layout_0001__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0001__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0001__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0001__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0001__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0001__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //layout 0002
        json_layout_0002__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0002__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0002__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0002__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0002__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0002__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

        //layout 0003
        json_layout_0003__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0003__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0003__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0003__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0003__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0003__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        //layout 0004
        json_layout_0004__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0004__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0004__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0004__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0004__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0004__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        //layout 0005
        json_layout_0005__pos_01: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0005__pos_02: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0005__pos_03: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0005__pos_04: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0005__pos_05: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_layout_0005__pos_06: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        
//        GLOBAL GAME SETTINGS

        level_props__solve_limit: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        level_props__exp_by_tasks: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        level_props__gold_by_tasks: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        level_props__joker_by_tasks: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        global__level_settings: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        global__inventory_glossary: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        global__profession_list: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        current_exp: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        player_inventory: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        player_professions: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
        quest_manager: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        
//        GLOBAL GAME SETTINGS


//        STORY
        json_catalog: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},
        json_cards: {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false},

    },
    
    system: {
        
//  STORY BLOCK
        book_boutchoose: {
            is_mounted_execute: false, // прогрузилась общая раскладка (layoutchild)
            is_all_fin_for_this_page: false, // когда начался блок после mounted_execute (блоки на странице)
            is_all_fin_for_this_page_executed: false, // динамический контент
            is_all_fin_for_this_page_with_additional: false, // начал загрузку историй
            is_all_fin_for_this_page_with_additional_executed: false, // финальный после загрузки историй 
        },
        
        book_boutpart: {
            is_mounted_execute: false, // прогрузилась общая раскладка (layoutchild)
            is_all_fin_for_this_page: false, // когда начался блок после mounted_execute (блоки на странице)
            is_all_fin_for_this_page_executed: false, // динамический контент
            is_all_fin_for_this_page_with_additional: false, // начал загрузку историй
            is_all_fin_for_this_page_with_additional_executed: false, // финальный после загрузки историй 
        },
        
        book_boutstory: {
            is_mounted_execute: false, // прогрузилась общая раскладка (layoutchild)
            is_all_fin_for_this_page: false, // когда начался блок после mounted_execute (блоки на странице)
            is_all_fin_for_this_page_executed: false, // динамический контент
            is_all_fin_for_this_page_with_additional: false, // начал загрузку историй
            is_all_fin_for_this_page_with_additional_executed: false, // финальный после загрузки историй 
        },
//  END STORY BLOCK
        
        
//        TEST PAGES

        g1_reset: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },

//        TEST PAGES
        
        g1_story: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_first: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_desksweek: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_cardweek: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_gameover: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_shift: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_empty: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_empty2: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_template1: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_template2: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_child_setup: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        
        g1_inventory: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
        g1_professions: {
            is_all_fin_for_this_page: false,
            is_mounted_execute: false,
        },
    },

    //когда все флажечки загрузятся, watcher должен щелкнуть флаг на странице (или в раскладке) чтобы нужная секция была открыта
    is_required_sections: {
        
        g1_story: {
            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false, 
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                
                //global game settings test
                global__inventory_glossary: false,
                
                global__profession_list: false,
                player_professions: false,
                quest_manager: false,
            },
        },
        
        book_boutchoose: {
            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false, 
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                
                //global game settings test
                global__inventory_glossary: false,
                
                global__profession_list: false,
                player_professions: false,
                quest_manager: false,
            },
        },
        
        book_boutpart: {
            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false, 
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                
                //global game settings test
                global__inventory_glossary: false,
                
                global__profession_list: false,
                player_professions: false,
                quest_manager: false,
            },
        },
        
        book_boutstory: {
            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false, 
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                
                //global game settings test
                global__inventory_glossary: false,
                
                global__profession_list: false,
                player_professions: false,
                quest_manager: false,
            },
        },

        g1_first: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: { 
                r_auth: false,
                json_layouts: false,
                json_version: false,
                
                events_list: false, 
                shift_list: false,
            },

        },

        g1_desksweek: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false, 
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                /*    rg_learning: false,
                 st_cards: false,*/
                
                //global game settings test
                level_props__solve_limit: false,
                level_props__exp_by_tasks:false,
                level_props__gold_by_tasks:false,
                level_props__joker_by_tasks:false,
                
                global__inventory_glossary: false,
                
                global__profession_list: false,
                player_professions: false,
                quest_manager: false,
            },

        },

        g1_cardweek: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
                
                events_list: false, 
                shift_list: false,
                
                rg_learning: false, 
                st_cards: false,

                //global game settings test
                level_props__solve_limit: false,
                player_professions: false,
                quest_manager: false,
            },

        },
        
        
          g1_gameover: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },
 
        },
        
            g1_shift: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },
 
        },
        
        

        g1_empty: {

            header: {
                r_auth: false,
                global__level_settings: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },

            center2: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },

        },

        g1_empty2: {

            header: {
                r_auth: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },

            center2: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },

        },

        g1_template1: {

            header: {
                r_auth: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
            },

        },
        
        g1_template2: {

            header: {
                r_auth: false,
            },
            center: {
                r_auth: false,
                json_layouts: false,
                json_version: false,
                
                a_test: false,
                a_test2: false,
                test3: false,
                
                //global game settings test
                level_props__solve_limit: false,
            },

        },
        
        
        g1_child_setup: {

            header: {
                r_auth: false,
//                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_version: false
            },

        },
        
        g1_inventory: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_version: false,
                global__inventory_glossary: false,
                player_inventory: false,
                player_professions: false,
            },

        },
        
        g1_professions: {

            header: {
                r_auth: false,
                global__level_settings: false,
                current_exp: false,
            },
            center: {
                r_auth: false,
                json_version: false,
                global__inventory_glossary: false,
                player_professions: false,
            },

        },

    },

//на старт страницы занулить....
    is_loaded_stages: {
        //"inactive", "run", "fin"
        auth: "inactive",
        layouts: "inactive",
        version: "inactive",
        initial_shift: "inactive",

        events_list: "inactive",
        shift_list:  "inactive",
        

        rg_for_task: "inactive",
        st_for_task: "inactive",

        dynamic_desks_startload: "inactive",
        dynamic_desks_ready: "inactive",

        test3: "inactive",
        
        //GAME GLOBAL SETTINGS
        level_props__solve_limit: "inactive",
        level_props__exp_by_tasks: "inactive",
        level_props__gold_by_tasks: "inactive",
        level_props__joker_by_tasks: "inactive",
        global__level_settings: "inactive",
        global__inventory_glossary: "inactive",
        global__profession_list: "inactive",
        
        player_inventory: "inactive",
        player_professions: "inactive",
        quest_manager: "inactive",
    },

//на старт страницы занулить....
    is_required_stages: {
        
        g1_story: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                quest_manager: "inactive",
            },
        },
        
        book_boutchoose: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                quest_manager: "inactive",
            },
        },
        
        book_boutpart: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                quest_manager: "inactive",
            },
        },
        
        book_boutstory: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                quest_manager: "inactive",
            },
        },

        g1_first: {
            /*  header: {
             auth: "inactive", 
             },*/
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",
                /* НЕ НУЖНЫ НА ПЕРВОЙ СТРАНИЦЕ 
                 dynamic_desks_startload: "inactive",
                 dynamic_desks_ready: "inactive", 
                 */
            },

        },

        g1_desksweek: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",
                /*   rg_for_task: "inactive", 
                 st_for_task: "inactive",  */

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                level_props__solve_limit: "inactive",
                level_props__exp_by_tasks: "inactive",
                level_props__gold_by_tasks: "inactive",
                level_props__joker_by_tasks: "inactive",
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                quest_manager: "inactive",
            },

        },

        g1_cardweek: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",
                
                rg_for_task: "inactive",
                st_for_task: "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                
                level_props__solve_limit: "inactive",
                
                quest_manager: "inactive",
            },

        },
        
           g1_gameover: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },
 
        },
          g1_shift: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },
 
        },  
        

        g1_template1: {
            header: {
                auth: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },

        },
        
        g1_template2: {
            header: {
                auth: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                test3: "inactive",
                
                level_props__solve_limit: "inactive"
            },

        },
        
        
        g1_child_setup: {
            header: {
                auth: "inactive",
//                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                version: "inactive"
            },

        },

        g1_empty: {
            header: {
                auth: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },

            center2: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },

        },

        g1_empty2: {
            header: {
                auth: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },

            center2: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
            },

        },
        
        g1_inventory: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",
                
                rg_for_task: "inactive",
                st_for_task: "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                global__inventory_glossary: "inactive",
                player_inventory: "inactive",
            },

        },
        
        g1_professions: {
            header: {
                auth: "inactive",
                global__level_settings: "inactive",
            },
            center: {
                auth: "inactive",
                layouts: "inactive",
                version: "inactive",
                initial_shift: "inactive",
                events_list: "inactive",
                shift_list:  "inactive",
                
                rg_for_task: "inactive",
                st_for_task: "inactive",

                dynamic_desks_startload: "inactive",
                dynamic_desks_ready: "inactive",
                global__inventory_glossary: "inactive",
                global__profession_list: "inactive",
                player_professions: "inactive",
            },

        },

    },

//с любой из таблиц пошла работа - все действия по кнопкам блокируются
    blocked_actions: {//Чтобы все КНОПКИ на форме заблокировались, надо чтобы хотя бы одна табличка блокирнулась!
        
        g1_story: {

            r_auth: false,
            json_layouts: false,
            json_version:  false,

            rg_learning: false,

            events_list: false,
            shift_list: false,
            
            global__profession_list: false,
            player_professions: false, 
            quest_manager: false, 
            
            current_exp: false,

        },
        
        book_boutchoose: {

            r_auth: false,
            json_layouts: false,
            json_version:  false,

            rg_learning: false,

            events_list: false,
            shift_list: false,
            
            global__profession_list: false,
            player_professions: false, 
            quest_manager: false, 
            
            current_exp: false,

        },
        
        book_boutpart: {

            r_auth: false,
            json_layouts: false,
            json_version:  false,

            rg_learning: false,

            events_list: false,
            shift_list: false,
            
            global__profession_list: false,
            player_professions: false, 
            quest_manager: false, 
            
            current_exp: false,

        },
        
        book_boutstory: {

            r_auth: false,
            json_layouts: false,
            json_version:  false,

            rg_learning: false,

            events_list: false,
            shift_list: false,
            
            global__profession_list: false,
            player_professions: false, 
            quest_manager: false, 
            
            current_exp: false,

        },

        g1_first: {

            r_auth: false,
            global__level_settings: false,
            current_exp: false,
            
            json_layouts: false,
            json_version:  false,
            events_list: false,
            shift_list:  false,
        },

        g1_desksweek: {

            r_auth: false,
            json_layouts: false,
            json_version:  false,

            rg_learning: false,

            rg_cards: false,

            events_list: false,
            shift_list: false,
            
            level_props__solve_limit: false,
            level_props__exp_by_tasks: false,
            level_props__gold_by_tasks: false,
            level_props__joker_by_tasks: false,
            
            global__level_settings: false,
            global__profession_list: false,
            player_professions: false, 
            quest_manager: false, 
            
            current_exp: false,

        },

        g1_cardweek: {
            r_auth: false,
            json_layouts: false,
            json_version:  false,
            rg_learning: false,

            rg_cards: false,

            events_list: false,
            shift_list: false,
            
            level_props__solve_limit: false,
            
            global__level_settings: false,
            player_professions: false,
            quest_manager: false,
            
            current_exp: false,
        },
        
        
          g1_gameover: {

            r_auth: false,
            json_layouts: false,
            
            global__level_settings: false,
            
            current_exp: false,
            

        },
        g1_shift: {

            r_auth: false,
            json_layouts: false,
            
            global__level_settings: false,
            
            current_exp: false,
        },
        
        

        g1_empty: {

            r_auth: false,
            json_layouts: false,
            
            global__level_settings: false,

        },

        g1_empty2: {

            r_auth: false,
            json_layouts: false,

        },
        g1_template1: {

            r_auth: false,
            json_layouts: false,

        },
        
        g1_template2: {
            r_auth: false,
            json_layouts: false,
            
            a_test: false,
            a_test2: false,
            test3: false,
            
            level_props__solve_limit: false,
        },
        
        g1_child_setup: {
            r_auth: false,
            
//            global__level_settings: false,
            
            current_exp: false,
        },
        
        g1_inventory: {
            r_auth: false,
            json_layouts: false,
            json_version:  false,
            rg_learning: false,

            rg_cards: false,

            events_list: false,
            shift_list: false,
            
            level_props__solve_limit: false,
            
            global__level_settings: false,
            global__inventory_glossary: false,
            global__profession_list: false,
            
            current_exp: false,
            player_inventory: false,
            player_professions: false,
        },
        
        g1_professions: {
            r_auth: false,
            json_layouts: false,
            json_version:  false,
            rg_learning: false,

            rg_cards: false,

            events_list: false,
            shift_list: false,
            
            level_props__solve_limit: false,
            
            global__level_settings: false,
            global__inventory_glossary: false,
            global__profession_list: false,
            player_professions: false,
            
            current_exp: false,
        },

        //...

    },

};







const getters = {

    __is_required_stages: (state) => (_route_) => {

            let obj = {};

            //  console.log(state.__is_required_stages);

            for (const [key, value] of Object.entries(state.is_required_stages)) {

                if (key === _route_)//.startsWith('g1_start')
                {   // obj[key]=value
                    obj = value; //['block_all_if_anyone_reloaded']

                }
            }

            //obj['_PAGE_']=_route_;
            return obj;

        },

    __is_required_sections: (state) => (_route_) => {

            let obj = {};

            //  console.log(state.is_required_sections);

            for (const [key, value] of Object.entries(state.is_required_sections)) {

                if (key === _route_)//.startsWith('g1_start')
                {   // obj[key]=value
                    obj = value; //['block_all_if_anyone_reloaded']

                }
            }

            //obj['_PAGE_']=_route_;
            return obj;

        },

    __blocked_tables: (state) => (_route_) => {

            let obj = {};

            for (const [key, value] of Object.entries(state.blocked_actions)) {

                if (key === _route_)//.startsWith('g1_start')
                {   // obj[key]=value
                    obj = value; //['block_all_if_anyone_reloaded']

                }
            }

            //obj['_PAGE_']=_route_;
            return obj;

        },

    __tables: (state) => (_route_) => {

            let obj = {};

            for (const [key, value] of Object.entries(state.is_loaded_tables)) {

                //если key среди 
                let is_finded_key = false;
                for (const [blocked_actions_key, blocked_actions_value] of Object.entries(state.blocked_actions[_route_]))
                {
                    if (blocked_actions_key === key)
                    {
                        is_finded_key = true;
                    }
                }

                if (is_finded_key)//.startsWith('g1_start')
                {
                    if (value['load_status'])
                    {

                        //debug_common['is_loaded_tables_on_page'][key] = value['load_status']; 
                        obj[key] = value['load_status'];
                    }
                }
            }



            //obj['_PAGE_']=_route_;
            return obj;

        },

    __is_errors: (state) => (_route_) => {

            let obj = {};

            for (const [key, value] of Object.entries(state.is_loaded_tables)) {

                //если key среди 
                let is_finded_key = false;
                for (const [blocked_actions_key, blocked_actions_value] of Object.entries(state.blocked_actions[_route_]))
                {
                    if (blocked_actions_key === key)
                    {
                        is_finded_key = true;
                    }
                }

                if (is_finded_key)//.startsWith('g1_start')
                {


                    if (value['load_status'])
                    {
                        //debug_common['is_loaded_tables_on_page'][key] = value['load_status']; 
                        obj[key] = {};
                        obj[key]['load_status'] = value['load_status'];
                        obj[key]['error_api'] = value['error_api'];
                        obj[key]['error_idb_load'] = value['error_idb_load'];
                        obj[key]['error_idb_save'] = value['error_idb_save'];
                        obj[key]['error_send'] = value['error_send'];
                    }




                }
            }



            //obj['_PAGE_']=_route_;
            return obj;

        }







};



const mutations = {
    
    setvx__is_loaded_tables__load_status(state, table)
    {
        state['is_loaded_tables'][table["table_name"]]['load_status'] = table['load_status'];
    },
    
    setvx__is_all_fin_for_this_page_with_additional_executed(state, table)
    {
        state['system'][table['_route_']]['is_all_fin_for_this_page_with_additional_executed'] = table['is_all_fin_for_this_page_with_additional_executed'];
    },


    setvx__is_all_fin_for_this_page_executed(state, table)
    {
        state['system'][table['_route_']]['is_all_fin_for_this_page_executed'] = table['is_all_fin_for_this_page_executed'];
    },


    setvx__is_all_fin_for_this_page_with_additional(state, table)
    {
        state['system'][table['_route_']]['is_all_fin_for_this_page_with_additional'] = table['is_all_fin_for_this_page_with_additional'];
    },
    
    
    
    updatevx__a_data_sys(state, table) {
        console.log(table);   
        state['answer']=table['answer'];
        state['state_tables']['state_auth']['r_auth']['mysql_v'] = table['mysql_v'];
    },
    
    setvx__is_all_fin_for_this_page(state, table)
    {
        console.log('test vuex');
        console.log(state['system'][table['_route_']]);
         console.log(table);
        state['system'][table['_route_']]['is_all_fin_for_this_page'] = table['is_all_fin_for_this_page'];
    },
    setvx__is_mounted_execute(state, table)
    {
        state['system'][table['_route_']]['is_mounted_execute'] = table['is_mounted_execute'];
    },
    
    
    setvx__page_v(state, page_v) {
        state.page_v=page_v;
    },
 
 setvx__is_active_timer_check_shift(state, data) {
     
     state.timers.is_active_timer_check_shift=data['is_active_timer_check_shift'];
 },



    setvx__curr_event(state, curr_event) {
        
        state.curr_event['_name'] = curr_event['_name'];
        state.curr_event['_game_type'] = curr_event['_game_type'];
        state.curr_event['_id'] = curr_event['_id'];
        
        
        state.curr_event['event'] = curr_event['event'];
        state.curr_event['event_timings'].curr_timestamp = curr_event['event_timings'].curr_timestamp;
        state.curr_event['event_timings'].delta_s = curr_event['event_timings'].delta_s;
        
        state.curr_event['event_timings'].game_types={};
        state.curr_event['event_timings'].game_types[curr_event['_game_type']] = curr_event['event_timings'].game_types[curr_event['_game_type']];
        
    },



    setvx__curr_timestamp(state, table) {
        state.curr_event.event_timings['curr_timestamp'] = table['curr_timestamp'];
    },

    setvx__current_deskweek(state, table) {
        state['current_deskweek'] = table['current_deskweek'];
    },

    setvx__route_(state, _route_) {
        state._route_ = _route_;
    },

    //_diyamic_tables 
    /* setvx__new_is_loaded_tables_desks(state, table) {
     state.is_loaded_tables[table['table_name']] = {load_status: false, error_send: false, error_api: false, error_idb_load: false, error_idb_save: false};
     //         НЕ РАБОТАЕТ РЕАКТИВНОСТЬ НИКАК
     //         state.is_loaded_tables[table['table_name']]={};
     //         state.is_loaded_tables[table['table_name']]['load_status']=false;
     //         state.is_loaded_tables[table['table_name']]['error_send']=false;
     //         state.is_loaded_tables[table['table_name']]['error_api']=false;
     //         state.is_loaded_tables[table['table_name']]['error_idb_load']=false;
     //         state.is_loaded_tables[table['table_name']]['error_idb_save']=false;
     },*/
    setvx__new_is_required_sections_desks(state, table) {
        //console.log("sections_desks:" + table['_route_'] + "|" + table['_section_'] + "|" + table['table_name']);
        state.is_required_sections[table['_route_']][table['_section_']][table['table_name']] = false;
    },
    setvx__new_blocked_actions_desks(state, table) {
        //console.log("blocked_sections:" + table['_route_'] + "|" + table['_section_'] + "|" + table['table_name']);
        state.blocked_actions[table['_route_']][table['table_name']] = false;
    },

    //auth_work
    setvx__r_auth(state, table) {
        state['state_tables'].state_auth[table['table_name']] = table['table_data'];
    },

    //flags and blocks
    setvx__flag_blocked_action(state, element) {

        //проставим блокировку таблицы во всех страницах 
        for (var key_page of Object.keys(state.blocked_actions))
        {
            for (var key_element of Object.keys(state.blocked_actions[key_page]))
            {

                if (key_element === element['name'])
                {
                    state.blocked_actions[key_page][key_element] = element['is_blocked'];
                    /*if (element['name'] === "r_auth")
                     {
                     console.log("+++BLOCK:" + element['name']);
                     }*/
                }

            }

        }



        state.cnt_loaded = state.cnt_loaded > 10000000 ? 0 : state.cnt_loaded + 1;


    },

    /*setvx__lock_action(state, lock) {
     state.lock[lock['method']] = lock['value'];
     },*/

    setvx__stage(state, element) {

        state.is_loaded_stages[element['stage_name']] = element['stage_value'];

        let stage_name = element['stage_name'];
        let stage_value = element['stage_value'];

        for (var key__route_  of Object.keys(state.is_required_stages))
        {
            // console.log(state.is_required_stages);


            let _route_ = key__route_;
            for (var key_div of Object.keys(state.is_required_stages[_route_]))
            {

                for (var key_stage of Object.keys(state.is_required_stages[_route_][key_div]))
                {

                    //console.log("key_stage|"+key_stage+"?=?"+stage_name);
                    if (key_stage === stage_name)
                    {
                        //console.log("SET|stage_value="+stage_value+"|for "+stage_name+"|"+_route_);
                        state.is_required_stages[_route_][key_div][stage_name] = stage_value;
                    }
                }



            }

        }

        state.cnt_loaded = state.cnt_loaded > 10000000 ? 0 : state.cnt_loaded + 1;

    },

    setvx__flag_a_data(state, table) {
//        console.log("TABLE");
//        console.log(state);
//        console.log(table);

        state.is_loaded_tables[table['table_name']]['load_status'] = table['is_loaded_tables']['load_status'];
        state.is_loaded_tables[table['table_name']]['error_send'] = table['is_loaded_tables']['error_send'];
        state.is_loaded_tables[table['table_name']]['error_api'] = table['is_loaded_tables']['error_api'];
        state.is_loaded_tables[table['table_name']]['error_idb_load'] = table['is_loaded_tables']['error_idb_load'];
        state.is_loaded_tables[table['table_name']]['error_idb_save'] = table['is_loaded_tables']['error_idb_save'];


        //установим флаг статуса загрузки таблицы для всех разделов на всех страницах
        for (var key_page of Object.keys(state.is_required_sections))
        {
            for (var key_section of Object.keys(state.is_required_sections[key_page]))
            {

                for (var key_table of Object.keys(state.is_required_sections[key_page][key_section]))
                {


                    if (key_table === table['table_name'])
                    {
                        state.is_required_sections[key_page][key_section][key_table] = table['is_loaded_tables']['load_status'];
                    }
                }
            }

        }

        //state.cnt_loaded = state.cnt_loaded > 10000000 ? 0 : state.cnt_loaded + 1;
        /*
         is_show: {
         
         g1_start :{                 
         header:{},                        
         center:{
         a_test: false, 
         
         */


    },

    setvx__is_all_stage_fin(state, element) {
        state['is_all_stage_fin'] = element['is_all_stage_fin'];
    },

    setvx__cnt_plus_plus(state, element) {
        state['cnt_loaded'] = state['cnt_loaded'] + 1;
    },

    setvx__clear_slide(state, element) {

        state['slides'] = {};

    },

    setvx__add_slide(state, element) {

        let new_slide = {};
        let slides = state['slides'];
        let slides_length = Object.keys(slides).length;

        new_slide[slides_length] = {
            layout_version: element['layout_version'],
            lid: element['lid'],
            pos: element['pos'],
            desk_version: element['desk_version'],
            card_num: element['card_num']
        }

        slides = Object.assign(slides, new_slide);

        state['slides'] = slides;

    },
    //regular work
    //sql
    setvx__a_data(state, table) {
        state['state_tables'][table['table_module']][table['table_name']] = table['table_data'];
    },
    updatevx__a_data(state, table) {
        state['state_tables'][table['table_module']][table['table_name']] = table['table_data'];
//        state['state_tables']['state_auth']['r_auth']['mysql_v'] = table['mysql_v'];
    },
    //json
    setvx__json(state, table) {
        state['state_tables'][table['table_module']][table['table_name']] = table['table_data'];
    },

    //exclusive work
    setvx__a_stress(state, table) {

        state['state_tables'].state_stress[table['table_name']] = table['table_data'];
    },
    updatevx__a_stress(state, table) {

        state['state_tables'].state_stress[table['table_name']] = table['table_data'];
        state['state_tables']['state_auth']['r_auth']['mysql_v'] = table['mysql_v'];
    },

};




const actions = {

};



export default new Vuex.Store({
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        _stress,
        _auth,
        _data,
        _json,
        _diyamic_tables,
        _system,
        _business_logic,
    }



});

 