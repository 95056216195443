const DB_NAME = 'game'
const DB_VERSION = 4

/*
  if (!('indexedDB' in window)) {
  console.log('This browser doesn\'t support IndexedDB');
  return;
}
*/

var DB;

export default {
  
  
  
         
     
    
async getDb () {
    return new Promise((resolve, reject) => {
        
        //если база уже есть, то ее отдать??
      if (DB) {
       // console.log('already_init')  
        return resolve(DB)
      }
      
      const request = indexedDB.open(DB_NAME, DB_VERSION)
      
      
      request.onerror = function(e) {
        console.log('Error opening db', e)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Error')
      }
      request.onsuccess = function(e) {
        DB = e.target.result
        resolve(DB)
      }
      request.onupgradeneeded = function(e) {
        let db = e.target.result
        
            if (!db.objectStoreNames.contains('store'))
            {
                var objectStore = db.createObjectStore('store', { autoIncrement: false, keyPath: 'table_name', })
                objectStore.createIndex("table_name", "table_name", { unique: true });
            }
        
          
            
      }
    })
  },
    
    
    
    async deleteAllTables() {
        let db = await this.getDb()

        var transaction = db.transaction(['store'], 'readwrite');
        var store = transaction.objectStore('store');

        var request = store.clear();

       request.onerror = function(e) {
          console.log('Error', e.target.error.name);
           console.log(e); 
        };
        request.onsuccess = function(e) {
          console.log(' IDB tables - clear');
        };
        
    },
    
    
//вход    
//table -> _table КОПИРУЕМ, а не по ССЫЛКЕ  _table = JSON.parse(JSON.stringify(table))
// table['table_name']
// table['table_data']     
// 
// Шифруем данные талицы table['table_data']  
//
//Кладем в IDB - table
   
async saveTable(table) {
 
  
 
  let _table = JSON.parse(JSON.stringify(table)); //ИМЕННО КОПИРУЕМ, а не передаем поссылке ...чтобы передавал не по ссылке!
   
   
      //  console.log(_table['table_data']);
   
   let yes=true;
   if (yes)
       {
            _table['table_data']=window._CRYPT().encrypt_json(_table['table_data']); 
       }
   
                                // console.log(_table['table_data']);

  
   let db = await this.getDb()
    
  var transaction = db.transaction(['store'], 'readwrite');
  var store = transaction.objectStore('store');
   
     
     
 var request = store.put(_table);

 request.onerror = function(e) {
    console.log('Error', e.target.error.name);
     console.log(e);
     table['is_loaded_tables']['error_idb_save']=true;
  };
  request.onsuccess = function(e) {
    //console.log(' - ok');
  };
},    
         
   
  
   
//вход    
//table_name 
//
//Проходимся по всем таблицам IDB в store и извлекаем их поочереди в cursor.value
//Если нашласть таблица в IDB, в store - с именем table_name //if (table_name === cursor.value['table_name'])
//
//Кладем ее в table=cursor.value;
//Далее если if(Object.keys(table).length>0) - table имеет ключи, т.е. не пустой объект
//Расшифровываем table['table_data']     
//
//ВЫХОД table 
// table['table_name']
// table['table_data']  (*расшифрованные данные) 

 async loadTable (table_name) {
      //  console.log("getTable_start: "+table_name);
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction(['store'], 'readonly')
      trans.oncomplete = () => {
         
        resolve(table)
        
      }
      const store = trans.objectStore('store')
      let table = {}
      store.openCursor().onsuccess = function(e) {
        const cursor = e.target.result
        //console.log(cursor);
        if (cursor) {
          
           if (cursor.value['table_name']===table_name)
           {
               table=cursor.value; //TODO Расшифровать, сериализовать  
               //console.log("table: "+table_name);
               //console.log(table);
               if(Object.keys(table).length>0)
                {
                    let yes=true;
                      if (yes)
                      {
                        table['table_data']=window._CRYPT().decrypt_json(table['table_data']);
                      }
                }
           }
          cursor.continue()
        }
    
        
      }
    })
    
  }  
   
   
    
   
   
   
   
   
   
   
   
       
}     
       
     
