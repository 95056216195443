<template>
    <section class="main-view">
        
        <vue-snotify></vue-snotify>
        
        <div id="await_loader_outer">
            <div class='await_loader'>
                <b-spinner type="grow" label="Loading..."></b-spinner>
                <b-spinner type="grow" label="Loading..." class="ml-3"></b-spinner>
                <b-spinner type="grow" label="Loading..." class="ml-3"></b-spinner>
            </div>
       </div>
        
        <div class="container-scroller">


            <div class="container-scroller">
                
<transition name="slide">
                <Sidebar v-if="this.is_header_type1()" v-on:callMethodInParent="show_info_modal" v-on:toggle_admin_controls="toggle_admin_controls"/>
</transition>
                <section class="home-section" :style="((this.is_header_type_main_game()) || (this.is_no_header_type_story_mode())) ? 'width: 100%; position: inherit;' : ''">
                <div v-if="this.is_header_type1()">  
                    <Header1/>
                </div>

                <div v-else-if="this.is_header_type_main_game()">
                    <Main_Game_Header/>
                </div>

                <div class="container-fluid page-body-wrapper" :style="this.show_bcg()">
                    <div class="main-panel">
                        <div class="h-100">
                            <!--content-wrapper-->
                            <Transition>
                                <router-view></router-view>
                            </Transition>
                        </div> <!-- content wrapper ends -->
                        <Footer/>
                    </div> <!-- main panel ends -->
                </div> <!-- page-body-wrapper ends -->
                </section>

            </div>
                
        </div>
        
        
        
        
        
        
        
<!-- STORY HINT Modal -->
<transition name="bounce" appear>
    
<div v-if="cdata.business_logic.show_story_intro_modal" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
       <!--modal-xl-->
      
<!--       <div class="modal-content" style="border:none; background: transparent;">
        
        
        <div class="custom_modal__body px-5" style="position: relative;">
          
        <div @click="hide_info_modal" class="d-flex align-items-center" style="position: absolute; top:0; right:0; cursor: pointer; padding: 10px; background: #49BDF4; border-radius: 12px; width: 44px; height: 44px;">
            <p style="font-size: 24px; color:white;"><i class="mdi mdi-close"></i></p>
        </div>
          
                <div class=''>
                    <div class="w-100">
                      <b-carousel
                        id="carousel-fade"
                        style="text-shadow: 0px 0px 2px #000"
                        slide
                        img-width="1024"
                        img-height="480"
                        :interval="0"
                        controls
                        @sliding-end="onSlideEnd"
                      >
                        <b-carousel-slide
                            v-for="index in 13"
                            :key="index"
                          :img-src="'/resource/img/intro/artboard-'+index+'.webp'"
                        ></b-carousel-slide>
                      </b-carousel>
                    </div>
                </div>
      </div>
        
    </div>-->
      
      
      
    <div class="modal-content" style="border: none; border-radius: 16px; padding: 24px;">
        <div class="custom_modal__body custom_modal__welcome_modal px-5" style="position: relative;">
            <p>Welcome!</p>
            <p>Looks like you just arrived!</p>
            <p>Would you like to meet your assistant?</p>
            
            <div class="w-100 d-flex justify-content-between mt-5">
                <button @click="hide_info_modal" class="btn_negative">No, thanks</button>
                <button @click="modal__goto_tutorial" class="btn_accept">Let's GO</button>
            </div>
        </div>
    </div>
      
      
  </div>
</div> 
    
</transition>
 <!--END STORY HINT Modal-->  
        
        
        
        
        
 
 
<!-- ADMIN CONTROLS Modal -->
<transition name="bounce" appear>
    <!--cdata.business_logic.show_story_intro_modal-->
    <!--state.show_help_modal-->
<div v-if="cdata.business_logic.show_admin_control_modal" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      
       <!--v-click-outside="hide_extension_modal"-->
       <div class="modal-content" style="border:none;">
        
        
        <div class="custom_modal__body p-5" style="position: relative;">
          
        <div @click="toggle_admin_controls(false)" class="d-flex align-items-center" style="position: absolute; top:0; right:0; cursor: pointer; padding: 10px; background: #49BDF4; border-radius: 12px; width: 44px; height: 44px;">
            <p style="font-size: 24px; color:white;"><i class="mdi mdi-close"></i></p>
        </div>
          
                <div class=''>
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="admin_controls__solved_info" 
                             v-model="cdata.business_logic.admin_controls.admin_controls__solved_info"
                             @change="admin_controls__solved_info_toggle">
                      <label class="custom-control-label" for="admin_controls__solved_info">Turn on "solved info"</label>
                    </div>
                    
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="admin_controls__right_answers" 
                             v-model="cdata.business_logic.admin_controls.admin_controls__right_answers"
                             @change="admin_controls__right_answers_toggle">
                      <label class="custom-control-label" for="admin_controls__right_answers">Turn on "right answers"</label>
                    </div>
                    
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="admin_controls__daily_limit" 
                             v-model="cdata.business_logic.admin_controls.admin_controls__daily_limit"
                             @change="admin_controls__daily_limit_toggle">
                      <label class="custom-control-label" for="admin_controls__daily_limit">Turn off "deck daily limit"</label>
                    </div>
                </div> 
      </div>
        
    </div>
  </div>
</div> 
</transition>
 <!--END ADMIN CONTROLS Modal-->  
 
 
        
        
    </section>
</template>

<script>
    import Sidebar from "./partials/Sidebar";
    import Header1 from "./partials/Header1";
    import Main_Game_Header from "./partials/Main_Game_Header";
    // import Sidebar from "./partials/Sidebar";
    import Footer from "./partials/Footer";
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import api_idb from '@/api/idb'

            var cdata = {//Если не указать - не всключиться реактивность 

                system: {
                    //_route_: "#not",// ЗАГРУЗКА ДЛЯ ВСЕХ _route_

                    //загрузка общих данных
                    timer_checkversion_tick: 0, //counter sec
                    timer_checkversion_value: 8, //set timer in sec
                    timer_checkversion_step: 7, //step sec


                    //загрузка общих данных
                    timer_summary_tick: 0, //counter sec
                    timer_summary_value: 120, //set timer in sec
                    timer_summary_step: 55, //step sec

                    //прооверка переключения типа игры
                    timer_check_gametype_tick: 0, //counter sec
                    timer_check_gametype_value: 120, //set timer in sec
                    timer_check_gametype_step: 55, //step sec

                    //прооверка сдвига
                    timer_check_shift_tick: 0, //counter sec
                    timer_check_shift_value: 120, //set timer in sec
                    timer_check_shift_step: 55, //step sec

                    is_mounted_execute: false,
                    cnf: {},
                    debug_flag: {
                        timings: false,
                    },
                    curr_event: {
                        _name: "",
                        _game_type: "",
                        _id: "",
                        event: {},
                        event_timings: {
                            curr_timestamp: 0,
                            delta_s: 0,
                            game_types: {

                                TYPE1: {

                                    stages: {

                                        is_stage_fin: false,
                                        is_stage_middle: false,
                                        is_stage_start: false,
                                    },
                                    stages_start: {
                                        timestamp_stage_fin: 0,
                                        timestamp_stage_middle: 0,
                                        timestamp_stage_start: 0,
                                    },
                                    until_stages_end: {

                                        rtm_duration_before_fin: 0,
                                        rtm_duration_before_middle: 0,
                                        rtm_duration_before_start: 0,
                                    },
                                }


                            }
                        }
                    }

                    /*buttons: {
                     common: {
                     is_button_blocked: true,
                     button_blocking_exeption_list:{},//ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                     }, 
                     },*/


                },
                /*errors: {
                 is_errors: false,
                 actions_error: {},
                 },*/
                business_logic: {
                    start_layout: -1,
                    show_story_intro_modal: false,
                    show_admin_control_modal: false,
                    
                    admin_controls: {
                        admin_controls__daily_limit: false,
                        admin_controls__right_answers: false,
                        admin_controls__solved_info: false
                    }
                },
            }



    export default {
        name: "layoutchild",
        components: {
            Header1,
            Main_Game_Header,
             Sidebar,
            Footer
        },
        data() {
            return {
                cdata: cdata,
            };
        },
        mounted() {


            /* COPYPASTE */
            if (!this.$root._AUTH().is_authed()) {
                return;
            } else {
//                !this.$store.state.state_tables.state_auth.r_auth['pid'] || 
//                if(!this.$store.state.state_tables.state_auth.r_auth['classnum']){
//                    this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/setup/' + '?t=' + this.$root._SYS().add_v());
//                }
            }//проверить, авторизован ли или разлогин 
            this.$store.dispatch('_system/vuex_set__is_all_stage_fin', {"is_all_stage_fin": false});
            this.cdata['system']['is_mounted_execute'] = false;
            //this.cdata['system']['buttons']['common']['is_button_blocked'] = true;
            this.cdata['system']['cnf'] = this.$root.cnf;
            //устанавливаем на странице 
            //this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);


            //НУЛИМ СТАДИИ
            for (const [stage_name, stage_status] of Object.entries(this.$store.state.is_loaded_stages)) {
                let stage_conf = {};
                //stage_conf['_route_'] = this.cdata.system._route_;
                stage_conf['stage_name'] = stage_name;
                stage_conf['stage_value'] = "inactive"; //NULL DEFAULT
                this.$store.dispatch('_system/vuex_set__stage', stage_conf);
            }

            //НУЛИМ ТАБЛИЦЫ (Из-за того что есть динамические таблицы...)
            for (const [table_name, stage_status] of Object.entries(this.$store.state.is_loaded_tables)) {
                let table_conf = {};
                //table_conf['_route_'] = this.cdata.system._route_;
                table_conf['table_name'] = table_name;
                table_conf['stage_value'] = false; //NULL DEFAULT
                this.$store.dispatch('_system/vuex_set__default_table_flags', table_conf);
            }









            /* MODIFY - LOAD VUEX */
            this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().r_auth__ls_or_back(this.$store, this.cdata));
            //this.$store.dispatch('_data/vuex_set__a_data__ls_or_back', this.$root._LOAD().r_auth__ls_or_back(this.$store,this.cdata));
            //переподгружаются с облака на load, first, desks 
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json_layouts__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json_version__back(this.$store, this.cdata));
            //this.$store.dispatch('_json/vuex_set__json_data__ls_or_back', this.$root._LOAD().json_layouts__ls_or_back(this.$store,this.cdata));


            this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().shift_list__back(this.$store, this.cdata));

            this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().events_list__back(this.$store, this.cdata));

            this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().player_exp__ls_or_back(this.$store, this.cdata));
            
            
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            
            
//            STORY
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json_catalog__back(this.$store, this.cdata));
            
            
            //ОСТАЛЬНОЕ подгружать в стадиях, потому что без подгруженного r_auth ничего больше не грузится!!! 

            /* #OPYPASTE  */
            /*setTimeout(() => {
             this.cdata.system.is_mounted_execute = true;
             }, 100);*/
            this.cdata.system.is_mounted_execute = true;
            /* COPYPASTE */


//                if(!this.$store.state.state_tables.state_auth.r_auth['pid'] || !this.$store.state.state_tables.state_auth.r_auth['classnum']){
//                    this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/setup/' + '?t=' + this.$root._SYS().add_v());
//                }

//this.$store.state._route_ === 'g1_template1'

            if(this.$store.state._route_ != 'g1_child_setup'){
                console.log('he he test: ' + this.$store.state._route_);
                this.cdata.system.timer_checkversion_tick = this.cdata.system.timer_checkversion_value; //тригаем таймер
                this.cdata.system.timer_summary_tick = this.cdata.system.timer_summary_value; //тригаем таймер
                this.cdata.system.timer_check_gametype_tick = this.cdata.system.timer_check_gametype_value; //тригаем таймер
                this.cdata.system.timer_check_shift_tick = this.cdata.system.timer_check_shift_value; //тригаем таймер
                
                
//                ОБУЧАЛКА
//                    let showed_modal_before = localStorage.getItem("intro_modal");
//                    if(!showed_modal_before) {
//                        localStorage.setItem("intro_modal", true);
//                        this.cdata.business_logic.show_story_intro_modal = true;
//                    } else {
//                        this.cdata.business_logic.show_story_intro_modal = false;
//                    }

                
                this.cdata.business_logic.admin_controls.admin_controls__daily_limit = this.$root._ADMIN().get_daily_limit__admin_controls();
                this.cdata.business_logic.admin_controls.admin_controls__right_answers = this.$root._ADMIN().get_right_answers__admin_controls();
                this.cdata.business_logic.admin_controls.admin_controls__solved_info = this.$root._ADMIN().get_solved_info__admin_controls();
                
            }


            

        },
        computed: {

            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    //state['test'] = this.$store.state['state_tables'].state_test;/* MODIFY */
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    state['layouts'] = this.$store.state['state_tables'].state_layouts; /* MODIFY */
                    state['manage'] = this.$store.state['state_tables'].state_manage;/* MODIFY */
                    state['rg_for_task'] = this.$store.state['state_tables'].state_rg;/* MODIFY */
                    state['st_for_task'] = this.$store.state['state_tables'].state_st;/* MODIFY */


                    state['desks'] = this.$root._LAYOUT().add_desks_to_state_desks(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_ready);
                    
//                    state['show_help_modal'] = this.$root._LAYOUT().show_info_modal(this.$store, this.cdata);
                    //SHOW ALL DESKS!!! state['desks'] = this.$store.state['state_tables'].state_desks;/* MODIFY */
                } catch (ex) {
                    console.log("computed: state error");
                    console.log(ex);
                }


                //}

                return state;
            },
            form() {

                let form = {};
                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                /*
                 try { 
                 
                 form['classnum'] = this.$store.state.state_tables.state_auth.r_auth['classnum'];
                 form['lid'] = this.$store.state.state_tables.state_auth.r_auth['lid'];
                 if (
                 this.$store.state.is_loaded_stages.layouts === "fin" &&
                 this.$store.state.is_loaded_stages.auth === "fin" &&
                 this.$store.state.is_loaded_stages.initial_shift === "fin" 
                 )
                 { //падает, если неподгружены данные по auth и layouts
                 let classnum = this.$store.state.state_tables.state_auth.r_auth['classnum'];
                 let lid = this.$store.state.state_tables.state_auth.r_auth['lid'];
                 form['json_links'] = this.$store.state.state_tables.state_layouts.json_layouts.layouts['class_' + classnum][lid]['json_links'];
                 }                    
                 else
                 {
                 form['json_links']={};
                 }
                 
                 } catch (ex) {
                 console.log("computed: form error");
                 console.log(ex);
                 }
                 */
                return form;
            }


        },
        watch: {
            
//    $route(to, from) {
//      const toDepth = to.path.split('/').length
//      const fromDepth = from.path.split('/').length
//      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
//    },

            "form.cnt_loaded": {
                handler: async function (newValue, oldValue) {
                    //    console.log("LAYOUTCHILD CNT_CNT_CNT ");
                        
                    //STAGE GAME SETTINGS
                    
                    
                    if (
                            this.$store.state.is_loaded_tables.global__level_settings.load_status &&
                            this.$store.state.is_loaded_stages.global__level_settings === "inactive"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "global__level_settings", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE global__level_settings FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if (
                            this.$store.state.is_loaded_tables.level_props__exp_by_tasks.load_status &&
                            this.$store.state.is_loaded_stages.level_props__exp_by_tasks === "inactive"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__exp_by_tasks", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE level_props__exp_by_tasks FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.level_props__gold_by_tasks === "inactive"
                        )
                    {
                        this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__gold_by_tasks__back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__gold_by_tasks", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE level_props__gold_by_tasks RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.level_props__gold_by_tasks === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__gold_by_tasks", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE level_props__gold_by_tasks FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.level_props__joker_by_tasks === "inactive"
                        )
                    {
                        this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__joker_by_tasks__back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__joker_by_tasks", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE level_props__joker_by_tasks RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.level_props__joker_by_tasks === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__joker_by_tasks", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE level_props__joker_by_tasks FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.global__profession_list === "inactive"
                        )
                    {
                        this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___profession_list__back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "global__profession_list", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE global__profession_list RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.global__profession_list === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "global__profession_list", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE global__profession_list FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.player_professions === "inactive" &&
                            this.$store.state.is_loaded_tables.global__profession_list.load_status
                        )
                    {
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().player_professions__ls_or_back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "player_professions", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE player_professions RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.player_professions === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "player_professions", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE player_professions FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.quest_manager === "inactive" &&
                            this.$store.state.is_loaded_tables.player_professions.load_status &&
                            this.$store.state.is_loaded_tables.global__inventory_glossary.load_status
                        )
                    {
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().quest_manager__ls_or_back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "quest_manager", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE quest_manager RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.quest_manager === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "quest_manager", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE quest_manager FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    
                    
                    
                    
                    
                    
                    
                    if (
                            this.$store.state.is_loaded_tables.level_props__solve_limit.load_status &&
                            this.$store.state.is_loaded_stages.level_props__solve_limit === "inactive"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "level_props__solve_limit", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE level_props__solve_limit FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    //STAGE GAME SETTINGS
                    
                    
                    //INVENTORY STAGE
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.global__inventory_glossary === "inactive"
                        )
                    {
//                      1. check ls or go db
//                      
                        this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___inventory_glossary__back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "global__inventory_glossary", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE global__inventory_glossary RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.global__inventory_glossary.load_status &&
                            this.$store.state.is_loaded_stages.global__inventory_glossary === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "global__inventory_glossary", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE global__inventory_glossary FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.player_inventory === "inactive"
                        )
                    {
//                      1. check ls or go db
//                      
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().player_inventory__ls_or_back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "player_inventory", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE player_inventory RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
//                    
                    if  (
                            this.$store.state.is_loaded_tables.player_inventory.load_status &&
                            this.$store.state.is_loaded_stages.player_inventory === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "player_inventory", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE player_inventory FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    //INVENTORY STAGE
                    
                    
                    //STAGE auth
                    if (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.auth === "inactive"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "auth", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE auth FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }


                    //TEST STAGE
                    if  (
                            this.$store.state.is_loaded_tables.r_auth.load_status &&
                            this.$store.state.is_loaded_stages.test3 === "inactive"
                        )
                    {
//                        vuex_set__a_data__ls_or_back
//                      1. check ls or go db
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().test1__back(this.$store, this.cdata));
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().test2__back(this.$store, this.cdata));
                        this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().test3__back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "test3", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE test3 RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    
                    if  (
                            this.$store.state.is_loaded_tables.a_test.load_status &&
                            this.$store.state.is_loaded_tables.a_test2.load_status &&
                            this.$store.state.is_loaded_tables.test3.load_status &&
                            this.$store.state.is_loaded_stages.test3 === "run"
                        )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "test3", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE test3 FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    //TEST STAGE


                    //STAGE layouts
                    if (
                            this.$store.state.is_loaded_tables.json_layouts.load_status &&
                            this.$store.state.is_loaded_stages.layouts === "inactive"
                            )
                    {

                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "layouts", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE layouts FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }


                    //STAGE version
                    if (
                            this.$store.state.is_loaded_tables.json_version.load_status &&
                            this.$store.state.is_loaded_stages.version === "inactive"
                            )
                    {

                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "version", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE version FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }




                    //STAGE event_list
                    if (
                            this.$store.state.is_loaded_tables.events_list.load_status &&
                            this.$store.state.is_loaded_stages.events_list === "inactive"
                            )
                    {

                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "events_list", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE events_list FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }

                    console.log("shift_list|||" + this.$store.state.is_loaded_tables.shift_list.load_status + "|" + this.$store.state.is_loaded_stages.shift_list);
                    //STAGE shift_list
                    if (
                            this.$store.state.is_loaded_tables.shift_list.load_status &&
                            this.$store.state.is_loaded_stages.shift_list === "inactive"
                            )
                    {

                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "shift_list", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE shift_list FIN");
                        this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": true});
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }








                    //!!!AWAIT ПОСЛЕДОВАТЕЛЬНО ВЫПОЛНЯЕТСЯ поэтому 
                    //по завершении стадии она уже выполнила всю работу и получает статус FIN
                    //STAGE initial_shift_fin
                    if (
                            this.$store.state.is_loaded_stages.auth === "fin" &&
                            this.$store.state.is_loaded_stages.layouts === "fin" &&
                            this.$store.state.is_loaded_stages.initial_shift === "inactive"
                            )
                    {
                        if(!this.$store.state.state_tables.state_auth.r_auth['classnum']){
                            this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/setup/');
                        }
                        
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "initial_shift", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE initial_shift RUN");
                        // console.log(this.$store.state.state_tables.state_auth.r_auth['lid']);    
                        if (this.$store.state.state_tables.state_auth.r_auth['lid'].toString() === "-1")
                        {
                            //поставить ребенка на нужную раскладку в соответствии с его классом
                            console.log("Неустановлен стартовый лэйаут, его класс " + this.$store.state.state_tables.state_auth.r_auth['classnum']);
                            //получить для класса, нужный стартовый лэйаут
                            this.cdata.business_logic.start_layout = this.$root._LAYOUT().find_start_layuot_by_class(this.$store, this.cdata);
                            //this.$store.dispatch('_data/vuex_update__a_data__ls_or_back', this.$root._UPDATE().r_auth_change_lid__ls_or_back(this.$store, this.cdata));

// некий код
//time = performance.now();
//time = performance.now() - time;
//console.log('Время выполнения = ', time);

                            var time = performance.now();
                            console.log("step_orger+++!!!1|0");

                            let pararm_initial_shift = {};
                            pararm_initial_shift['new_lid'] = this.cdata.business_logic.start_layout;
                            pararm_initial_shift['new_classnum'] = this.$store.state.state_tables.state_auth.r_auth['classnum']; //НЕ МЕНЯЕТСЯ!!! ТОТ ЖЕ!!!
                            /* AWAIT!!!!*/              await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().r_auth_change_lid_and_classnum__multi_ls_and_back(this.$store, this.cdata, pararm_initial_shift));
                            // $store.dispatch('_system/vuex_set__lock_clear', {method: 'r_auth_change_lid'});


                            let delta12 = performance.now() - time;
                            console.log("step_orger+++!!!2|" + delta12);
                        } else
                        {


                            this.cdata.business_logic.start_layout = this.$store.state.state_tables.state_auth.r_auth['lid'];
                            console.log("Cтартовый лэйаут " + this.$store.state.state_tables.state_auth.r_auth['lid'] + ", его класс " + this.$store.state.state_tables.state_auth.r_auth['classnum']);
                        }
                        let delta13 = performance.now() - time;
                        //console.log("step_orger+++!!!3|" + delta13);
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "initial_shift", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE initial_shift FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }




                    //STAGE rg_for_task RUN
                    if (
                            this.$store.state.is_loaded_stages.initial_shift === "fin" &&
                            this.$store.state.is_loaded_stages.rg_for_task === "inactive"
                            )
                    {
                        console.log("LAYOUTCHILD STAGE rg_for_task RUN");
                        this.$store.dispatch('_data/vuex_set__a_data__ls_or_back', this.$root._LOAD().rg_learning__ls_or_back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "rg_for_task", stage_value: "run"});
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }
                    //STAGE rg_for_task FIN                    
                    if (
                            this.$store.state.is_loaded_tables.rg_learning.load_status &&
                            this.$store.state.is_loaded_stages.rg_for_task === "run"
                            )
                    {
                        console.log("LAYOUTCHILD STAGE rg_for_task FIN");
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "rg_for_task", stage_value: "fin"});
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }



                    //STAGE st_for_task RUN
                    if (
                            this.$store.state.is_loaded_stages.initial_shift === "fin" &&
                            this.$store.state.is_loaded_stages.st_for_task === "inactive"
                            )
                    {

                        this.$store.dispatch('_data/vuex_set__a_data__ls_or_back', this.$root._LOAD().st_cards__ls_or_back(this.$store, this.cdata));
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "st_for_task", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE st_for_task RUN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }


                    //STAGE st_for_task FIN                    
                    if (
                            this.$store.state.is_loaded_tables.st_cards.load_status &&
                            this.$store.state.is_loaded_stages.st_for_task === "run"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "st_for_task", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE st_for_task FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }





                    //ПАРАЛЛЕЛЬНО идет подгрузка динамических таблиц
                    //поэтому нужна еще одна стадия - ожидания, когда они все подгрузятся!
                    //STAGE dynamic_desks_startload RUN
                    if (
                            this.$store.state.is_loaded_stages.auth === "fin" &&
                            this.$store.state.is_loaded_stages.layouts === "fin" &&
                            this.$store.state.is_loaded_stages.initial_shift === "fin" &&
                            this.$store.state.is_loaded_stages.dynamic_desks_startload === "inactive"
                            )
                    {
                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "dynamic_desks_startload", stage_value: "run"});
                        console.log("LAYOUTCHILD STAGE dynamic_desks_startload RUN");
                        let tables_filter = this.$root._LAYOUT().get_actual_layuot_filter(this.$store, this.cdata);
                        //console.log("tables_filter");
                        //console.log(tables_filter);

                        for (const [tables_filter_id, table_value] of Object.entries(tables_filter)) {

                            //ПРОПИШЕМ ТАБЛИЦЫ В БЛОКИРОВКУ И В СЕКЦИИ ! ПРОБЛЕМА С РЕАКТИВНОСТЬЮ - то что добавляется динамически, внутри не реактивно!
                            let state_desks_conf = {};
                            state_desks_conf['table_name'] = table_value['table_name'];
                            state_desks_conf['table_version'] = table_value['table_version'];
                            state_desks_conf['_route_'] = this.cdata['system']['_route_'];
                            //в какие страницы
                            state_desks_conf['_routes_'] = {};
                            // state_desks_conf['_routes_']['0'] = 'g1_empty';
                            // state_desks_conf['_routes_']['1'] = 'g1_empty2';
                            state_desks_conf['_routes_']['0'] = 'g1_desksweek';
                            //state_desks_conf['_routes_']['0'] = 'g1_first';
                            //state_desks_conf['_routes_']['1'] = 'g1_first'; //НЕ добавлять, чтобы блокировка кнопок работала корректно
                            //В какие секции 
                            //? TODO ВО ВСЕ СТАДИИ??? или вообще только в центральную...
                            state_desks_conf['_sections_'] = {};
                            state_desks_conf['_sections_']['0'] = 'center';
                            //state_desks_conf['_sections_']['1'] = 'center2';

                            this.$store.dispatch('_diyamic_tables/vuex_set__dynamic_desks', state_desks_conf);
                        }



                        //попробуем загрузить динамические таблицы!

                        for (const [tables_filter_id, table_value] of Object.entries(tables_filter)) {

                            //при добавлении нулим и ставим дефолтные значения.
                            //console.log("GET_VERSION " + table_value['table_name']);
                            //   let table_version =  table_value['table_version'];   //this.$root._LAYOUT().get_desk_version_by_desk_table_name(this.$store, this.cdata, table_value['table_name']);
                            console.log(table_value['table_version'] + "|" + table_value['table_version']);
                            //console.log("---");

                            //console.log("TRY LOAD... " + table_value['table_name']);

                            //АСИНХРОННО!!
                            this.$store.dispatch('_json/vuex_set__json_data__version_ls_or_back', this.$root._LOAD().json_layout_xxxx__pos_xx__ls_or_back(this.$store, this.cdata, table_value['table_name'], table_value['table_version']));
                        }



                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "dynamic_desks_startload", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE dynamic_desks_startload FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }








                    //STAGE dynamic_desks_ready FIN
                    if (
                            this.$store.state.is_loaded_stages.dynamic_desks_startload === "fin" &&
                            this.$store.state.is_loaded_stages.dynamic_desks_ready === "inactive" &&
                            this.$root._LAYOUT().is_loaded_dynamic_tables_by_layuot_filter(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_startload)
                            )
                    {





                        this.$store.dispatch('_system/vuex_set__stage', {stage_name: "dynamic_desks_ready", stage_value: "fin"});
                        console.log("LAYOUTCHILD STAGE dynamic_desks_ready FIN");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                        return;
                    }



                    //======================================================================
                    //проверяет завершены ли все стадии
                    if (
                            this.cdata.system.is_mounted_execute &&
                            !this.$store.state.is_all_stage_fin
                            ) {

                        let is_false_stage = false;
                        for (const [stage_name, stage_value] of Object.entries(this.$store.state.is_loaded_stages)) {
                            if (stage_value === "inactive" || stage_value === "run")
                            {
                                is_false_stage = true;
                            }
                        }
                        if (!is_false_stage) {

                            this.$store.dispatch('_system/vuex_set__is_all_stage_fin', {"is_all_stage_fin": true});
                            console.log("LAYOUTCHILD ALL STAGE FIN");
                            this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                            
                            
                            //ОБУЧАЛКА
                            if(this.$store.state._route_ != 'g1_child_setup'){
                                let showed_modal_before = localStorage.getItem("intro_modal");
                                if(!showed_modal_before) {
                                    localStorage.setItem("intro_modal", true);
                                    this.cdata.business_logic.show_story_intro_modal = true;
                                } else {
                                    this.cdata.business_logic.show_story_intro_modal = false;
                                }
                            }
                            
                            
                        }
                    }













                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

            "cdata.system.timer_checkversion_tick": {
                async handler(value) {

                    if (value > 0) {

                        let timer_step = this.cdata.system.timer_checkversion_step; //sec

                        setTimeout(() => {
                            //this.cdata.system['timer_checkversion_tick']--;
                            this.cdata.system['timer_checkversion_tick'] = this.cdata.system['timer_checkversion_tick'] - timer_step;
                        }, timer_step * 1000); //



                    } else
                    {
                        this.cdata.system['timer_checkversion_tick'] = this.cdata.system.timer_checkversion_value;
                        //console.log("TIMER ACTION timer_checkversion_tick");

////if (window._STR().is_not_empty(this.cdata['system']['cnf']['server_cloud']))
                        if (this.$store.state.is_all_stage_fin)
                        {
                            console.log("LLL " + this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc + " RUN");
                            await  this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json_version__back(this.$store, this.cdata));
                            console.log("LLL " + this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc + " FIN");

                            let last_reset_timestamp = localStorage.getItem("last_reset_timestamp");
                            if (last_reset_timestamp === null)
                            {
                                localStorage.setItem("last_reset_timestamp", this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc);
                            } else
                            {
                                console.log(parseInt(this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10)+" ?>? "+parseInt(last_reset_timestamp, 10));
                                if (parseInt(this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10) > parseInt(last_reset_timestamp, 10))
                                {
                                    //RESET IN _onstorge func in main.js 
                                    console.log("DO RESET"); 
                                    localStorage.setItem("last_reset_timestamp", parseInt(this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10));
                                    //на других вкладках сработает, если более одной владки!!!!
                                    api_idb.deleteAllTables();
                                    this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/reset/');
                                    
                                }
                            }
                        }


                    }



                },
                immediate: true // This ensures the watcher is triggered upon creation
            },

            "cdata.system.timer_check_gametype_tick": {
                async handler(value) {

                    this.cdata.system.curr_event.event_timings.delta_s = this.$store.state.state_tables.state_auth.r_auth['delta_s'];
                    this.cdata.system.curr_event.event_timings.curr_timestamp = Math.floor(new Date().getTime() / 1000.0) + parseInt(this.cdata.system.curr_event.event_timings.delta_s);
                    //установить в store!!!! и брать уже оттуда!
                    this.$store.dispatch('_system/vuex_set__curr_timestamp', {"curr_timestamp": this.cdata.system.curr_event.event_timings.curr_timestamp});
                    if (value > 0) {

                        let timer_step = this.cdata.system.timer_check_gametype_step; //sec

                        setTimeout(() => {
                            //this.cdata.system['timer_check_gametype_tick']--;
                            this.cdata.system['timer_check_gametype_tick'] = this.cdata.system['timer_check_gametype_tick'] - timer_step;
                        }, timer_step * 1000); //



                    } else
                    {
                        this.cdata.system['timer_check_gametype_tick'] = this.cdata.system.timer_check_gametype_value;
                        //console.log("TIMER ACTION timer_check_gametype_tick");

                        //?ЕСЛИ нет блокировки...

                        this.$root._TIMINGS().set_curr_event(this.$store, this.cdata);


                        this.$root._TIMINGS().set_stage_flags(this.$store, this.cdata);
                        await  this.$store.dispatch('_system/vuex_set__timings', this.cdata.system.curr_event);


                    }



                },
                immediate: true // This ensures the watcher is triggered upon creation
            },

            "cdata.system.timer_summary_tick": {
                async  handler(value) {

                    if (value > 0) {

                        let timer_step = this.cdata.system.timer_summary_step; //sec; //sec
                        setTimeout(() => {
                            //this.cdata.system.timer_summary_tick--;
                            this.cdata.system.timer_summary_tick = this.cdata.system.timer_summary_tick - timer_step;
                        }, timer_step * 1000); //

                        console.log("TIMER TICK summary " + this.cdata.system.timer_summary_tick);
                    } else
                    {
                        this.cdata.system.timer_summary_tick = this.cdata.system.timer_summary_value;
                        console.log("TIMER ACTION summary");


                        if (this.$store.state.is_all_stage_fin)
                        {
                            //обновим список мерориятий
                            await  this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().events_list__back(this.$store, this.cdata));

                        }


                    }

                }
            },

            "cdata.system.timer_check_shift_tick": {
                async  handler(value) {

                    if (value > 0) {

                        let timer_step = this.cdata.system.timer_check_shift_step; //sec; //sec; //sec
                        setTimeout(() => {
                            //this.cdata.system.timer_check_shift_tick--;
                            this.cdata.system.timer_check_shift_tick = this.cdata.system.timer_check_shift_tick - timer_step;
                        }, timer_step * 1000); //

                        console.log("TIMER TICK check_shift" + this.cdata.system.timer_check_shift_tick);
                    } else
                    {

                        if (this.$store.state.timers.is_active_timer_check_shift)
                        {

                            this.cdata.system.timer_check_shift_tick = this.cdata.system.timer_check_shift_value;
                            console.log("TIMER ACTION check_shift");





                            if (this.$store.state.is_all_stage_fin)
                            {


                                // Он и не должен это постоянно обновлять... только раз в год в момент сдвига....await  this.$store.dispatch('_data/vuex_set__a_data__back', this.$root._LOAD().shift_list__back(this.$store, this.cdata));


//-=-=-=-=-= mmmmmm


                                //=============================== SHIFT LEARNING   FORSE TURN ON INSIDE
                                let result_shift_by_learning = this.$root._SHIFT().check_shift_by_rg_learning(this.$store, this.cdata, this.state);
                                //Надо ли делать сдвиг по выученности?
                                //result_shift_by_learning['is_shift']=true/false; 
                                //result_shift_by_learning['info']=
                                console.log("SHIFTX learning is_shift=" + result_shift_by_learning['is_shift'] + ", info=" + result_shift_by_learning['info']);

                                let next_shift_by_learning = {};
                                if (result_shift_by_learning['is_shift'])
                                {
                                    //сдвиг на следующий от текущего лид, если имеется! Иначе гейм овер!
                                    next_shift_by_learning = this.$root._LAYOUT().find_next_layuot_reason_learning(this.$store, this.cdata);

//                           //Если следующий лид существует, вернет новый класс и лид или флаг конец игры
//                            next_shift_by_learning['is_gameover']=;
//                            next_shift_by_learning['new_classnum']
//                            next_shift_by_learning['new_lid']  

                                    console.log("SHIFTX is_gameover=" + next_shift_by_learning['is_gameover'] + ", new_classnum=" + next_shift_by_learning['new_classnum'] + ", new_lid=" + next_shift_by_learning['new_lid']);


                                    if (!next_shift_by_learning['is_gameover']) {
                                        //сделать сдвиг, если новые неравны старым
                                        console.log("SHIFTX DO_SHIFT - result_shift_by_learning");
                                        let pararm_shift_by_learning = {};
                                        pararm_shift_by_learning['new_lid'] = next_shift_by_learning['new_lid'];
                                        pararm_shift_by_learning['new_classnum'] = next_shift_by_learning['new_classnum'];


                                        this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": false});

                                        await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().r_auth_change_lid_and_classnum__multi_ls_and_back(this.$store, this.cdata, pararm_shift_by_learning));
                                        this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/shift/?type=learning');
                                        return;



                                    } else
                                    {
                                        console.log("SHIFTX GAME OVER - result_shift_by_learning");

                                        this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": false});

                                        this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/gameover/?type=learning');
                                        //редирект не гейм овер
                                        return;
                                    }

                                }




                                //=============================== SHIFT DATETIME   

                                let result_shift_by_datetime = this.$root._SHIFT().check_shift_by_datetime(this.$store, this.cdata, this.state);
                                //Вернет надо ли делать сдвиг вперед по тиблице временного сдвига                            
                                //                            result_shift_by_datetime['is_shift']=true/false;
                                //                            result_shift_by_datetime['new_classnum']=;
                                //                            result_shift_by_datetime['new_lid']=;
                                //                            result_shift_by_datetime['info']=  
                                console.log("SHIFTY is_shift=" + result_shift_by_datetime['is_shift'] + ", new_classnum=" + result_shift_by_datetime['new_classnum'] + ", new_lid=" + result_shift_by_datetime['new_lid']);

                                if (result_shift_by_datetime['is_shift'])
                                {
                                    //сделать сдвиг, если новые неравны старым

                                    if (this.$store.state.state_tables.state_auth.r_auth.lid.toString() !== result_shift_by_datetime['new_lid'].toString())
                                    {
                                        console.log("SHIFTY DO_SHIFT - result_shift_by_datetime");
                                        let pararm_shift_by_datetime = {};
                                        pararm_shift_by_datetime['new_lid'] = result_shift_by_datetime['new_lid'];
                                        pararm_shift_by_datetime['new_classnum'] = result_shift_by_datetime['new_classnum'];

                                        this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": false});

                                        await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().r_auth_change_lid_and_classnum__multi_ls_and_back(this.$store, this.cdata, pararm_shift_by_datetime));
                                        this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/shift/?type=timeshift');
                                        return;
                                    } else
                                    {
                                        console.log("SHIFTY ERROR!!!???? new lid = current lid.");
                                    }
                                    //result_shift_by_datetime['new_classnum']
                                    //result_shift_by_datetime['new_lid']
                                    //TODO просто сделать сдвиг на класс и лид и на страницу перезагрузки раскладки!
                                } else
                                {
                                    if (result_shift_by_datetime['is_row_dont_find'] && this.$store.state.state_tables.state_auth.r_auth.classnum.toString() === "5")
                                    {

                                        console.log("SHIFTY GAME OVER - result_shift_by_datetime");

                                        this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": false});

                                        this.$root._PAGE().move_to(this.$store, this.cdata, '/g1/gameover/?type=timeshift');
                                        //редирект не гейм овер
                                        return;


                                    } else
                                    {
                                        console.log("SHIFTY DONT ALLOW !!! reason: " + result_shift_by_datetime['info']);
                                    }
                                }



//=-=-=-=











                            }

                        }
                    }

                }
            },

        },

        methods: {
            admin_controls__solved_info_toggle(){
                this.$root._ADMIN().toggle_solved_info__admin_controls(this.cdata.business_logic.admin_controls.admin_controls__solved_info);
            },
            admin_controls__daily_limit_toggle(){
                this.$root._ADMIN().toggle_daily_limit__admin_controls(this.cdata.business_logic.admin_controls.admin_controls__daily_limit);
            },
            
            admin_controls__right_answers_toggle(){
                this.$root._ADMIN().toggle_right_answers__admin_controls(this.cdata.business_logic.admin_controls.admin_controls__right_answers);
            },
            
            toggle_admin_controls(value){
                this.cdata.business_logic.show_admin_control_modal = value;
            },
            
            onSlideEnd(slide) {
                this.sliding = false;
                
                if(slide == 0) {
                    this.cdata.business_logic.show_story_intro_modal = false;
                }
            },
            
            show_info_modal: function() {
//                this.$root._LAYOUT().hide_info_modal(this.$store, this.cdata);
                this.cdata.business_logic.show_story_intro_modal = true;
            },
            
            hide_info_modal: function() {
//                this.$root._LAYOUT().hide_info_modal(this.$store, this.cdata);
                this.cdata.business_logic.show_story_intro_modal = false;
            },
            
            modal__goto_tutorial: function() {
                this.cdata.business_logic.show_story_intro_modal = false;
                this.$router.push('/book/boutstory?qr=main_story_1_A1');
//                /book/boutstory?qr=main_story_1_A1&back=DECODE_BACK_LINK_AND_IDS_TO_ACCSEPT_OR_SUCCESS_QUEST&v=1672163852
            },
            
            show_bcg: function() {
                    if(this.$store.state._route_ === 'g1_cardweek' ||
                       this.$store.state._route_ === 'g1_child_setup' ||
                       this.$store.state._route_ === 'book_boutstory'){
                        return "";
                    } else {
                        return "background: #F4F5FD;";
                    }
            },
            

            is_header_type1: function () {
                if (
//                        this.$store.state._route_ === 'g1_first' ||
                        this.$store.state._route_ === 'g1_empty' ||
                        this.$store.state._route_ === 'g1_empty2' ||
                        this.$store.state._route_ === 'g1_desksweek' ||
//                        this.$store.state._route_ === 'g1_cardweek' ||
                        this.$store.state._route_ === 'g1_template1' ||
                        this.$store.state._route_ === 'g1_inventory' ||
                        this.$store.state._route_ === 'g1_professions' ||
                        
                        this.$store.state._route_ === 'g1_story' ||
                        
                        this.$store.state._route_ === 'book_boutchoose' ||
                        this.$store.state._route_ === 'book_boutpart'
                        )
                {
                    return true;
                } else {
                    return false;
                }
//                return true;
            },
            
            is_header_type_main_game: function () {
                if  (
                        this.$store.state._route_ === 'g1_cardweek'
                    ) {
                    return true;
                } else {
                    return false;
                }
            },
            
            is_no_header_type_story_mode: function () {
                if  (
                        this.$store.state._route_ === 'book_boutstory'
                    ) {
                    return true;
                } else {
                    return false;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    /* сделать чтобы уезжал олготип и меню, и чтобы убирался отступ, появившийся из-за уехавшего логотипа и меню - уже в компоненте*/
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: unset!important;
        }
    }
    



    
    
    


.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
    













/*MODAL*/
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: #00000059;
    
    & .modal-content {
        border: none;
        border-radius: 16px;
        padding: 24px;
    }
    
    & .custom_modal__header {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        
        & h5 {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
        }
    }
}

.bounce-enter-active {
  animation: bounce-in .8s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
/*END MODAL*/




.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-active /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-active {
  transform: translate(-100%, 0);
}



.custom_modal__welcome_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & p {
        color: #2E3B4B;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }
    
    & .btn_negative {
        border:none;
        width: 200px;
        background: #FF7697;
        -webkit-box-shadow: 0px 4px 0px #dc6582;
        box-shadow: 0px 4px 0px #dc6582;
        border-radius: 12px;
        padding: 8px 24px;
        
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
    }
    
    & .btn_accept {
        width: 200px;
        background: #55D18E;
        -webkit-box-shadow: 0px 4px 0px #4abb7e;
        box-shadow: 0px 4px 0px #4abb7e;
        border-radius: 12px;
        padding: 8px 24px;
        border: none;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
    }
}
</style>