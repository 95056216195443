<template>
    <b-navbar v-if="this.$store.state._route_!==''" id="template-header" class="navbar default-layout-navbar p-0" toggleable="lg">

        <div class="horizontal-menu" v-if='form.is_all_stage_fin'>  


            <!-- 1) TOP LINE -->
            <nav class="navbar col-lg-12 col-12 p-0">
                
                <div class="container-fluid px-5">
                    
                    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-start">
                        <nav class="navbar navbar-expand-lg">
                            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <div class="navbar-nav">
<!--                                    <router-link class="nav-item nav-link d-flex align-items-center" v-bind:to="'/book/boutchoose/'+'?t='+$root._SYS().add_v()">
                                        <img class="menu-custom-icon" src="/resource/img/game_ico/nav/nav_game.svg" alt="alt"/>
                                        <span class="menu-custom-title ml-2">Story</span>
                                    </router-link>-->


<!--                                    <router-link class="nav-item nav-link d-flex align-items-end" v-bind:to="'/g1/desksweek/'+'?t='+$root._SYS().add_v()">
                                        <i class="mdi mdi-video-input-component"></i>
                                        <span class="menu-custom-title ml-1">Dashboard</span>
                                    </router-link>

                                    <router-link class="nav-item nav-link d-flex align-items-end" v-bind:to="'/g1/professions/'+'?t='+$root._SYS().add_v()">
                                        <i class="mdi mdi-finance"></i>
                                        <span class="menu-custom-title ml-1">Professions</span>
                                    </router-link>

                                    <router-link class="nav-item nav-link d-flex align-items-end" v-bind:to="'/g1/inventory/'+'?t='+$root._SYS().add_v()">
                                        <i class="mdi mdi-store-outline"></i>
                                        <span class="menu-custom-title ml-1">Inventory</span>
                                    </router-link>-->

<!--                                    <router-link class="nav-item nav-link d-flex align-items-end" v-bind:to="'/g1/story/'+'?t='+$root._SYS().add_v()">
                                        <i class="mdi mdi-book-open-page-variant"></i>
                                        <span class="menu-custom-title ml-1">Story</span>
                                    </router-link>-->

                                </div>
                            </div>
                        </nav>
                    </div>


                    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                        
<!--                        <div class="d-flex flex-column w-50">
                            <div class="progress w-100 h-25">
                                <div class="progress-bar" role="progressbar" :style="{ 'width': state['lvl_info']['percentage_to_lvl_up']+'%' }" :aria-valuenow="state['lvl_info']['percentage_to_lvl_up']" aria-valuemin="0" aria-valuemax="100">
                                    {{ Number(state['lvl_info']['current_exp']) >= Number(state['lvl_info']['current_lvl_max']) ? 
                                        'MAX LEVEL (' + (Number(state['lvl_info']['current_lvl']) + 1) + ')' : 
                                        state['lvl_info']['percentage_to_lvl_up'] + '% (' + (state['lvl_info']['current_lvl']) + ')' }}
                                </div>
                            </div>
                        
                            <small class="text-center">{{state['lvl_info']['current_exp'] + " - " + state['lvl_info']['current_lvl_max']}}</small>
                        </div>-->
                        
                        <div class="d-flex align-items-center nav__resources">
                            
                                <div class="custom_tooltip" data-side="bottom">
                                    <div class="d-flex align-items-center justify-content-center nav_resources mr-3">
                                        <p class="mr-1 ">{{state['user_stats']['joker']['item_count']}}</p>
                                        <img src="/resource/img/game_ico/joker_coin.svg" alt="image"/>
                                    </div>
                                    <span class="tooltiptext">Universal Charge</span>
                                </div>
                            
                                <div class="custom_tooltip" data-side="bottom">
                                    <div class="d-flex align-items-center justify-content-center nav_resources mr-3">
                                        <p class="mr-1 ">{{state['user_stats']['gold']['item_count']}}</p>
                                        <img src="/resource/img/game_ico/gold.svg" alt="image"/>
                                    </div>
                                    <span class="tooltiptext">Credits</span>
                                </div>
                            
                                
                                <div class="custom_tooltip" data-side="bottom">
                                    <div class="d-flex align-items-center justify-content-center nav_resources">
                                        <p class="mr-1">{{state['user_stats']['exp']['item_count']}}</p>
                                        <img src="/resource/img/game_ico/exp.svg" alt="image"/>
                                    </div>
                                    <span class="tooltiptext">Experience</span>
                                </div>
                            
                        </div>

<!--                        <div class="doughnut_average">
                                <doughnut-chart :width="40" :height="40" :chartData="chartData" class='charts_custom__canvas'></doughnut-chart>
                                <p class="doughnut_average__text">12</p>
                        </div>-->
                        
                        <b-navbar-nav class="navbar-nav-right m-0">
                            <b-nav-item-dropdown right class="nav-profile">
                                <template slot="button-content">
                                    <span
                                        class="nav-link dropdown-toggle"
                                        id="profileDropdown"
                                        href="javascript:void(0);"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        > 
                                        <div>
                                            
                                <div class="custom_tooltip" data-side="bottom" style="z-index:3;">
                                        <div class="doughnut_average" style="pointer-events: none;">
                                                <doughnut-chart :width="40" :height="40" :chartData="current_lvl_chartData" class='charts_custom__canvas'></doughnut-chart>
                                                <p class="doughnut_average__text">
                                                {{ state['lvl_info']['current_lvl'] }}
<!--                                                Number(state['lvl_info']['current_exp']) >= Number(state['lvl_info']['current_lvl_max']) ? 
                                                    (Number(state['lvl_info']['current_lvl']) + 1) : 
                                                    (state['lvl_info']['current_lvl'])-->
                                                </p>
                                            </div>
                                    <div class="tooltiptext">
                                        <p>Level {{ state['lvl_info']['current_lvl'] }}</p>
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <p>{{state['lvl_info']['current_exp']}}</p>
                                                <img class="ml-1" src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;"/>
                                            </div>
                                            
                                            <p class="mx-2">/</p>
                                            
                                            <div class="d-flex align-items-center">
                                                <p>{{state['lvl_info']['current_lvl_max']}}</p>
                                                <img class="ml-1" src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                            
<!--                                            <div class="doughnut_average">
                                                <doughnut-chart :width="40" :height="40" :chartData="current_lvl_chartData" class='charts_custom__canvas'></doughnut-chart>
                                                <p class="doughnut_average__text">
                                                {{ Number(state['lvl_info']['current_exp']) >= Number(state['lvl_info']['current_lvl_max']) ? 
                                                    (Number(state['lvl_info']['current_lvl']) + 1) : 
                                                    (state['lvl_info']['current_lvl']) }}
                                                </p>
                                            </div>-->
                                        </div>
                                        <div class="nav-profile-text">
                                            <p class="mb-0"> {{this.state.auth.r_auth.name}}<i class="mdi mdi-chevron-down"></i> </p>
                                        </div>
                                    </span>
                                </template>                  
<!--                                <b-dropdown-item class="preview-item" v-on:click="to_parent_profile">
                                    <i class="mdi mdi-account mr-2 text-primary"></i> Parent account
                                </b-dropdown-item>-->
                                <b-dropdown-item class="preview-item" v-on:click="logout">
                                    <i class="mdi mdi-logout mr-2 text-primary"></i> Log out
                                </b-dropdown-item>

                            </b-nav-item-dropdown>
                        </b-navbar-nav>
<!--                        <button class="navbar-toggler navbar-toggler-right align-self-center"
                                @click="toggleMobileSidebar()">
                            <span class="mdi mdi-menu"></span>
                        </button>-->
                    </div>
                </div>
            </nav>

            <!-- 2) MENU -->
<!--            <nav class="bottom-navbar">
                <div class="container align-items-start  ">
                    <ul class="nav page-navigation align-items-left" style="width:unset; "  >
                        <li class="nav-item" style="color:white;">
                            <router-link class="nav-link" v-bind:to="'/g1/desksweek/'+'?t='+$root._SYS().add_v()">
                                <i class="mdi mdi-flag-variant menu-custom-icon" style="color:white;"></i>
                                <span class="menu-custom-title" style="color:white;">Solving Cards</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </nav> -->

        </div>    


    </b-navbar>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import doughnutChart from "@/ucomponents/charts/chartjs/doughnutChartDinamicData";



            var cdata = {

                test_cnt:0,
                test_rand:-1,

                // _route_ = header1!!!



                _data: {

                    a_registrations: '',
                    /*global - on all lkchild and action*/
                    timer_reload_file__version_json: 0,
                    /*only for menu and FORCE REDIRECT TO TRANSLATION*/
                    timer_check_menu_stage_change: 0,
                    stages: {
                        is_stage_end: false,
                        is_stage_shop: false,
                        is_stage_transl2: false,
                        is_stage_start: false,
                        is_stage_tryplay: false,
                        is_stage_transl: false,
                        is_stage_pre: false,
                        curr_timestamp: 0,
                        timestamp_utm_stage_end: 0,
                        timestamp_utm_stage_shop: 0,
                        timestamp_utm_stage_transl2: 0,
                        timestamp_utm_stage_start: 0,
                        timestamp_utm_stage_tryplay: 0,
                        timestamp_utm_stage_transl: 0,
                        timestamp_utm_stage_pre: 0
                    },

                },

                is_curr_page_active: false,

            }

    export default {
        name: "app-header",
        data() { //тюненгуемые переменные и функции
            return {
                cdata: cdata,
            };
        },
        components: {
            doughnutChart,
        },
        
        created() {
            
            window.addEventListener("scroll", this.handleSCroll);
        },
        mounted() {

            console.log("##############HEADER");


           this.cdata.test_cnt=this.cdata.test_cnt+1;
           this.cdata.test_rand=Math.floor(Math.random() * 100);

            /* SET DEBUG TIMINGS */

            /*
             var url = new URL(window.location.href);
             let debug = url.searchParams.get("debug");
             if (debug === "true") {
             localStorage.setItem('__debug_timings', "1");
             }*/
            /* SET DEBUG TIMINGS */

 
            var navItems = document.querySelectorAll(
                    ".horizontal-menu .page-navigation >.nav-item"
                    );
            document
                    .querySelectorAll(".horizontal-menu .page-navigation >.nav-item")
                    .forEach(function (el) {
                        el.addEventListener("click", function () {
                            for (var i = 0; i < navItems.length; i++) {
                                if (navItems[i] == el) {
                                    el.classList.toggle("show-submenu");
                                } else {
                                    navItems[i].classList.remove("show-submenu");
                                }
                            }
                        });
                    });
            window.addEventListener('focus', this.page_focused);
            window.addEventListener('blur', this.page_blured);
            window.addEventListener('pageshow', this.page_focused);
            window.addEventListener('pagehide', this.page_blured);
            this.page_focused();
        },
        methods: {

            to_parent_profile() {
                this.$root._AUTH().to_parent_profile();
//                console.log("out");
                //this.$router.push('/lkparent/choosegamesstatp/' + '?t=' + this.$root._SYS().add_v());
            },
            doc_visiblitychanged: function () {

                if (document.hidden) {
                    console.log('document.hidden');
                    this.cdata['is_curr_page_active'] = false;
                } else
                {
                    console.log('document.shoed');
                    this.cdata['is_curr_page_active'] = true;
                }

                //this.$refs.commandLine.blur();
            },
            page_blured: function () {
                console.log('blur');
                this.cdata['is_curr_page_active'] = false;
                //this.$refs.commandLine.blur();
            },
            page_focused: function () {
                console.log('focus');
                this.cdata['is_curr_page_active'] = true;
                //this.$refs.commandLine.focus();
            },
            logout: function () {
                this.$root._AUTH().logout();
            },
            toggleMobileSidebar: () => {
                document
                        .querySelector(".top-navbar")
                        .classList.toggle("header-toggled");
//                bottom-navbar
            },
            handleSCroll() {
                let header = document.querySelector("body");
                if (window.scrollY > 70) {
                    header.classList.add("fixed-on-scroll");
                } else {
                    header.classList.remove("fixed-on-scroll");
                }
            }
        },
        computed: {
            current_lvl_chartData() {
                let output = {
                    datasets: [
                      {
                        backgroundColor: ['#49BDF4', '#C3DAFF'],
                        data: [0, 0]
//                        data: [40, 20]
                      }
                    ]
                };
        
                try{
                    if(this.state['lvl_info']['current_lvl_max'] == this.state['lvl_info']['current_exp']) {
                        output['datasets'][0]['data'][0] = 1;
                        output['datasets'][0]['data'][1] = 0;
                        
                        output['datasets'][0]['backgroundColor'][0] = '#ffa600';
                    } else {
                        let current_exp = Number(this.state['lvl_info']['current_exp']) - Number(this.state['lvl_info']['current_lvl_min']);
                        let exp_needed = (Number(this.state['lvl_info']['current_lvl_max']) - Number(this.state['lvl_info']['current_lvl_min'])) - current_exp;
                        output['datasets'][0]['data'][0] = current_exp;
                        output['datasets'][0]['data'][1] = exp_needed;
                    }
                    
                } catch(ex){
                    console.log(ex);
                }
        
                return output;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['test'] = this.$store.state['state_tables'].state_test;/* MODIFY */
                    state['auth'] = this.$store.state['state_tables'].state_auth;/* MODIFY */
                    state['layouts'] = this.$store.state['state_tables'].state_layouts;/* MODIFY */

                    state['desks'] = this.$root._LAYOUT().add_desks_to_state_desks(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_ready);
                    
                    state['lvl_info'] = this.$root._UPDATE().current_player_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__level_settings);
                    state['user_stats'] = this.$root._UPDATE().user_stats(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },
            
            form() {
                let form = {};
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                return form;
            }
        },
        beforeDestroy() {

            window.removeEventListener("focus", this.page_focused);
            window.removeEventListener("blur", this.page_blured);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleSCroll);
        },

        watch: {

        }
 

    };
</script>

<style scoped>

    .nav-item i{
        font-size: 22px;
    }

    .menu_logos
    {
        width:420px!important;
        max-width: unset!important;
    }
    .menu_logoexpo
    {
        width:100px!important;
        height: 45px!important;
        max-width: unset!important;
    }
    .menu_logomikebi
    {
        width:100px!important;
        height: 45px!important;
        max-width: unset!important;
    }

    /* 1040 388  */
    .menu_pgrands
    {
        width:135px!important;
        height: 50px!important;
        max-width: unset!important;
    }




    @media (max-width: 992px) {
        .menu_logos
        {
            width:335px!important;

        }
        .menu_logoexpo
        {
            width:78px!important;
            height: 35px!important;
            max-width: unset!important;
        }
        .menu_logomikebi
        {
            width:78px!important;
            height: 35px!important;
            max-width: unset!important;
        }
        /* 1040 388  */
        .menu_pgrands
        {
            width:94px!important;
            height: 35px!important;
            max-width: unset!important;
        }





    }




    @media (max-width: 413px) {
        .menu_logos
        {
            width:235px!important;

        }
        .menu_logoexpo
        {
            width:52px!important;
            height: 26px!important;
            max-width: unset!important;
        }
        .menu_logomikebi
        {
            width:52px!important;
            height: 26px!important;
            max-width: unset!important;
        }
        /* 1040 388  */
        .menu_pgrands
        {
            width:65px!important;
            height: 24px!important;
            max-width: unset!important;
        }





    }





    @media (max-width: 319px){

        .menu_menu_logoscontent{
            display: none; /*display: block;*/
        }

    }



    /* сделать чтобы уезжал олготип и меню, и чтобы убирался отступ, появившийся из-за уехавшего логотипа и меню - уже в компоненте*/
    @media (max-width: 991px){
        .horizontal-menu {
            position: unset!important;
        }
    }



    .nav_resources {
        padding: 6px 10px;
        background: #FFFFFF;
        border-radius: 40px;
        width: 95px;
    }
    
    .nav_resources p {
        color: #2E3B4B;
        font-weight: 600;
        font-size: 16px;
    }
    
    .nav_resources img {
        width: 20px;
        height: 20px;
    }
            

    .doughnut_average {
        position: relative;
    }
    
    .doughnut_average canvas {
        position: absolute;
    }
    
    .doughnut_average__text {
        position: absolute;
        left: 49%;
        bottom: 22%;
        transform: translate(-50%, 0);
        font-size: 1.3vw; 
        font-size: 15px; 
        font-weight: bold;
        margin: 0;
        color: black;
    }
    
    
    
    .menu-custom-title {
        font-weight: 500;
        font-size: 21px;
        color: #2E3B4B;
        opacity: .5;
    }
    
    .menu-custom-icon {
        color: #2E3B4B;
        opacity: .5;
    }
    
    .router-link-active .menu-custom-title,
    .router-link-active .menu-custom-icon{
        opacity: 1;
    }
</style>