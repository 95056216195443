import axios from 'axios';
import api_idb from '@/api/idb'

        const state = {

        };


const getters = {

};


const mutations = {

};

const actions = {

    //ОТРАБОТАЕТ АСИНХРОННО   - можно запустить в паралель подгрузку многих таблиц, ну и что, что будет несколько запросов.
    async vuex_set__a_data__back( { commit }, conf) {

        let table_name = conf['table_name'];
        let table_module = conf['table_module'];



        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element = {}
        element['name'] = table_name;
        element['is_blocked'] = true;
        commit('setvx__flag_blocked_action', element, {root: true});





        let params = conf['params']



        //проверим данные в api_idb    //IndexedDB=IDB 
        let table = {};  //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        //table = await api_idb.loadTable(table_name) //НЕ ГРУЗИТСЯ из STORE
        table['table_module'] = table_module;
        table['is_loaded_tables'] = {};
        table['is_loaded_tables']['load_status'] = false;
        table['is_loaded_tables']['error_send'] = false;
        table['is_loaded_tables']['error_api'] = false;
        table['is_loaded_tables']['error_idb_load'] = false;
        table['is_loaded_tables']['error_idb_save'] = false;


        //блокировка флага в списке флогов в разрезе страницы
        //cdata['flags']['is_action'][login_flag_name] = true;      

        let response_data = {};

        console.log(table_name + " RUN: LOAD_FROM_BACK");
        console.log(params);

        await axios.post(conf['server'], params)
                .then(response =>
                {
                      console.log(table_name + " LOAD_FROM_BACK"+"answ-code:"+response['data']['answer_code']);
                    switch (response['data']['answer_code'])
                    {
                        case "READY":

                          
                            response_data = response['data']['data'];
                            console.log(response['data']);
                            if (response['data']['success'])
                            {
                                table['is_loaded_tables']['load_status'] = true;
                            }
                            break;



                        default:
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                            //cdata['flags']['is_error']['api'] = true;
                            console.log(response['data']);
                            table['is_loaded_tables']['error_api'] = true;

                            break;
                    }
                    //разрешаем кликать на кнопку для дейтсвия 
                    //cdata['flags']['is_action'][login_flag_name] = false;

                })
                .catch(error => {

                    console.log(table_name + " ERROR: LOAD_FROM_BACK");
                    console.log(error);
                    //Флаг ошибки отправки данных
                    //cdata['flags']['is_error']['sending'] = true;
                    table['is_loaded_tables']['error_send'] = true;

                    //cdata['flags']['is_action'][login_flag_name] = false;

                }).then(function () { });

        //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
        //console.log("load_status="+table['is_loaded_tables']['load_status']);

        table['table_name'] = table_name;

        if (table['is_loaded_tables']['load_status'])
        {
            //let table_data=response_data;

            table['table_data'] = response_data['table_data'][table_name];


            //console.log(table);

            try {
                await api_idb.saveTable(table)

                console.log(table_name + ", SAVE_TO_STORE");
                commit('setvx__a_data', table, {root: true})

            } catch (error) {
                table['is_loaded_tables']['error_idb_save'] = true;
                console.log("Ошибка при сохранении данных в store");
                console.error(error);
            }

        }




        // console.log(table);
        commit('setvx__flag_a_data', table, {root: true})


        //работа с таблицей завершена
        element['is_blocked'] = false;
        commit('setvx__flag_blocked_action', element, {root: true});



        // console.log("step_orger+++fin_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));

    },

    //ОТРАБОТАЕТ АСИНХРОННО   - можно запустить в паралель подгрузку многих таблиц, ну и что, что будет несколько запросов.
    async vuex_set__a_data__ls_or_back( { commit }, conf) {

        let table_name = conf['table_name'];
        let table_module = conf['table_module'];

        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element = {}
        element['name'] = table_name;
        element['is_blocked'] = true;
        commit('setvx__flag_blocked_action', element, {root: true});





        let params = conf['params']



        //проверим данные в api_idb    //IndexedDB=IDB 
        let table = {};  //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        table = await api_idb.loadTable(table_name)
        table['table_module'] = table_module;
        table['is_loaded_tables'] = {};
        table['is_loaded_tables']['load_status'] = false;
        table['is_loaded_tables']['error_send'] = false;
        table['is_loaded_tables']['error_api'] = false;
        //    table['is_loaded_tables']['error_idb_load']=false; устанавливается во время загрузки таблицы из Хранилища
        table['is_loaded_tables']['error_idb_save'] = false;

        //если данных в IDB нету ...
        if (!table.hasOwnProperty('table_name'))
        {

            //блокировка флага в списке флогов в разрезе страницы
            //cdata['flags']['is_action'][login_flag_name] = true;      

            let response_data = {};

            await axios.post(conf['server'], params)
                    .then(response =>
                    {

                        console.log(table_name + " BACK HERE");
                        console.log(response['data']);
                        switch (response['data']['answer_code'])
                        {
                            case "READY":

                                console.log(table_name + " LOAD_FROM_BACK");
                                response_data = response['data']['data'];
                                //  console.log("response['data']['success']="+response['data']['success']);
                                if (response['data']['success'])
                                {
                                    table['is_loaded_tables']['load_status'] = true;
                                }
                                break;



                            default:
                                //какая-то ошибка в API - не вернул обрабатываемый ответ
                                //cdata['flags']['is_error']['api'] = true;
                                table['is_loaded_tables']['error_api'] = true;

                                break;
                        }
                        //разрешаем кликать на кнопку для дейтсвия 
                        //cdata['flags']['is_action'][login_flag_name] = false;

                    })
                    .catch(error => {
                        console.log(error);
                        //Флаг ошибки отправки данных
                        //cdata['flags']['is_error']['sending'] = true;
                        table['is_loaded_tables']['error_send'] = true;

                        //cdata['flags']['is_action'][login_flag_name] = false;

                    }).then(function () { });

            //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
            console.log("load_status=" + table['is_loaded_tables']['load_status']);

            table['table_name'] = table_name;

            if (table['is_loaded_tables']['load_status'])
            {
                //let table_data=response_data;

                table['table_data'] = response_data['table_data'][table_name];


                //console.log(table);

                try {
                    await api_idb.saveTable(table)

                    console.log(table_name + ", SAVE_TO_STORE");
                    commit('setvx__a_data', table, {root: true})

                } catch (error) {
                    table['is_loaded_tables']['error_idb_save'] = true;
                    console.log("Ошибка при сохранении данных в store");
                    console.error(error);
                }

            }


        } else
        {
            //console.log("WAIT 1 sec"); 
            await new Promise(res => {
                setTimeout(res, 1000);
            });
            //console.log("WAIT 1 sec - FIN");

            table['is_loaded_tables']['load_status'] = true;

            //если данные в api_db есть, подтянем
            console.log(table_name + ", LOAD_FROM_STORE");
            //console.log(table);
            //this.state.is_loaded_tables[table_name]=true;
            commit('setvx__a_data', table, {root: true})

        }

        // console.log(table);
        commit('setvx__flag_a_data', table, {root: true})


        //работа с таблицей завершена
        element['is_blocked'] = false;
        commit('setvx__flag_blocked_action', element, {root: true});

        // console.log("step_orger+++fin_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));
    },

    async vuex_update__multi_a_data__ls_or_back( { commit, rootState }, conf) {


        /*   let lock={}
         lock['method']=conf['params']['method'];
         lock['value']='locked';  
         commit('setvx__lock_action', lock, { root: true }); */



        let table_names = conf['table_names']
        //let table_modules=conf['table_modules'];

        /* 
         table_names={ 
         "r_auth":{module:"auth"}, 
         "a_test":{module:"test"}, 
         "a_test2":{module:"test"}  
         };
         */


        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме

        for (const [key, value] of Object.entries(table_names)) {
            let element = {}
            element['name'] = key;
            element['is_blocked'] = true;
            commit('setvx__flag_blocked_action', element, {root: true});
        }





        let params = conf['params']
        let table = {}; //table_name добавляется само при считывании из store или вручную добавляем при считывании back
        let table_data_from_back = {};

        for (const [key, value] of Object.entries(table_names)) {
            table_data_from_back[key] = {};
            table[key] = {};
            table[key]['table_module'] = value['module'];
            table[key]['table_updatetype'] = value['updatetype'];
            table[key]['table_keyfield'] = value['keyfield'];
            table[key]['is_loaded_tables'] = {};
            table[key]['is_loaded_tables']['load_status'] = true;//даже если при дабавлении в таблицу произошел сбой, таблица по преждему остается загруженной
            table[key]['is_loaded_tables']['error_send'] = false;
            table[key]['is_loaded_tables']['error_api'] = false;
            //table['is_loaded_tables']['error_idb_load']=false;
            table[key]['is_loaded_tables']['error_idb_save'] = false;
        }

        var time = performance.now();
        //  console.log("step_orger@@@1|0");
        let response_answer_code = {};
        let response_data = {};






        await axios.post(conf['server'], params)
                .then(response =>
                {
                    console.log(response['data']);
                    response_answer_code = response['data']['answer_code'];
                    switch (response['data']['answer_code'])
                    {
                        case "READY":
                            //  console.log("step_orger@@@2|"+(performance.now() - time));
                            response_data = response['data']['data'];
                            console.log("MULTIDATA FROM BACK");
                            // console.log(response['data']['data']); 

                            //ОБНОВИМ изменения в хранилище IDB - STORE

                            //OLD
//                            for (const [key, value] of Object.entries(table_names)) {
//                                table_data_from_back[key]['table_data'] = response_data['table_data'][key];
//                                table[key]['mysql_v'] = response_data['mysql_v'];
//                            }
                            //OLD
                            
                            for (const [key, value] of Object.entries(table_names)) {
                                table_data_from_back[key]['table_data'] = response_data['table_data'][key]; 
                            }
                            //обновляем системные данные 
                            table['mysql_v'] = response_data['mysql_v']; 
//                            table['server_prefix'] = response_data['server_prefix'];
                            table['answer'] = response_data['answer'];
//============================================================================


                            break;


                        case "MYSQL_VERSION_MISS_MATCH":
                            window.location.reload();
                            console.log(response['data']['answer_code']);


                            break;

//                                         case "INCORRECT_CODE":
//                                         rldata['flagserror_action']['is_error_incorrect_code']=true;
//                                         console.log("INCORRECT_CODE");
//                                         table['is_loaded_tables']['load_status']=true;
//                                         break;

                        default:
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                            for (const [key, value] of Object.entries(table_names)) {
                                table[key]['is_loaded_tables']['error_api'] = true;
                                table[key]['is_loaded_tables']['load_status'] = true;
                            }
                            break;
                    }
                    //разрешаем кликать на кнопку для дейтсвия 
                    //cdata['flags']['is_action']['ok_disabled'] = false;


                })
                .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    for (const [key, value] of Object.entries(table_names)) {
                        table[key]['is_loaded_tables']['error_send'] = true;
                        table[key]['is_loaded_tables']['load_status'] = true;
                    }
                    //cdata['flags']['is_action']['ok_disabled'] = false;

                }).then(function () { });







        //  let delta13 = performance.now() - time;
        //    console.log("@@@ UNDER AXIOS---");
        //  console.log("step_orger@@@3|"+delta13); 


        //AWAIT  если загрузились данные из бэка, то мохраним в STORE и совершим мутацию    
        //console.log("load_status="+table['is_loaded_tables']['load_status']);


        for (const [key, value] of Object.entries(table_names)) {

            table[key]['table_name'] = key;
            if (table[key]['is_loaded_tables']['load_status'] && response_answer_code === "READY")
            {



                //console.log(table);
                try {
                    let table_data_for_idb = {};
                    let table_data_full = {};
                    console.log("table_updatetype");
                    //console.log(table[key]);
                    console.log(table[key]['table_updatetype']);

                    switch (table[key]['table_updatetype'])
                    {
                        case "owerwrite":
                            //просто перезапишем таблицу
                            //? table[key]['table_data'] = response_data['table_data'][key];
                            table[key]['table_data'] = table_data_from_back[key]['table_data'];
                            //console.log(table[key]['table_data']);
                            await api_idb.saveTable(table[key]);

                            break;

                        case "insertrows":
                            //добавим новые записи (без сортировки, вконец)

                            table_data_for_idb = await api_idb.loadTable(table[key]['table_name']);
                            console.log("Загружено из IDB");
                            console.log(table_data_for_idb);


                            if (table_data_for_idb.hasOwnProperty('table_name'))
                            {
                                console.log("таблица - есть! " + table[key]['table_name'] + " - добавка новых данных");

                                //проходит записи с бэка, которые добавляем!
                                for (const [tkey, value_data_from_back] of Object.entries(table_data_from_back[key]['table_data'])) {
                                    //пушим содержимое с бэка table_data_for_idb в  
                                    let table_data_for_idb__table_data__length = Object.keys(table_data_for_idb['table_data']).length;
                                    console.log("table_data_for_idb__table_data__length=" + table_data_for_idb__table_data__length);
                                    table_data_for_idb['table_data'][table_data_for_idb__table_data__length] = value_data_from_back;

                                }


                                table[key]['table_data'] = JSON.parse(JSON.stringify(table_data_for_idb['table_data']));
                                console.log(table[key]['table_data']);
                            } else {
                                //нет таблицы - надо создать!
                                //??скорее всего невозможная ситуация, т.е. все таблицы должны быть первисно загружены перед изменениями...
                                table[key]['table_data'] = table_data_from_back[key]['table_data'];
                                await api_idb.saveTable(table[key]);
                                console.log("таблица - нет! " + table[key]['table_name'] + " - надо создать и закинуть туда новые даные!");
                            }


                            await api_idb.saveTable(table[key]);

                            break;

                        case "updaterows":

                            table_data_for_idb = await api_idb.loadTable(table[key]['table_name']);
                            console.log("Загружено из IDB");
                            console.log(table_data_for_idb);

                            console.log("updaterows...");
                            if (table_data_for_idb.hasOwnProperty('table_name'))
                            {
                                console.log("таблица - есть! " + table[key]['table_name'] + " - Обновноние данных в имеющихся данных, если нет - то добавка");
                                console.log('lsoinaoia');
                                console.log(Object.entries(table_data_from_back[key]['table_data']));
                                console.log('lsoinaoia');
                                
                                //проходит записи с бэка, которые добавляем!
                                    for (const [tkey, value_data_from_back] of Object.entries(table_data_from_back[key]['table_data'])) {

                                        //ищем в данных из idb по ключу
                                        let is_find_row_by_key = false;
                                        for (const [idb_key, idb_row] of Object.entries(table_data_for_idb['table_data'])) {
                                            if (value_data_from_back[table[key]['table_keyfield']] === idb_row[table[key]['table_keyfield']])
                                            {
                                                console.log('jbjkbkbkbk');
                                                console.log(table[key]['table_keyfield']);
                                                console.log('jbjkbkbkbk');
                                                //обновим данные
//                                                console.log("YES find " + value_data_from_back[table[key]['table_keyfield']].toString());
                                                for (const [field_name, field_value] of Object.entries(idb_row)) {
                                                    console.log(value_data_from_back[field_name]);
                                                    console.log(idb_row[field_name]);
                                                    idb_row[field_name] = value_data_from_back[field_name];
                                                }
                                                is_find_row_by_key = true;
                                                break;
                                            }
                                        }


                                        if (!is_find_row_by_key)
                                        {
                                            //пушим содержимое с бэка table_data_for_idb в  
                                            let table_data_for_idb__table_data__length = Object.keys(table_data_for_idb['table_data']).length;
                                            console.log("table_data_for_idb__table_data__length=" + table_data_for_idb__table_data__length);
                                            table_data_for_idb['table_data'][table_data_for_idb__table_data__length] = value_data_from_back;

                                        }

                                    }
                                    table[key]['table_data'] = JSON.parse(JSON.stringify(table_data_for_idb['table_data']));


                                
                                
                            } else {
                                //нет таблицы - надо создать!
                                //??скорее всего невозможная ситуация, т.е. все таблицы должны быть первисно загружены перед изменениями...
                                table[key]['table_data'] = table_data_from_back[key]['table_data'];
                                await api_idb.saveTable(table[key]);
                                console.log("таблица - нет! " + table[key]['table_name'] + " - надо создать и закинуть туда новые даные!");
                            }


                            await api_idb.saveTable(table[key]);

                            break;





                        case "deleterows":
                            //тоже что и update, только что delete вместо update

                            table_data_for_idb = await api_idb.loadTable(table[key]['table_name']);
                            console.log("Загружено из IDB");
                            console.log(table_data_for_idb);

                            console.log("deleterows...");
                            if (table_data_for_idb.hasOwnProperty('table_name'))
                            {
                                console.log("таблица - есть! " + table[key]['table_name'] + " - Удаление данных по строчкам");

                                //проходит записи с бэка, которые удаляем!
                                for (const [tkey, value_data_from_back] of Object.entries(table_data_from_back[key]['table_data'])) {

                                    //ищем в данных из idb по ключу
                                    let num_for_del = null;
                                    for (const [idb_key, idb_row] of Object.entries(table_data_for_idb['table_data'])) {
                                        //console.log(table[key]['table_keyfield']);
                                        //console.log(value_data_from_back);  //[table[key]['table_keyfield']]
                                        //console.log(idb_row);//[table[key]['table_keyfield']]
                                        if (value_data_from_back[table[key]['table_keyfield']] === idb_row[table[key]['table_keyfield']])
                                        {
                                            //обновим данные
                                            console.log("YES find " + value_data_from_back[table[key]['table_keyfield']].toString());
                                            num_for_del = idb_key;
                                            break;
                                        }
                                    }


                                    if (num_for_del !== null)
                                    {
                                        //удаляем содержимое с бэка в table_data_for_idb 
                                        console.log(table_data_for_idb['table_data']);
                                        console.log("num_for_del=" + num_for_del);
                                        table_data_for_idb['table_data'].splice(num_for_del, 1);


                                    }

                                }


                                table[key]['table_data'] = JSON.parse(JSON.stringify(table_data_for_idb['table_data']));
                                console.log(table[key]['table_data']);

                                await api_idb.saveTable(table[key]);
                            } else {
                                //нет таблицы - надо создать!
                                //??скорее всего невозможная ситуация, т.е. все таблицы должны быть первисно загружены перед изменениями...
//                                table[key]['table_data']=table_data_from_back[key]['table_data'];  
//                                await api_idb.saveTable(table[key]);
                                console.log("таблицы из которой происходит удаление - нету в store! " + table[key]['table_name'] + " - ");
                                table[key]['is_loaded_tables']['error_idb_save'] = true;

                            }






                            break;


                    }


                    //    console.log(key+", START_TO IDB");



                    //      console.log(key+", SAVE_TO_STORE!!! OKEY");
                    //      console.log(table[key]);
                    commit('updatevx__a_data', table[key], {root: true})


                } catch (error) {
                    table[key]['is_loaded_tables']['error_idb_save'] = true;
                    console.log("Ошибка при сохранении данных в store");
                    console.error(error);
                }

            }

        }


        commit('updatevx__a_data_sys', table, {root: true});

        //изменяем флажки 
        for (const [key, value] of Object.entries(table_names)) {

            commit('setvx__flag_a_data', table[key], {root: true})

        }

        //работа с таблицей завершена 
        for (const [key, value] of Object.entries(table_names)) {

            let element = {}
            element['name'] = key;
            element['is_blocked'] = false;
            commit('setvx__flag_blocked_action', element, {root: true});

    }




    /*  lock['method']=conf['params']['method'];
     lock['value']='unlocked';  
     commit('setvx__lock_action', lock, { root: true }); */



    //     console.log("step_orger+++fin VUEX AXIOS_"+conf['table_name']+"|"+(performance.now() - conf['microtime']));

    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}