import Vue from 'vue'
import Router from 'vue-router'

 
import layoutchild from '../layoutchild/layoutchild.vue'
import layoutsys from '../layoutsys/layoutsys.vue'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '',
  routes: [
      
      
      
      
      
      
      
      
    {
      path: '/',
       component: { render (c) { return c('router-view') }  },  
      children: [
        {
          path: '',
          name: 'landing_page',  
          component: () => import('@/upages/landing/main/landing_page')  
        },
        {
          path: 'login',
          name: 'login',  
          component: () => import('@/upages/landing/main/login')  
        },
      ]
    },
 
    
    
    
     
      
       
    
    
     
      
    {
        path: '/admin',
        component: layoutsys,
        children: [
            {
              path: 'users', 
              name: 'users',
              component: () => import('@/upages/games/admin/users')
            },
        ]
    },    
          
  
    
    
    {
        path: '/book',
        component: layoutchild,
        children: [
            {
              path: 'boutchoose', 
              name: 'boutchoose',
              component: () => import('@/upages/games/book/boutchoose')
            },
            
            {
              path: 'boutpart', 
              name: 'boutpart',
              component: () => import('@/upages/games/book/boutpart')
            },
            
            {
              path: 'boutstory', 
              name: 'boutstory',
              component: () => import('@/upages/games/book/boutstory')
            },
        ]
    },
    
    
    
     
    {
      path: '/g1',
      component: layoutchild,
      children: [ 
          
        {
          path: 'setup', 
          name: 'child_setup',
          component: () => import('@/upages/landing/main/child_setup')
        },

        {
          path: 'first', 
          name: 'first',
          component: () => import('@/upages/games/g1/first')
        },
         {
          path: 'template1', 
          name: 'template1',
          component: () => import('@/upages/games/g1/template1')
        },
        
        {
          path: 'template2', 
          name: 'template2',
          component: () => import('@/upages/games/g1/template2')
        },
        
        {
          path: 'desksweek', 
          name: 'desksweek',
          component: () => import('@/upages/games/g1/desksweek')
        },
        {
          path: 'gameover', 
          name: 'gameover',
          component: () => import('@/upages/games/g1/gameover')
        }, 
        /*{
          path: 'shift', 
          name: 'shift',
          component: () => import('@/upages/games/g1/shift')
        }, */
        {
          path: 'empty', 
          name: 'empty',
          component: () => import('@/upages/games/g1/empty')
        }, 
        {
          path: 'empty2', 
          name: 'empty2',
          component: () => import('@/upages/games/g1/empty2')
        },
        {
          path: 'cardweek', 
          name: 'cardweek',
          component: () => import('@/upages/games/g1/cardweek')
        },
        {
          path: 'inventory', 
          name: 'inventory',
          component: () => import('@/upages/games/g1/inventory')
        }, 
        {
          path: 'professions', 
          name: 'professions',
          component: () => import('@/upages/games/g1/professions')
        }, 
        
        {
          path: 'story', 
          name: 'story',
          component: () => import('@/upages/games/g1/story')
        }, 
      ]
    },
    
    
    
    
        
     
    {
      path: '/g1',
      component: layoutsys,
      children: [ 
       
        {
          path: 'shift', 
          name: 'shift',
          component: () => import('@/upages/games/g1/shift')
        }, 
         {
          path: 'reset', 
          name: 'reset',
          component: () => import('@/upages/games/g1/reset')
        }, 
     
      ]
    },
    
    
    
    
    
    /*
    {
      path: '/g1',
      component: { render (c) { return c('router-view') }      },  
      children: [ 
         {
          path: 'load',
          name: 'load',  
          component: () => import('@/upages/games/g1/load')  
        } 
      ]
    },
    
    */
    
      
      
     
      
      
      
      
    
    
    {
      path: '/error',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error_auth',
          name: 'error_auth',  // 
          component: () => import('@/upages/landing/main/error_auth')  
       }
      ]
    },
    
    
    
    
    
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})
