
//https://lodash.com/docs/4.17.15#countBy
    
    //lodash lib
    
    // _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
    // var users = [  { 'user': 'fred',   'age': 48 },   { 'user': 'barney', 'age': 34 },
    
    //shuffle([1, 2, 3, 4]);
    
    //_.map(['6', '8', '10'], _.ary(parseInt, 1));
    //=> [6, 8, 10]
    
    //_.uniq([2, 1, 2]);
    // => [2, 1]
    
    //_.without([2, 1, 2, 3], 1, 2);
    // => [3]
    
    //var users = [
//  { 'user': 'barney', 'age': 36, 'active': true },
//  { 'user': 'fred',   'age': 40, 'active': false }
//];
// 
//_.filter(users, function(o) { return !o.active; });
// => objects for ['fred']

//var users = {
//  'fred':    { 'user': 'fred',    'age': 40 },
//  'pebbles': { 'user': 'pebbles', 'age': 1 }
//};
// 
//_.mapValues(users, function(o) { return o.age; });
// => { 'fred': 40, 'pebbles': 1 } (iteration order is not guaranteed)




import axios from 'axios'; 
import api_idb from '@/api/idb' 
import _ from 'lodash';

 const state = { 
    };
    
const getters = { 
    
/*
    getParams: (state, getters, rootState) => {
        
        console.log("rootState");
        console.log(rootState);
        console.log(state);
        return  rootState.state_tables.state_stress.a_stress.length ? _.minBy(rootState.state_tables.state_stress.a_stress, function(o) { return parseInt(o.info); })  : "";
    
    
    },*/
    
  /* minStress: (state) => { //state  c
        } */
    
}; 
 
 
 
 
 
const mutations = {
   /* SET_A_STRESS (state, table) {
       // state.state_stress.a_stress=table;
        state.state_stress.a_stress = table['table_data'];  
    //  state.state_stress[table['table_name']] = table['table_data'];  
      
    },*/
    
     
       
    
    
};
 
const actions = {
    
     //state, commit, dispatch, rootState, rootGetters 
    
    
   async vuex_update__a_stress__back ({ commit, rootState }, cdata) {
       
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']="a_stress";
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        let table={}; 
        table['is_loaded_tables']={};
        table['is_loaded_tables']['load_status']=true;//даже если при дабавлении в таблицу произошел сбой, таблица по преждему остается загруженной
        table['is_loaded_tables']['error_send']=false;
        table['is_loaded_tables']['error_api']=false;
        //table['is_loaded_tables']['error_idb_load']=false;
        //table['is_loaded_tables']['error_idb_save']=false;
        
        
        
        let a_stress_new_info="eeeeee";
        
         var params = {
                        method: "add_new_info_in_a_stress",
                        data: {
                            info: a_stress_new_info,
                            r_auth: rootState.state_tables.state_auth.r_auth,
                        }
                    };
          
         // console.log(rootState.state_tables.state_auth.r_auth['rid']);
         // console.log(rootState);
          
          table['table_name']='a_stress';
      
           
            let response_data={}; 
           await axios.post(cdata['cnf']['server_action'], params)
                            .then(response =>
                            {
                                console.log(response['data']); 
                                switch (response['data']['answer_code'])
                                {
                                    case "READY":  
                                        
                                        response_data=response['data']['data'];
                                        
                                        
                                        //ОБНОВИМ изменения в хранилище IDB - STORE
                                        table['table_data']=response_data['table_data']['a_stress']; 
                                        table['mysql_v']=response_data['mysql_v'];
                                        //console.log("table stress");
                                        //console.log(table); 
                                        //изменяем таблицу
                                        commit('updatevx__a_stress', table, { root: true });
                                        
                                        break;

//                                         case "INCORRECT_CODE":
//                                         rldata['flagserror_action']['is_error_incorrect_code']=true;
//                                         console.log("INCORRECT_CODE");
//                                         table['is_loaded_tables']['load_status']=true;
//                                         break;

                                    default:
                                        //какая-то ошибка в API - не вернул обрабатываемый ответ
                                        table['is_loaded_tables']['error_api']=true;
                                        table['is_loaded_tables']['load_status']=true;
                                        break;
                                }
                                //разрешаем кликать на кнопку для дейтсвия 
                                //cdata['flags']['is_action']['ok_disabled'] = false;
                                

                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                table['is_loaded_tables']['error_send']=true; 
                                table['is_loaded_tables']['load_status']=true;
                                //cdata['flags']['is_action']['ok_disabled'] = false;

                            }).then(function () {                              
                            });          
                    
     
     
       //изменяем флажки 
       commit('setvx__flag_a_data', table, { root: true })
       
       //работа с таблицей завершена
       element['is_blocked']=false;
       commit('setvx__flag_blocked_action', element, { root: true });
    
      
      
            
       
       
       
       
   },
    
    
    
    
   async vuex_set__a_stress__back ({ commit },cdata) {
        
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']="a_stress";
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        let table={}; 
        table['is_loaded_tables']={};
        table['is_loaded_tables']['load_status']=false;
        table['is_loaded_tables']['error_send']=false;
        table['is_loaded_tables']['error_api']=false;
        //table['is_loaded_tables']['error_idb_load']=false;
        //table['is_loaded_tables']['error_idb_save']=false;
        
        
        
        let a_stress_limit=4;
        
         var params = {
                        method: "get_a_stress",
                        data: {
                            limit:a_stress_limit
                        }
                    };
                    //this.$root.cnf['server_action']
                    //
            
           //cdata['flags']['is_error']['sending'] = false;   
           //cdata['flags']['is_error']['api'] = false;
            
           
           //cdata['flags']['is_action']['ok_disabled'] = true;           
             
           
            let response_data={}; 
           await axios.post(cdata['cnf']['server_action'], params)
                            .then(response =>
                            {
                                console.log(response['data']); 
                                switch (response['data']['answer_code'])
                                {
                                    case "READY":  
                                        
                                        response_data=response['data']['data']['a_stress'];
                                        table['is_loaded_tables']['load_status']=true;
                                        
                                        break;

//                                         case "INCORRECT_CODE":
//                                         rldata['flagserror_action']['is_error_incorrect_code']=true;
//                                         console.log("INCORRECT_CODE");
//                                         break;

                                    default:
                                        //какая-то ошибка в API - не вернул обрабатываемый ответ
                                        table['is_loaded_tables']['error_api']=true;
                                        break;
                                }
                                //разрешаем кликать на кнопку для дейтсвия 
                                //cdata['flags']['is_action']['ok_disabled'] = false;
                                

                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                table['is_loaded_tables']['error_send']=true; 
                                //cdata['flags']['is_action']['ok_disabled'] = false;

                            }).then(function () { });          
                    
     table['table_name']='a_stress';   
     if (table['is_loaded_tables']['load_status'])
     {
         
         table['table_data']=response_data;
      
        console.log("table stress");
        console.log(table);
       
        //изменяем таблицу
        commit('setvx__a_stress', table, { root: true });
       
        
   }
       //изменяем флажки
       commit('setvx__flag_a_data', table, { root: true })
       
       //работа с таблицей завершена
       element['is_blocked']=false;
       commit('setvx__flag_blocked_action', element, { root: true });
    
      
      
            
            
            
    }
};
 
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}