
<template>
<!-- <section class="app"> -->
  <div class="sidebar">
    <div class="logo-details">
      <!-- <i class='mdi mdi-crosshairs-question icon'></i>
        <div class="logo_name">EO</div> -->
        <i class='mdi mdi-menu d-none d-lg-block' id="btn" ></i>
    </div>
    <ul class="nav-list">
      <!-- <li class="d-none d-sm-block">
          <i class='mdi mdi-magnify' ></i>
         <input type="text" placeholder="Поиск...">
         <span class="tooltip">Поиск</span>
      </li> -->

      <li>
          <router-link :to="'/g1/desksweek/'+'?t='+$root._SYS().add_v()">
            <i class="mdi mdi-video-input-component"></i>
            <span class="links_name">Dashboard</span>
          </router-link>
         <span class="tooltip">Dashboard</span>
      </li>

      <li>
          <router-link :to="'/g1/professions/'+'?t='+$root._SYS().add_v()">
            <i class="mdi mdi-finance"></i>
            <span class="links_name">Professions</span>
          </router-link>
         <span class="tooltip">Professions</span>
      </li>

      <li>
          <router-link :to="'/g1/inventory/'+'?t='+$root._SYS().add_v()">
            <i class="mdi mdi-store-outline"></i>
            <span class="links_name">Inventory</span>
          </router-link>
         <span class="tooltip">Inventory</span>
      </li>

      <li>
          <router-link :to="'/book/boutchoose/'+'?t='+$root._SYS().add_v()">
            <i class="mdi mdi-book-open-page-variant"></i>
            <span class="links_name">Story</span>
          </router-link>
         <span class="tooltip">Story</span>
      </li>
      
<!--      <li style="cursor: pointer;">
          <a @click.prevent="show_info_modal">
            <i class="mdi mdi-book-open-page-variant"></i>
            <span class="links_name">Story</span>
          </a>
         <span class="tooltip">Story</span>
      </li>-->



     <li v-if="rldata.show_admin_controls" 
         @click="show_admin_controls"
         class="profile d-flex justify-content-center align-items-center"  style="cursor: pointer;">
            <p class="m-0">ADMIN</p>
            <i class='mdi mdi-security' id="log_out"></i>
     </li>

<!--v-on:click="$root.logout()"-->
<!--     <li class="profile d-flex justify-content-center align-items-center"  style="cursor: pointer;">
            <p class="m-0">Log out</p>
            <i class='mdi mdi-exit-to-app' id="log_out"></i>
     </li>-->
    </ul>
  </div>
<!-- </section> -->
</template>

<script>
  var data = {
    user: {
      full_name: '',
      email: '',
      password: '',
    },
    
    show_admin_controls: false,
  };
 
  export default {
    name: "app-sidebar",
    data () {
    return {
      rldata: data
    }
  },
    methods: {
      show_info_modal : function() {
          this.$emit("callMethodInParent");
//          localStorage.removeItem('intro_modal');
      },
      
      show_admin_controls: function() {
            this.$emit("toggle_admin_controls", true);
      }
    },
    created () {
    },
    mounted(){     
        let sidebar = document.querySelector(".sidebar");
        let closeBtn = document.querySelector("#btn");
        let searchBtn = document.querySelector(".mdi-magnify"); 

        let logo_name = document.querySelector('.logo_name');

        closeBtn.addEventListener("click", ()=>{
            sidebar.classList.toggle("open");
            menuBtnChange();
        });

        function menuBtnChange() {
            if(sidebar.classList.contains("open")){
                closeBtn.classList.replace("mdi-menu", "mdi-backburger");
//                logo_name.show();
            }else {
                closeBtn.classList.replace("mdi-backburger","mdi-menu");
//                logo_name.hide();
            }
        }
        
        
//        ADMIN CONTROLS
//&admin_controls=show

        var url = new URL(window.location.href);
        let show_admin_controls__param = url.searchParams.get("admin_controls");
        
        let show_admin_controls__local = this.$root._ADMIN().is_admin_active();
        if(show_admin_controls__local && show_admin_controls__param != 'hide'){
            this.rldata.show_admin_controls = true;
        } else if(show_admin_controls__param == 'show') {
            this.rldata.show_admin_controls = true;
            this.$root._ADMIN().show_admin_controls();
        } else if(show_admin_controls__param == 'hide') {
            this.rldata.show_admin_controls = false;
            this.$root._ADMIN().hide_admin_controls();
            window.location.reload();
        }
//        END ADMIN CONTROLS
        
    }
  }
</script>


