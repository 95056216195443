import axios from 'axios';
import api_idb from '@/api/idb' 
 
const state = {
  
};
  
 const getters = { }; 
  
const mutations = {
     
    
    
};
  
const actions = {
    
     
     
     
     
     
     
 
     
     
     
 
     
  async  vuex_r_auth({commit}, cdata) {
        
        //выставляем флаг, что идет работа с таблицей, чтобы можно было подцепить блокировку кнопок на форме
        let element={}
        element['name']="r_auth";
        element['is_blocked']=true; 
        commit('setvx__flag_blocked_action', element, { root: true });
        
        
        
        let table={}; 
        table['is_loaded_tables']={};
        table['is_loaded_tables']['load_status']=false;
        //table['is_loaded_tables']['error_send']=false;
        //table['is_loaded_tables']['error_api']=false;
        table['is_loaded_tables']['error_idb_load']=false;
        table['is_loaded_tables']['error_idb_save']=false;
        
        let r_auth={};
        try{
            
             let cr_auth=localStorage.getItem("r_auth");
             r_auth=window._CRYPT().decrypt_json(cr_auth); 
             table['is_loaded_tables']['load_status']=true;
            
         } catch (e) { 
                console.log(e);
                table['is_loaded_tables']['error_idb_load']=true;
         }
        
         
         if (table['is_loaded_tables']['load_status'])
         {
            table['table_name']='r_auth';
            table['table_data']=r_auth;

            console.log(r_auth); 

             
             console.log("WAIT AUTH 4 sec"); 
             await new Promise(res => { setTimeout(res, 4000); });
             console.log("WAIT AUTH 4 FIN sec"); 

            //изменяем таблицу
            commit('setvx__r_auth', table , { root: true })
         }
         
        //изменяем флажки
       // commit('setvx__flag_a_data', table, { root: true })
    
        //работа с таблицей завершена
        element['is_blocked']=false; 
        commit('setvx__flag_blocked_action', element, { root: true });
    
        
    },
 
    
     
    
};
 
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}