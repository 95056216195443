<template>
    <section class="main-view">
        <div class="container-scroller">
           

            <div class="container-fluid page-body-wrapper">
                <div class="main-panel">
                    <div class="content-wrapper">
                        <router-view></router-view>
                    </div> <!-- content wrapper ends -->
                   
                </div> <!-- main panel ends -->
            </div> <!-- page-body-wrapper ends -->
        </div>
    </section>
</template>

<script>
   
    import Vue from 'vue'
    import { mapGetters } from 'vuex'

            var cdata = {//Р•СЃР»Рё РЅРµ СѓРєР°Р·Р°С‚СЊ - РЅРµ РІСЃРєР»СЋС‡РёС‚СЊСЃСЏ СЂРµР°РєС‚РёРІРЅРѕСЃС‚СЊ 

                system: {
                    is_mounted_execute:false,
                    cnf: {},
               
 

                },
            
                business_logic: {
                
                },
            }



    export default {
        name: "layoutsys",
        components: {
         
        },
        data() {
            return {
                cdata: cdata,
            };
        },
        mounted() {
 
            /* COPYPASTE */
            if (!this.$root._AUTH().is_authed()) {
                return;
            }//РїСЂРѕРІРµСЂРёС‚СЊ, Р°РІС‚РѕСЂРёР·РѕРІР°РЅ Р»Рё РёР»Рё СЂР°Р·Р»РѕРіРёРЅ 
          
            this.cdata['system']['is_mounted_execute'] = false;
         
            this.cdata['system']['cnf'] = this.$root.cnf;
            //СѓСЃС‚Р°РЅР°РІР»РёРІР°РµРј РЅР° СЃС‚СЂР°РЅРёС†Рµ 
          
 
 
 
 
 
 
 
 
 
       
 
            /* #OPYPASTE  */
            /*setTimeout(() => {
             this.cdata.system.is_mounted_execute = true;
             }, 100);*/
            this.cdata.system.is_mounted_execute = true;
            /* COPYPASTE */


        },
        computed: {

      
        },
        watch: {
  

        },

        methods: {

            
        },
    }
</script>

<style scoped>
    /* СЃРґРµР»Р°С‚СЊ С‡С‚РѕР±С‹ СѓРµР·Р¶Р°Р» РѕР»РіРѕС‚РёРї Рё РјРµРЅСЋ, Рё С‡С‚РѕР±С‹ СѓР±РёСЂР°Р»СЃСЏ РѕС‚СЃС‚СѓРї, РїРѕСЏРІРёРІС€РёР№СЃСЏ РёР·-Р·Р° СѓРµС…Р°РІС€РµРіРѕ Р»РѕРіРѕС‚РёРїР° Рё РјРµРЅСЋ - СѓР¶Рµ РІ РєРѕРјРїРѕРЅРµРЅС‚Рµ*/
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: unset!important;
        }
    }

</style>