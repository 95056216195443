<template>
    <section class="app-footer">
<!--        <footer class="footer">
            <div class="container-fluid clearfix">
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">© 2021 <a target='_blank' class="" href="https://mikebi.org/">Фонд развития образования МиКЭБИ</a>. Все права защищены.</span>
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/first/'+'?t='+$root._SYS().add_v()">first</router-link>
                </span>
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/template1/'+'?t='+$root._SYS().add_v()">template1</router-link>
                </span>
                
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/template2/'+'?t='+$root._SYS().add_v()">template2</router-link>
                </span>
                
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/gameover/'+'?t='+$root._SYS().add_v()">gameover</router-link>
                </span>
                
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/shift/'+'?t='+$root._SYS().add_v()">shift</router-link>
                </span>
                
                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
                    <router-link class="nav-link" v-bind:to="'/g1/reset/'+'?t='+$root._SYS().add_v()">reset</router-link>
                </span>
 
                <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-muted">
                    
                    client-v{{ this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc  }}/ page-v{{ this.$store.state.page_v }}
                </span>
            </div> 
        </footer>-->

    </section>
</template>

<script>
    export default {
        name: 'app-footer',

        mounted() {



        },

    }
</script>