<script>
import {
  Doughnut, mixins
} from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
    data() { 
        return {
            options: {
                
                
  elements: {
      arc: {
          borderWidth: 0
      }
  },
//                rotation: -Math.PI,
//                circumference: Math.PI,
                cutoutPercentage: 75,
//                responsive: true,
                
                legend: {
                    display: false
                },
                
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return tooltipItem.yLabel;
//                            return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +'%';
                        }
                    }
                },
                
                
            },
        }
    },
    

    mounted () {
        this.renderChart(this.chartData, this.options);
    },
 
                
                
}
</script>
