//ALT+SHIFT+F - форматирует выделенный участок кода
//CTRL+TAB переключение м\ду вкладками
//CTRL+SHIFT+C  открывает или закрывает выделенное в коммент
//СCTRL+SHIFT+ENTER открывает и закрывает экран на полную

//https://adults.eo.events:5001/check/game_ticket?_id=626f133ec851ebfb261d9a4d
//https://adults.eo.events/landing/ctel_2/?ctel=626dc96124b4dd25769d70d5
//https://f0-dev.game.eo.events:4437/?_id=626f133ec851ebfb261d9a4d
//https://f0-dev.game.eo.events:4437/?_id=626f133ec851ebfb261d9a4d&_game=g1

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '@/store/index.js';
//метрика на eoevents@yandex.ru
import VueRouter from 'vue-router'; /*?*/
import api_idb from '@/api/idb';



import Snotify, { SnotifyPosition} from 'vue-snotify';
const options = {toast: {position: SnotifyPosition.rightBottom, }};
Vue.use(Snotify, options);


//import $ from 'jquery'
//import VueYandexMetrika from 'vue-yandex-metrika-ts'
//        Vue.use(VueYandexMetrika, {
//            id: 85997303,
//            router: router, //this.$router,
//            //scriptSrc: "https://mc.yandex.ru/metrika/tag_turboapp.js",
//            env: process.env.NODE_ENV, /* PRODUCTION MODE if not set -DEV*/
//            options: {clickmap: true, trackLinks: true, accurateTrackBounce: true, trackHash: true} /*?trackHash:true*/
//        });
//в коде на mounted 
//if (this.$metrika)
//this.$metrika.hit("rimo_start");
//console.log(this.$metrika); //чё в объекте то из функций !!! там есть и друние  setUserID userParams для более крутой аналитики и сегментированя на основе импорта csv
//this.$metrika.reachGoal('text_help_click');         
//конец метрика       

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
//import 'bootstrap/dist/js/bootstrap.min.js'


//ДЕТЕКТИТЬ КЛИКИ ВНЕ ПОЛЕЙ ЭЛЕМЕНТА
Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
});
//ДЕТЕКТИТЬ КЛИКИ ВНЕ ПОЛЕЙ ЭЛЕМЕНТА



import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
//import moment from 'moment'   
import 'moment/locale/ru';
Vue.use(VueMoment,
        {
            moment,
        })


const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)



/*
 const debug = process.env.NODE_ENV !== 'production';
 // Для dev-режима
 if (debug) {
 axios.defaults.baseURL = 'http://w-shop.lc';
 }
 */

Vue.config.productionTip = true
Vue.config.devtools = true; //включает veu на продакшене

Vue.directive('focus', {//v-focus global user derictive
    // Когда привязанный элемент вставлен в DOM...
    inserted: function (el) {
        // Переключаем фокус на элемент
        el.focus()
    }
})


var cnf = {
    //TESTING

    is_allow_lkchild_answers_autocompile: false, //for test
    version: "",
    is_swoole: false,
    server_auth: "", //set in  created()
    server_action: "", //set in  created()
    server_cloud: "", //set in  created()
    server_cloud2: "", //set in  created()
    link_to_auth_dashboard: "", //set in  created()
    pd_: '', //set in  created()
    
    bl: '', //base_lang
    el: '', //educated_lang

    //TIMERS
    timer_reload__version_json: 30,
    timer_stage_changed: 5,
    timer_statdata: 120, /*not LESS axios execution 13 sec.......   120sec*/


}

var cnm = {

}

var texts = {
    error_sending: "При отправке данных возникла ошибка: 'сервер недоступен'. Пожалуйста, проверьте ваш доступ к сети интернет и повторите попытку. ",
    error_api: "При выполнении запроса возникла проблема. Мы уже занимаемся ее решением. Пожалуйста, повторите попытку позже. Приносим свои извинения за доставленные неудобства. ",
}

var _gameObj = {
    router,
    store,
    render: h => h(App),
    data: {
        cnf: cnf,
        cnm: cnm,
        texts: texts

    },
    created() {


        if (window.location.href.substr(0, 5) !== 'https') {
            window.location.href = window.location.href.replace('http:', 'https:');
        }

        this.cnf['server_auth'] = "https://sso-backend.eo-space.com:5000"; //    https://sso-backend.eo-space.com:5000/child/child_validation?_id=
        if (this.$root.cnf['is_swoole'])
        {
            this.cnf['server_action'] = "https://game-backend.eo-space.com:9507"; //SWOOLE
        } else
        {
            this.cnf['server_action'] = "https://game-backend.eo-space.com/eo.space-back/manapi.php"; //PHPFARM 
        }
        this.cnf['server_cloud2'] = window.location.protocol + '//' + window.location.host; //CLOUD  
//        this.cnf['server_cloud'] = window.location.protocol + '//' + window.location.host; //CLOUD  
        this.cnf['server_cloud'] = "https://tgen.eo-space.com"; //CLOUD 

        this.cnf['link_to_auth_dashboard'] = "https://sso.eo-space.com";
        this.cnf['pd_'] = "freH4bg4t2";
        
        this.cnf['el'] = 'en'; //educated_lang
        this.cnf['bl'] = 'ru'; //base_lang
        this.cnf['el_full'] = 'english'; //educated_lang
        this.cnf['bl_full'] = 'russian'; //base_lang
        
        //LOCAL STORAGE EVENT CATCH - LOGOUT - Разлогин на отдной вкладке, должен приводить к разлогину на всех остальных вкладках!!!
        //РАБОТАЕТ КОГДА БОЛЕЕ 1-й ВКЛАДКИ!!! Если вкладка одна - ничего не происходит!!!! 
        window.addEventListener('storage', this._AUTH()._onstorage);
        //Проверяем, нужно ли авторизовать ребенка //Если _id = не пустой... то редиректим на login page
        this.$root._AUTH().redirect_to_login();
        axiosRetry(axios,
                {
                    retries: 2,
                    retryDelay: (retryCount) => {
                        let interval = 2000 * retryCount;
                        console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);
                        return interval;
                    }, retryCondition: (error) => {
                        console.log(error.response.status);
                        //при любых ошибках - повторный запрос
                        return true;
                    }
                }
        );
    },
    mounted() {








    },
    methods: {
        /*     
         _CRYPT: function () { 
         var self = this;
         return {
         
         encrypt_str: function (str) {
         var crypto = require('crypto');
         var mykey = crypto.createCipher('aes-128-cbc', self.cnf['pd_']);
         var mystr = mykey.update(str, 'utf8', 'hex')
         mystr += mykey.final('hex');
         return mystr;
         },
         
         decrypt_str: function (cstr) {
         var crypto = require('crypto');
         var mykey = crypto.createDecipher('aes-128-cbc', self.cnf['pd_']);
         var mystr = mykey.update(cstr, 'hex', 'utf8')
         mystr += mykey.final('utf8');
         return mystr;
         },
         
         encrypt_json: function (json_data) {
         var str = JSON.stringify(json_data);
         var crypto = require('crypto');
         var mykey = crypto.createCipher('aes-128-cbc', self.cnf['pd_']);
         var mystr = mykey.update(str, 'utf8', 'hex')
         mystr += mykey.final('hex');
         return mystr;
         },
         
         decrypt_json: function (cjson_data) {
         var crypto = require('crypto');
         var mykey = crypto.createDecipher('aes-128-cbc', self.cnf['pd_']);
         var mystr = mykey.update(cjson_data, 'hex', 'utf8')
         mystr += mykey.final('utf8');
         var json = JSON.parse(mystr);
         return json;
         },
         
         }
         },
         */


        __OLD: function () {
            var self = this;
            return {

                load_ls_shop: function (data) {

                    //shop
                    let gid = data['a_registrations']['gid'];
                    if (localStorage.getItem('shop') !== null)
                    {
                        let cjson_shop = localStorage.getItem('shop');
                        data['shop'] = window._CRYPT().decrypt_json(cjson_shop);
                        if (data['shop'].hasOwnProperty('gid')) {
                            if (data['shop']['gid'] === gid)  //DA CHEKAET OTHER GAME shopS!
                            {
                                //ONLY LS CSENARIO
                                this.$root.get_shop_data(data, gid); // будет помещено в this.data['shop'],   если нету в ls подтянет из json и по факту загрузки обновит данные

                                return true;
                            } else
                            {
                                console.log("!!! NOT EQUAL data['shop']['gid']===gid");
                            }
                        } else
                        {
                            console.log("NO PROPETY data['shop'].hasOwnProperty('gid')");
                        }
                    } else
                    {
                        console.log("IS NULL localStorage.getItem('shop')!==null");
                    }

                    return false;
                },
                data_clear_user: function () {

                    localStorage.removeItem('active_users[cjson_users]');
                    localStorage.removeItem('active_childs[cjson_childs]');
                    localStorage.removeItem('active_subjects[cjson_subjects]');
                    localStorage.removeItem('active_games[cjson_games]');
                },
                clear_localstorage_on_updt: function (localstorage_stamp) {


                    if (localstorage_stamp !== null && localstorage_stamp !== "")
                    {
                        /* СБРОС LOCALSTORAGE на обновление  */
                        var ls_v = localStorage.getItem(localstorage_stamp);
                        if (ls_v === null)
                        {
                            let is_allow_EOO2 = localStorage.getItem('is_allow_EOO2');
                            let teachguid = localStorage.getItem('teachguid');
                            let teachschid = localStorage.getItem('teachschid');
                            localStorage.clear();
                            localStorage.setItem(localstorage_stamp, true);
                            if (is_allow_EOO2 !== null)
                            {
                                localStorage.setItem('is_allow_EOO2', 'true');
                            }
                            if (teachguid !== null)
                            {
                                localStorage.setItem('teachguid', teachguid);
                            }
                            if (teachschid !== null)
                            {
                                localStorage.setItem('teachschid', teachschid);
                            }
                        }

                    }

                    /* СБРОС LOCALSTORAGE на обновление  */

                },
             
                //curr_event_code
                //
                //

                //ОБНОВЛЯЕТ ВЕРСИЮ СТРАНИЦЫ В СООТВЕТСТВИИ С СОХРАНЕННОЙ ВЕРСИЕЙ В ЛОКАЛ СТОРАДЖ
                page_version_update_and_reload: function () {
                    console.log("CHECK PAGE:" + window.location.pathname.replace(/\//g, ''));
                    //!!! НЕ ВКЛЮЧАТЬ this.clear_localstorage_on_updt_20210330("__updt_20210403_1");//временная функция до проведения 2-х грантовых игр

                    let cjson_version_timestamp_utc = localStorage.getItem('version_timestamp_utc');
                    if (cjson_version_timestamp_utc !== null)
                    {

                        let version_timestamp_utc = window._CRYPT().decrypt_json(cjson_version_timestamp_utc);
                        let curr_ver = version_timestamp_utc['version_timestamp_utc'];
                        console.log("CURR SITE VERSION:" + version_timestamp_utc['version_timestamp_utc']);
                        //НЕ ВКЛЮЧАТЬ - ТОЛЬКО ДЛЯ ПРОВЕРКИ добавления к URL  t=time()
                        //this.$root.reload_page_with_t_stamp(curr_ver);


                        //if (is_allow_log){console.log("nothing to do");}
                        //если в версии кэша страницы не совпадает версия на сервере в version.json то страницу надо обновить.
                        let version_chach_page = localStorage.getItem('version_chach_page[' + window.location.pathname.replace(/\//g, '') + ']');
                        if (version_chach_page === null)
                        {
                            console.log("PAGE_VERSION IS NULL |set=" + curr_ver);
                            localStorage.setItem('version_chach_page[' + window.location.pathname.replace(/\//g, '') + ']', curr_ver);
                            //перезагрузка!  тоже сделаем! 
                            //this.$root.reload_page_with_t_stamp(curr_ver);
                            window.location.reload();
                        } else {

                            console.log("PAGE_VERSION NOT NULL AND is=" + version_chach_page);
                            console.log("ACTUAL VERSION site is=" + curr_ver);
                            if (version_chach_page !== curr_ver)
                            {
                                console.log("UPDATE PAGE_VERSION TO=" + curr_ver);
                                localStorage.setItem('version_chach_page[' + window.location.pathname.replace(/\//g, '') + ']', curr_ver);
                                //перезагрузка!
                                //window.location.reload();  
                                //this.$root.reload_page_with_t_stamp(curr_ver);
                                window.location.reload();
                            } else
                            {
                                console.log("NOTHING TODO. Version is actual");
                            }

                        }
                    } else
                    {
                        console.log("unexpectadly ls site version null");
                    }



                },
                get_shop_data: function (data, gid) {
                    /* ['shop'] */
                    data['is_loaded_tables_shop'] = false; //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ data - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить data['val']!!! ПРОСТЫЕ - значением, если просто передать data['val'] то передастся НЕ ПО ССЫЛКЕ!!!!


                    let is_allow_log = true;
                    //проверим соответствует ли загруженные в LS карточки GID


                    if (localStorage.getItem('shop') !== null)
                    {


                        //иначе возьмем из ls
                        let cjson_shop = localStorage.getItem('shop');
                        data['shop'] = window._CRYPT().decrypt_json(cjson_shop);
                        if (data['shop'].hasOwnProperty('gid')) {



                            if (data['shop']['gid'] === gid)  //DA CHEKAET OTHER GAME shopS!
                            {
                                if (is_allow_log) {
                                    console.log('shop -> WILL load from ls');
                                    console.log(data['shop']);
                                }

                            } else
                            {
                                if (is_allow_log) {
                                    console.log('shop -> MISSMATCH gid');
                                    console.log('shop -> removeItem shop');
                                }
                                localStorage.removeItem('shop');
                                data['is_loaded_tables_shop'] = false;
                            }


                        } else
                        {
                            if (is_allow_log) {
                                console.log('shop -> error hasOwnProperty(gid)');
                                console.log('shop -> removeItem shop');
                            }
                            localStorage.removeItem('gid');
                            data['is_loaded_tables_shop'] = false;
                        }





                    }






                    //Загрузка списка школ
                    //ЕСЛИ В ls пусто - загрузим черех axios
                    if (localStorage.getItem('shop') === null)
                    {

                        if (is_allow_log) {
                            console.log('shop -> byref, load from json ');
                        }




                        let url = location.protocol + '//' + window.location.hostname + (location.port ? ':' + location.port : '') + '/games_data/shop/' + gid + '/products.json' + '?timestamp=' + (new Date()).getTime();
                        const config = {
                            headers: {Pragma: 'no-cache'}
                        }
                        axios.get(url, config)
                                .then(response =>
                                {

                                    try {

                                        if (is_allow_log) {
                                            console.log("shop");
                                            console.log(response['data']);
                                        }
                                        let shop = response['data'];
                                        var cjson_shop = window._CRYPT().encrypt_json(shop);
                                        localStorage.setItem('shop', cjson_shop);
                                        data['shop'] = shop;
                                        data['is_loaded_tables_shop'] = true;
                                        data['cnt_loaded'] = data['cnt_loaded'] + 1;
                                    } catch (e) {

                                        //если какая-то ошибка - просто снесем значение в local storage
                                        localStorage.removeItem('shop');
                                        if (is_allow_log) {
                                            console.log("error---");
                                            console.log(e);
                                        }
                                        //ничего делать не надо                      
                                    }


                                })
                                .catch(error => {
                                    console.log("get shop, all attemps faild =" + error);
                                    //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
                                })
                                .then(function () {

                                });
                    } else
                    {

                        //иначе возьмем из ls
                        let cjson_shop = localStorage.getItem('shop');
                        data['shop'] = window._CRYPT().decrypt_json(cjson_shop);
                        data['cnt_loaded'] = data['cnt_loaded'] + 1;
                        data['is_loaded_tables_shop'] = true;
                        if (is_allow_log) {
                            console.log(data['shop']);
                            console.log('shop -> load from ls');
                        }
                    }




                },
            }
        },

        _SHIFT: function () {
            var self = this;
            return {

                check_shift_by_rg_learning: function ($store, cdata, state) {



                    //соберем все id разделов задач в раскладке
                    let is_requare_set_new_layout = false;
                    let formed_theme_list = {};
                    //!!список тем- пустой обзект - накапливаем...

                    for (const [key_desk, desk] of Object.entries(state.desks)) {


                        for (const [key_theme, theme] of Object.entries(desk.theme_list)) {

                            //если нету добавить theme_id
                            let is_theme_in_list = false;
                            for (const [tkey, trow] of Object.entries(formed_theme_list)) {
                                if (trow['theme_id'] === theme.theme_id)
                                {
                                    is_theme_in_list = true;
                                    break;
                                }
                            }

                            if (!is_theme_in_list)
                            {
                                formed_theme_list[theme.theme_id] = {};
                                Object.assign(formed_theme_list[theme.theme_id],
                                        {
                                            theme_id: theme.theme_id,
                                            theme_learning_task_limit: theme.theme_learning_task_limit
                                        }
                                );



                            }



                        }
                    }
                    cdata.business_logic.formed_theme_list = formed_theme_list;
                    console.log(formed_theme_list);


                    //на выоде Список formed_theme_list всех тем в раскладке 
                    //с текущим лимитом по выученности theme_id/theme_learning_task_limit

                    //перебираем formed_theme_list смотрим в таблице текущей выученности, если темы нет хотя бы одной, - рано! 
                    //Если выученность еще не доведена до уровня - рано!

                    let shift_disabled_exist_theme_not_fineded = false;
                    let shift_disabled_exist_theme_not_learned_well = false;


                    for (const [key_theme, theme] of Object.entries(cdata.business_logic.formed_theme_list)) {

                        let is_theme_id_fineded = false;
                        let rg_learning_key = -1;
                        for (const [key_row, rg_learning_row] of Object.entries($store.state.state_tables.state_rg.rg_learning)) {

                            if (rg_learning_row['theme_id'].toString() === theme['theme_id'].toString())
                            {
                                is_theme_id_fineded = true;
                                rg_learning_key = key_row;
                                break;
                            }
                        }

                        if (!is_theme_id_fineded)
                        {
                            //Есть неизученная вообще тема...
                            theme['is_not_finded_theme'] = true;
                            theme['is_not_learned_well_theme'] = false;
                            shift_disabled_exist_theme_not_fineded = true;
                            //break; 
                        } else
                        {
                            theme['is_not_finded_theme'] = false;
                            theme['is_not_learned_well_theme'] = false;
                            theme['theme_learning_task_count'] = Math.floor($store.state.state_tables.state_rg.rg_learning[rg_learning_key]['theme_learning_task_count']);

                            //проверить по текущей теме    theme['theme_learning_task_limit']  rg_learning_row['theme_learning_task_count'] 
                            if (Math.floor(theme['theme_learning_task_limit']) > Math.floor($store.state.state_tables.state_rg.rg_learning[rg_learning_key]['theme_learning_task_count']))
                            {
                                shift_disabled_exist_theme_not_learned_well = true;
                                theme['is_not_learned_well_theme'] = true;
                                //break;
                            }

                        }


                    }

                    let result = {};
                    console.log("shift_disabled_exist_theme_not_learned_well="+shift_disabled_exist_theme_not_learned_well);
                    console.log("shift_disabled_exist_theme_not_fineded="+shift_disabled_exist_theme_not_fineded);
                  
                    // FORSE TURN ON INSIDE !shift_disabled_exist_theme_not_learned_well || !shift_disabled_exist_theme_not_fineded
                    // CORRECT              shift_disabled_exist_theme_not_learned_well || shift_disabled_exist_theme_not_fineded
                    if (shift_disabled_exist_theme_not_learned_well || shift_disabled_exist_theme_not_fineded)
                    {
                        //is_requare_set_new_layout = false; //сдвиг запрещен
                        result['is_shift'] = false;
                        result['info'] = "сдвиг запрещен";

                    } else
                    {
                        //is_requare_set_new_layout = true;//сдвиг разрешен
                        result['is_shift'] = true;
                        result['info'] = "сдвиг разрешен";
                    }



                    return result;

                },

                check_shift_by_datetime: function ($store, cdata, state) {

                    //проверить  shift_list

                    let is_shift_finded = false;
                    let shift_classnum = -1;
                    let shift_lid = -1;
                    for (const [key_event, shift] of Object.entries($store.state.state_tables.state_manage.shift_list)) {
                        //console.log(shift.start_timestamp_MSK + "|" + shift.lid+ "|" + shift.classnum);
                        shift['tsh_start'] = new Date(shift.start_timestamp_MSK).getTime() / 1000;
                        shift['tsh_close'] = new Date(shift.close_timestamp_MSK).getTime() / 1000;

                        let now = $store.state.curr_event.event_timings.curr_timestamp;

                        //console.log(shift['ts_start'] + " < " + cdata.system.curr_event.event_timings.curr_timestamp + " < " + event['ts_close']);
                        if (
                                shift['tsh_start'] <= now &&
                                now < shift['tsh_close'] &&
                                shift['classnum'].toString() === $store.state.state_tables.state_auth.r_auth.classnum.toString()
                                )
                        {
                            console.log("SHIFTY Позиция для сдвига по таблице " + shift.classnum + "=classnum|lid=" + shift.lid);
                            is_shift_finded = true;
                            shift_classnum = shift.classnum;
                            shift_lid = shift.lid;
                            break;
                        }


                    }

                    let result = {};

                    if (!is_shift_finded)
                    {

                        result['is_shift'] = false;
                        result['is_row_dont_find'] = true;
                        result['info'] = "Ошибка - нет раскладки для текущей даты вермени в shift_list";
                    } else
                    {
                        if (
                                $store.state.state_tables.state_auth.r_auth.lid >= shift_lid)
                        {
                            result['is_shift'] = false;
                            result['is_row_dont_find'] = false;
                            result['info'] = "СДВИГ TIMESHIFT НЕ НУЖЕН (текущий lid больше или равен того на который требуется сдвинуть)";
                        } else
                        {
                            result['is_shift'] = true;
                            result['is_row_dont_find'] = false;
                            result['new_classnum'] = shift_classnum;
                            result['new_lid'] = shift_lid;
                            result['info'] = "СДВИГ НА " + shift_classnum + "=classnum|lid=" + shift_lid;
                            console.log("СДВИГ НА " + shift_classnum + "=classnum|lid=" + shift_lid);
                        }

                        //если раскладка совпадает - то окей
                        //иначе требуется сдвиг куда надо, если номер раскладки больше! чем текущий номер раскладки,... сдвиг только вперед!



                    }



                    return result;
                },

            }
        },

        _LAYOUT: function () {
            var self = this;
            return {
                
                check_unlocked_chapters: function($store, cdata){
                    let output = {};
                    if($store.state.is_all_stage_fin && $store.state.is_loaded_tables.quest_manager.load_status){
                        for (let [a_part_index, a_part_data] of Object.entries(cdata.business_logic['a_part'].episods)) {
                            for (let [chapter_index, chapter_data] of Object.entries(a_part_data.chapters)) {

                                if(chapter_data.chapter_ancestor != "first"){
                                    output[chapter_data.chapter_qr] = "locked";

                                    $store.state['state_tables'].state_game_stats.quest_manager.forEach(quest => {
                                        if(quest.quest_type.split("_")[0] == "story"){
                                            let side_quest__ancestor_data = JSON.parse(quest.ancestor_data);
                                            let side_quest__quest_progress = JSON.parse(quest.quest_progress);
                                            if(side_quest__ancestor_data.quest_id == chapter_data.chapter_ancestor &&
                                                    side_quest__quest_progress.status == "done"){
                                                output[chapter_data.chapter_qr] = "unlocked";
                                             }
                                        }
                                    });
                                } else {
                                    output[chapter_data.chapter_qr] = "unlocked";
                                }
                            }
                        }
                    }
                    return output;
                },
                
                show_info_modal: function ($store, cdata) {
                    if($store.state.is_all_stage_fin){
                        let showed_modal_before = localStorage.getItem("intro_modal");
                        console.log('show_modal_nkjnk');
                        console.log(showed_modal_before);
                        if(!showed_modal_before) {
//                            localStorage.setItem("intro_modal", true);
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                },
                
                hide_info_modal: function ($store, cdata) {
                    let showed_modal_before = localStorage.getItem("intro_modal");
                    console.log('hide_modal_nkjnk');
                    console.log(showed_modal_before);
                    localStorage.setItem("intro_modal", true);
                    showed_modal_before = localStorage.getItem("intro_modal");
                    console.log(showed_modal_before);
                    console.log('hide_modal_nkjnk');
                },
                
                get_players_gold: function ($store, cdata, is_loaded_player_inventory) {
                    if (is_loaded_player_inventory === "fin"){
                        let gold_res = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_gold");
                        if(gold_res) {return gold_res.item_count;}
                        else {return 0;}
                    } else {
                        return 0;
                    }
                },
                
                form_quest_manager: function  ($store, cdata, is_loaded_quest_manager, is_loaded_global__inventory_glossary) {
//                    if(is_loaded_quest_manager == "fin" && is_loaded_global__inventory_glossary == "fin"){
                    if($store.state.is_loaded_tables.quest_manager.load_status && $store.state.is_loaded_tables.global__inventory_glossary.load_status){
                        
                        let filter = {
                            resources_to_give: {}, //"res_gold": ['0': '16'] id quest
                            side_quests: [],
                            chain_quest: [],
                            event_quests: []
                        };
                        
                        $store.state['state_tables'].state_game_stats.quest_manager.forEach(quest => {
                            
                            let quest_copy = { ...quest };
                            
                            let quest_type = "SIDE";
                            switch(quest_copy.quest_type){
                                case "CHAIN":
                                    quest_type = "CHAIN";
                                    break;
                                case "EVENT":
                                    quest_type = "EVENT";
                                    break;
                                default: 
                                    quest_type = "SIDE";
                            }
                            
                            
                            if(quest_type == "SIDE"){
                                let quest_progress__side_check = JSON.parse(quest_copy.quest_progress);
                                if(quest_progress__side_check.status == "done"){
                                    return;
                                }
                                
                                quest_copy.ready_to_complete = 1;
                                
                                
                                let quest_options__side_check = JSON.parse(quest_copy.quest_options);
                                if(quest_options__side_check) {
                                    quest_copy.quest_options = quest_options__side_check;
                                }
                            }
                            
//                            QUEST PROGRESS - resources_to_give
                            quest_copy.quest_progress = JSON.parse(quest_copy.quest_progress);
                            for (const [res_id, res_amount] of Object.entries(quest_copy.quest_progress)){
                                if(!filter.resources_to_give[res_id]) { filter.resources_to_give[res_id] = []; }
                                filter.resources_to_give[res_id].push(quest_copy.id);
                            }
//                            END QUEST PROGRESS - resources_to_give

//                            quest_copy.what_to_get = JSON.parse(quest_copy.what_to_get);
                                    
                                    let persent_done = "0";
                                    let need_to_give = 0;
                                    let already_earned = 0;
                                    
                                    let prof_exp_reward = 0;
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(JSON.parse(quest_copy.what_to_get))) {
                                        if(resource_id == "prof_exp") {
                                            prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { 
                                                ...this.find_in_inventory_glossary(resource_id, $store)
                                            };

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    quest_copy.what_to_get = new_what_to_get;
                                    
                                    quest_copy.prof_exp_reward = prof_exp_reward;
                            
                            
                            
//                            quest_copy.what_to_give = JSON.parse(quest_copy.what_to_give);

                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(JSON.parse(quest_copy.what_to_give))) {
                                        let what_to_give_find__res = { 
                                            ...this.find_in_inventory_glossary(resource_id, $store)
                                        };
                                                
                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };
                                        
                                        if(quest_type == "SIDE"){
                                            let res_in_players_inv = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == what_to_give_find__res.item_id);

                                            what_to_give_new__res['current_amount'] = 0;

                                            if(res_in_players_inv) { what_to_give_new__res['current_amount'] = Number(res_in_players_inv.item_count); }
                                            
                                            if(what_to_give_new__res['current_amount'] < resource_amount) { quest_copy.ready_to_complete = 0; }
                                        }
                                        
                                        new_what_to_give.push(what_to_give_new__res);
                                        
                                        need_to_give += Number(resource_amount);
                                        already_earned += Number(quest_copy.quest_progress[resource_id]);
                                    }
                                    quest_copy.what_to_give = new_what_to_give;
                            
                                    console.log("njknkjnkjn");
                                    console.log(need_to_give);
                                     console.log(already_earned);
                                    console.log("njknkjnkjn");
                                    if(quest_type == "CHAIN"){
                                        persent_done = (already_earned * 100) / need_to_give;
                                        quest_copy.persent_done = persent_done + "%";
                                    }

                            
//                            SORT QUESTS
                            
                            switch(quest_copy.quest_type){
                                case "CHAIN":
                                    filter.chain_quest.push(quest_copy);
                                    break;
                                case "EVENT":
                                    filter.event_quests.push(quest_copy);
                                    break;
                                default: 
                                    filter.side_quests.push(quest_copy);
                            }
                            
//                            END SORT QUESTS
                        });
                        
                        return filter;
                    } else {
                        return {};
                    }
                },   
                
                
                form_chain_quest: function ($store, cdata, player_professions) {
                    let output = {
                        available_extensions: {
                            Formulas: [],
                            Items: [],
                            Boosters: []
                        },
                        
                        chain_quest: {
                            what_to_give: {},
                            what_to_get: {},
                            quest_progress: {},
                            ancestor_data: {
                                Boosters: ""
                            }
                        },
                        
                        prof_exp_bonus_for_category: [],
                        
                        chain_quest_to_display: {
                            what_to_give: [],
                            what_to_get: [],
                            prof_exp: "0"
                        },
                    };
                    
                    if(player_professions && cdata.business_logic.show_chain_modal) {
                        let selected_profession_index = cdata.business_logic.prof_slider_index;
                        
                        for (const [extension_category, extension_category_obj] of Object.entries(player_professions[selected_profession_index].prof_extensions)){
                            for (const [extensions_unlock_lvl, extensions_data] of Object.entries(extension_category_obj)){
                                if(Number(extensions_data.item_count) > 0){
                                    output.available_extensions[extension_category].push({...extensions_data});
                                }
                            }
                        }
                        
//                        add watch and set null on prof change????
//                        show_selected FIRST LOAD

                            if(cdata.business_logic.chain.selected_extensions.Formulas == null) { 
                                cdata.business_logic.chain.selected_extensions.Formulas = output.available_extensions['Formulas'][0];
                                output.available_extensions['Formulas'] = output.available_extensions['Formulas'].filter(item => item.item_id !== cdata.business_logic.chain.selected_extensions.Formulas.item_id);

                            } else {
                                output.available_extensions['Formulas'] = output.available_extensions['Formulas'].filter(item => item.item_id !== cdata.business_logic.chain.selected_extensions.Formulas.item_id);
                            }

                            if(cdata.business_logic.chain.selected_extensions.Items != null) {
                                output.available_extensions['Items'] = output.available_extensions['Items'].filter(item => item.item_id !== cdata.business_logic.chain.selected_extensions.Items.item_id);

    //                            ADD BONUS PROF_EXP FOR SLOT
                                output.prof_exp_bonus_for_category = output.prof_exp_bonus_for_category.filter(item => item.category_name !== "Items");
                                let prof_exp_bonus_for_Items = {
                                    category_name: "Items",
                                    category_img: "/resource/img/game_ico/extensions_ico/Items_category.svg",
                                    bonus__prof_exp: 10
                                };
                                if(output.prof_exp_bonus_for_category.length > 0) { prof_exp_bonus_for_Items.bonus__prof_exp = 20; }
                                output.prof_exp_bonus_for_category.push(prof_exp_bonus_for_Items);
                            }

                            if(cdata.business_logic.chain.selected_extensions.Boosters != null) {
                                output.available_extensions['Boosters'] = output.available_extensions['Boosters'].filter(item => item.item_id !== cdata.business_logic.chain.selected_extensions.Boosters.item_id);

    //                            ADD BONUS PROF_EXP FOR SLOT
                                output.prof_exp_bonus_for_category = output.prof_exp_bonus_for_category.filter(item => item.category_name !== "Boosters");
                                let prof_exp_bonus_for_Boosters = {
                                    category_name: "Boosters",
                                    category_img: "/resource/img/game_ico/extensions_ico/Boosters_category.svg",
                                    bonus__prof_exp: 10
                                };
                                if(output.prof_exp_bonus_for_category.length > 0) { prof_exp_bonus_for_Boosters.bonus__prof_exp = 20; }
                                output.prof_exp_bonus_for_category.push(prof_exp_bonus_for_Boosters);
                            }
                        
                        output.chain_quest_to_display = this.form_chain_format_for_display(cdata.business_logic.chain.selected_extensions);
                        output.chain_quest = { ...this.form_chain_format_for_db(cdata.business_logic.chain.selected_extensions) };
                        
                    }
                    
                    return output;
                },
                
                form_chain_format_for_display: function(selected_extensions){
                    
                    let what_to_get = [];
                    let what_to_give = [];
                    
                    let prof_exp_reward = 0;
                    
                    for (const [extension_category, extension_data] of Object.entries(selected_extensions)){
                        if(extension_data){
                            
                            extension_data.what_to_get.forEach(item_data => {
                                let exist_resource_index = what_to_get.findIndex(res => res.item_id == item_data.item_id);
                                if(exist_resource_index != -1) {
                                    what_to_get[exist_resource_index].amount = (Number(what_to_get[exist_resource_index].amount) + Number(item_data.amount)).toString();
                                } else {
                                    what_to_get.push({...item_data});
                                }
                            });

                            prof_exp_reward = prof_exp_reward + Number(extension_data.prof_exp_reward);

                            extension_data.what_to_give.forEach(item_data => {
                                let exist_resource_index = what_to_give.findIndex(res => res.item_id == item_data.item_id);
                                if(exist_resource_index != -1) {
                                    what_to_give[exist_resource_index].amount = (Number(what_to_give[exist_resource_index].amount) + Number(item_data.amount)).toString();
                                } else {
                                    what_to_give.push({...item_data});
                                }
                            });
                        }
                    }
                     
                    if((selected_extensions.Items != null && selected_extensions.Boosters == null) || (selected_extensions.Items == null && selected_extensions.Boosters != null)) { prof_exp_reward = Number(prof_exp_reward) + 10; }
                    else if(selected_extensions.Items != null && selected_extensions.Boosters != null) { prof_exp_reward = Number(prof_exp_reward) + 30; }
                    
                    what_to_get["prof_exp"] = prof_exp_reward.toString();
                    
                    return {
                        "what_to_get": what_to_get,
                        "what_to_give": what_to_give,
                        "prof_exp": prof_exp_reward
                    };
                },
                
                form_chain_format_for_db: function(selected_extensions){
                    
                    let quest_progress = {};
                    let what_to_get = {};
                    let what_to_give = {};
                    let ancestor_data = {
                        Boosters: null,
                        Formulas: null,
                        Items: null
                    };
                    
                    let prof_exp_reward = 0;
                    
                    for (const [extension_category, extension_data] of Object.entries(selected_extensions)){
                        if(extension_data){
                            
                            ancestor_data[extension_category] = extension_data.item_id;
                            
                            extension_data.what_to_give.forEach(item_data => {
                                quest_progress[item_data.item_id] = "0";
                            });

                            extension_data.what_to_get.forEach(item_data => {
                                if(!what_to_get[item_data.item_id]) { what_to_get[item_data.item_id] = 0; }
                                what_to_get[item_data.item_id] = (Number(what_to_get[item_data.item_id]) + Number(item_data.amount)).toString();
                            });

                            prof_exp_reward = prof_exp_reward + Number(extension_data.prof_exp_reward);

                            extension_data.what_to_give.forEach(item_data => {
                                if(!what_to_give[item_data.item_id]) { what_to_give[item_data.item_id] = 0; }
                                what_to_give[item_data.item_id] = (Number(what_to_give[item_data.item_id]) + Number(item_data.amount)).toString();
                            });
                        }
                    }
                     
                    if((ancestor_data.Items != null && ancestor_data.Boosters == null) || (ancestor_data.Items == null && ancestor_data.Boosters != null)) { prof_exp_reward = Number(prof_exp_reward) + 10; }
                    else if(ancestor_data.Items != null && ancestor_data.Boosters != null) { prof_exp_reward = Number(prof_exp_reward) + 30; }
                    
                    what_to_get["prof_exp"] = prof_exp_reward.toString();
                    
                    return {
                        ...{"what_to_get": what_to_get},
                        ...{"what_to_give": what_to_give},
                        ...{"quest_progress": quest_progress},
                        ...{"ancestor_data": ancestor_data}
                    };
                },
                
                form_chain_format_with_ids_for_db: function(selected_extensions){
                    
                    let quest_progress = {};
                    let what_to_get = {};
                    let what_to_give = {};
                    let ancestor_data = {
                        Boosters: null,
                        Formulas: null,
                        Items: null
                    };
                    
//                    let prof_exp_reward = 0;
                    
                    for (const [extension_category, extension_data] of Object.entries(selected_extensions)){
                        if(extension_data){
                            
                            ancestor_data[extension_category] = extension_data.item_id;
                            
                            for (const [item_id, item_data] of Object.entries(extension_data.what_to_give)) {
                                quest_progress[item_id] = "0";
                                
                                if(!what_to_give[item_id]) { what_to_give[item_id] = 0; }
                                what_to_give[item_id] = (Number(what_to_give[item_id]) + Number(item_data)).toString();
                            }
                            
                            for (const [item_id, item_data] of Object.entries(extension_data.what_to_get)) {
                                if(!what_to_get[item_id]) { what_to_get[item_id] = 0; }
                                what_to_get[item_id] = (Number(what_to_get[item_id]) + Number(item_data)).toString();
                            }
                        }
                    }
                     
                    
//                    what_to_get["prof_exp"] = prof_exp_reward.toString();
                    if((ancestor_data.Items != null && ancestor_data.Boosters == null) || (ancestor_data.Items == null && ancestor_data.Boosters != null)) { what_to_get["prof_exp"] = (Number(what_to_get["prof_exp"]) + 10).toString(); }
                    else if(ancestor_data.Items != null && ancestor_data.Boosters != null) { what_to_get["prof_exp"] = (Number(what_to_get["prof_exp"]) + 30).toString(); }
                    
                    return {
                        ...{"what_to_get": what_to_get},
                        ...{"what_to_give": what_to_give},
                        ...{"quest_progress": quest_progress},
                        ...{"ancestor_data": ancestor_data}
                    };
                },
                
                form_story_quests__for_display: function(selected_quest, quest_id, $store) {
                    
//                    need to put ansestor data => quest id

//                    ready_to_complete always trey and then chck => if all good => nothing changes
                    let quest_to_display = {
                        "quest_type": "",
                        "ready_to_complete": 1,
                        "already_active": 0,
                        "already_approved": 0,
                        
                        "what_to_give": [],
                        "what_to_get": [],
                        "quest_options": selected_quest.quest_options,
                    };
//                    already_active LOOK IN QUEST_MANAGER DB
                    
                    if(selected_quest.quest_type == "story_quest") {
                        quest_to_display.quest_type = "story_quest";
                        
                        
                        let new_what_to_get = [];
                        for (const [resource_id, resource_amount] of Object.entries(selected_quest.what_to_get)) {
                            let what_to_get_find__res = { 
                                ...this.find_in_inventory_glossary(resource_id, $store)
                            };

                            let what_to_get_new__res = {
                                item_id: what_to_get_find__res.item_id,
                                item_img: what_to_get_find__res.item_img,
                                item_name: what_to_get_find__res.item_name,
                                amount: resource_amount,
                            };

                            new_what_to_get.push(what_to_get_new__res);
                        }
                        quest_to_display.what_to_get = new_what_to_get;
                        
                        
                        let new_what_to_give = [];
                        for (const [resource_id, resource_amount] of Object.entries(selected_quest.what_to_give)) {
                            let what_to_give_find__res = { 
                                ...this.find_in_inventory_glossary(resource_id, $store)
                            };
                            
                            let what_to_give_find__res__current_inv = {
                                "item_id": resource_id,
                                "item_count": 0
                            };
                            
                            for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_stats.player_inventory)) {
                                if(item_data.item_id == resource_id) {
                                    what_to_give_find__res__current_inv = { ...item_data};
                                    break;
                                }
                            }
                            
                            if(Number(what_to_give_find__res__current_inv.item_count) < Number(resource_amount)) {
                                quest_to_display.ready_to_complete = 0;
                            }

                            let what_to_give_new__res = {
                                item_id: what_to_give_find__res.item_id,
                                item_img: what_to_give_find__res.item_img,
                                item_name: what_to_give_find__res.item_name,
                                amount: resource_amount,
                                current_amount: what_to_give_find__res__current_inv.item_count
                            };

                            new_what_to_give.push(what_to_give_new__res);
                        }
                        quest_to_display.what_to_give = new_what_to_give;
                    }
                    
                    
                    for (const [existing_quest_index, existing_quest] of Object.entries($store.state.state_tables.state_game_stats.quest_manager)) {
                        if(existing_quest.quest_type == "story_quest") {
                            let existing_quest__ancestor_data = JSON.parse(existing_quest.ancestor_data);
                            if(existing_quest__ancestor_data.quest_id && existing_quest__ancestor_data.quest_id == quest_id) {
                                quest_to_display.already_active = 1;
                                let found_quest__progress = JSON.parse(existing_quest.quest_progress);
                                if(found_quest__progress.status == "done") { quest_to_display.already_approved = 1; }

                                break;
                            }
                        }
                    }
                    
                    return quest_to_display;
                    
                },
                
                get_player_professions: function ($store, cdata, player_inventory, set_active_prof_in_cdata = false) {

                    if ($store.state.is_loaded_tables.global__profession_list.load_status && $store.state.is_loaded_tables.player_professions.load_status && player_inventory && $store.state.is_loaded_tables.global__inventory_glossary.load_status)
                    {
                        let filter = [];
                        for (const [profession_list_index, profession_list_item] of Object.entries($store.state['state_tables'].state_game_settings.global__profession_list.profession_list)){
                            let player_prof = $store.state.state_tables.state_game_stats.player_professions.find(prof => prof.prof_id == profession_list_item.prof_id);
                            
                            if(player_prof) {
                                
                                
//                                RESOURCES
                                let prof_resources_conf = [];
                                for (const [prof_res_index, prof_res_name] of Object.entries(profession_list_item.prof_resources)) {
                                    let resource_to_find = player_inventory['resources'].find(res_inv => res_inv.item_id == prof_res_name);
                                    if(!resource_to_find) { resource_to_find = player_inventory['resources'][0]; }
                                    
                                    prof_resources_conf.push(resource_to_find);
                                }
                                
//                                FORMULAS, BOOSTERS, ITEMS
                                let prof_extensions = {
                                    Formulas: {},
                                    Items: {},
                                    Boosters: {}
                                };
//                                TODO WHAT TO GET, WHAT TO GIVE
                                let formula_count = 1;
                                let item_count = 1;
                                let booster_count = 1;
                                
                                for (const [prof_formula_index, prof_formula_id] of Object.entries(profession_list_item.prof_extensions.Formulas)) {
                                    let formula_to_find = player_inventory['prof_formulas'].find(formula_inv => formula_inv.item_id == prof_formula_id);
                                    if(!formula_to_find) { formula_to_find = player_inventory['prof_formulas'][0]; }
                                    
//                                    prof_extensions.Formulas[prof_formula_index] = { ...formula_to_find };
                                    
                                    let new_formula = { 
                                        ...formula_to_find,
                                        ...{"tier": formula_count}
                                    };
                                    formula_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_formula.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_formula.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { 
                                                ...this.find_in_inventory_glossary(resource_id, $store)
                                            };

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_formula.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_formula.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};
                                                
                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };
                                        
                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_formula.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Formulas[prof_formula_index] = new_formula;
                                    
                                }
                                
                                for (const [prof_item_index, prof_item_id] of Object.entries(profession_list_item.prof_extensions.Items)) {
                                    let item_to_find = player_inventory['prof_items'].find(item_inv => item_inv.item_id == prof_item_id);
                                    if(!item_to_find) { item_to_find = player_inventory['prof_items'][0]; }
                                    
//                                    prof_extensions.Items[prof_item_index] = { ...item_to_find };

                                    let new_item = { 
                                        ...item_to_find,
                                        ...{"tier": item_count}
                                    };
                                    item_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_item.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_item.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_item.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_item.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};
                                                
                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };
                                        
                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_item.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Items[prof_item_index] = new_item;
                                }
                                
                                for (const [prof_booster_index, prof_booster_id] of Object.entries(profession_list_item.prof_extensions.Boosters)) {
                                    let booster_to_find = player_inventory['prof_boosters'].find(item_booster => item_booster.item_id == prof_booster_id);
                                    if(!booster_to_find) { booster_to_find = player_inventory['prof_boosters'][0]; }
                                    
//                                    prof_extensions.Boosters[prof_booster_index] = { ...booster_to_find };

                                    let new_booster = { 
                                        ...booster_to_find,
                                        ...{"tier": booster_count}
                                    };
                                    booster_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_booster.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_booster.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_booster.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_booster.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };

                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_booster.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Boosters[prof_booster_index] = new_booster;
                                }
//                                FORMULAS, BOOSTERS, ITEMS
                                
                                
                                let conf = {
                                    "current_lvl": 1,
                                    "current_exp": 0,
                                    "current_lvl_min": 0,
                                    "current_lvl_max": 100,
                                    "lvl_progress_percentage": "0"
                                };

                                conf.current_exp = player_prof.prof_exp;

                                for (const [key_level, level_info] of Object.entries(profession_list_item.prof_levels)) {
                                    if(Number(conf.current_exp) >= Number(level_info.exp_min)) {
                                        conf.current_lvl = key_level;
                                        conf.current_lvl_max = level_info.exp_max;
                                        conf.current_lvl_min = level_info.exp_min;
                                    }
                                }

                                let percentage__to_lvl_up = Number(conf.current_lvl_max) - Number(conf.current_lvl_min);
                                let percentage__solved_to_lvl_up = Number(conf.current_exp) - Number(conf.current_lvl_min);

                                let percentage_to_lvl_up = (percentage__solved_to_lvl_up * 100) / percentage__to_lvl_up;
                                percentage_to_lvl_up = Math.floor(percentage_to_lvl_up);
                                conf.lvl_progress_percentage = percentage_to_lvl_up > 100 ? "100" : percentage_to_lvl_up + "%";
                                
                                
                                
//                                filter.push({
//                                    ...profession_list_item,
//                                    ...player_prof,
//                                    lvl_info: conf
//                                });
                                let output = {
                                    ...profession_list_item,
                                    ...player_prof,
                                    lvl_info: conf
                                };
                                
                                output.prof_resources = prof_resources_conf;
                                output.prof_extensions = prof_extensions;
                                
                                
                                filter.push(output);
                                
                                
                                if(set_active_prof_in_cdata && player_prof.is_active == 1){
                                    cdata.business_logic.selected_profession = output;
                                }
                                
                            } else {
                                
                                
//                                RESOURCES
                                let prof_resources_conf = [];
                                for (const [prof_res_index, prof_res_name] of Object.entries(profession_list_item.prof_resources)) {
                                    let resource_to_find = player_inventory['resources'].find(res_inv => res_inv.item_id == prof_res_name);
                                    if(!resource_to_find) { resource_to_find = player_inventory['resources'][0]; }
                                    
                                    prof_resources_conf.push(resource_to_find);
                                }
                                
//                                FORMULAS, BOOSTERS, ITEMS
                                let prof_extensions = {
                                    Formulas: {},
                                    Items: {},
                                    Boosters: {}
                                };
//                                TODO WHAT TO GET, WHAT TO GIVE
                                let formula_count = 1;
                                let item_count = 1;
                                let booster_count = 1;
                                
                                for (const [prof_formula_index, prof_formula_id] of Object.entries(profession_list_item.prof_extensions.Formulas)) {
                                    let formula_to_find = player_inventory['prof_formulas'].find(formula_inv => formula_inv.item_id == prof_formula_id);
                                    if(!formula_to_find) { formula_to_find = player_inventory['prof_formulas'][0]; }
                                    
//                                    prof_extensions.Formulas[prof_formula_index] = { ...formula_to_find };
                                    
                                    let new_formula = { 
                                        ...formula_to_find,
                                        ...{"tier": formula_count}
                                    };
                                    formula_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_formula.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_formula.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { 
                                                ...this.find_in_inventory_glossary(resource_id, $store)
                                            };

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_formula.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_formula.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};
                                                
                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };
                                        
                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_formula.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Formulas[prof_formula_index] = new_formula;
                                    
                                }
                                
                                for (const [prof_item_index, prof_item_id] of Object.entries(profession_list_item.prof_extensions.Items)) {
                                    let item_to_find = player_inventory['prof_items'].find(item_inv => item_inv.item_id == prof_item_id);
                                    if(!item_to_find) { item_to_find = player_inventory['prof_items'][0]; }
                                    
//                                    prof_extensions.Items[prof_item_index] = { ...item_to_find };

                                    let new_item = { 
                                        ...item_to_find,
                                        ...{"tier": item_count}
                                    };
                                    item_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_item.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_item.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_item.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_item.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};
                                                
                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };
                                        
                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_item.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Items[prof_item_index] = new_item;
                                }
                                
                                for (const [prof_booster_index, prof_booster_id] of Object.entries(profession_list_item.prof_extensions.Boosters)) {
                                    let booster_to_find = player_inventory['prof_boosters'].find(item_booster => item_booster.item_id == prof_booster_id);
                                    if(!booster_to_find) { booster_to_find = player_inventory['prof_boosters'][0]; }
                                    
//                                    prof_extensions.Boosters[prof_booster_index] = { ...booster_to_find };

                                    let new_booster = { 
                                        ...booster_to_find,
                                        ...{"tier": booster_count}
                                    };
                                    booster_count++;
                                    
                                    let new_what_to_get = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_booster.what_to_get)) {
                                        if(resource_id == "prof_exp"){
                                            new_booster.prof_exp_reward = resource_amount;
                                        } else {
                                            let what_to_get_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                            let what_to_get_new__res = {
                                                item_id: what_to_get_find__res.item_id,
                                                item_img: what_to_get_find__res.item_img,
                                                item_name: what_to_get_find__res.item_name,
                                                amount: resource_amount,
                                            };

                                            new_what_to_get.push(what_to_get_new__res);
                                        }
                                    }
                                    new_booster.what_to_get = new_what_to_get;
                                    
                                    let new_what_to_give = [];
                                    for (const [resource_id, resource_amount] of Object.entries(new_booster.what_to_give)) {
                                        let what_to_give_find__res = { ...this.find_in_inventory_glossary(resource_id, $store)};

                                        let what_to_give_new__res = {
                                            item_id: what_to_give_find__res.item_id,
                                            item_img: what_to_give_find__res.item_img,
                                            item_name: what_to_give_find__res.item_name,
                                            amount: resource_amount,
                                        };

                                        new_what_to_give.push(what_to_give_new__res);
                                    }
                                    new_booster.what_to_give = new_what_to_give;
                                    
                                    
                                    prof_extensions.Boosters[prof_booster_index] = new_booster;
                                }
//                                FORMULAS, BOOSTERS, ITEMS
                                
                                let conf = {
                                    "current_lvl": 1,
                                    "current_exp": 0,
                                    "current_lvl_min": 0,
                                    "current_lvl_max": 100,
                                    "lvl_progress_percentage": "0"
                                };

                                for (const [key_level, level_info] of Object.entries(profession_list_item.prof_levels)) {
                                    if(Number(conf.current_exp) >= Number(level_info.exp_min)) {
                                        conf.current_lvl = key_level;
                                        conf.current_lvl_max = level_info.exp_max;
                                        conf.current_lvl_min = level_info.exp_min;
                                    }
                                }

                                let percentage__to_lvl_up = Number(conf.current_lvl_max) - Number(conf.current_lvl_min);
                                let percentage__solved_to_lvl_up = Number(conf.current_exp) - Number(conf.current_lvl_min);

                                let percentage_to_lvl_up = (percentage__solved_to_lvl_up * 100) / percentage__to_lvl_up;
                                percentage_to_lvl_up = Math.floor(percentage_to_lvl_up);
                                conf.lvl_progress_percentage = percentage_to_lvl_up > 100 ? "100" : percentage_to_lvl_up + "%";
                                
                                
                                
//                                filter.push({
//                                    ...profession_list_item,
//                                    lvl_info: conf
//                                });
                                
                                let output = {
                                    ...profession_list_item,
                                    lvl_info: conf
                                };
                                
                                output.prof_resources = prof_resources_conf;
                                output.prof_extensions = prof_extensions;
                                
                                
                                filter.push(output);
                                
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return [];
                    }

                },
                
                get_player_current_profession: function ($store, cdata, is_loaded_global__profession_list, is_loaded_player_professions) {

                    if (is_loaded_global__profession_list === "fin" && is_loaded_player_professions === "fin")
                    {
                        let filter = {};
                            
                        let active_profession = $store.state.state_tables.state_game_stats.player_professions.find(prof => prof.is_active == "1");
                        if(!active_profession) {
                            active_profession = $store.state.state_tables.state_game_stats.player_professions[0];
                        }
                        
                        for (const [profession_list_index, profession_list_item] of Object.entries($store.state['state_tables'].state_game_settings.global__profession_list.profession_list)){
                            if(profession_list_item.prof_id == active_profession.prof_id) {
                                
                                
    let conf = {
        "current_lvl": 1,
        "current_exp": 0,
        "current_lvl_min": 0,
        "current_lvl_max": 100,
        "lvl_progress_percentage": "0"
    };

    conf.current_exp = active_profession.prof_exp;

    for (const [key_level, level_info] of Object.entries(profession_list_item.prof_levels)) {
        if(Number(conf.current_exp) >= Number(level_info.exp_min)) {
            conf.current_lvl = key_level;
            conf.current_lvl_max = level_info.exp_max;
            conf.current_lvl_min = level_info.exp_min;
        }
    }

    let percentage__to_lvl_up = Number(conf.current_lvl_max) - Number(conf.current_lvl_min);
    let percentage__solved_to_lvl_up = Number(conf.current_exp) - Number(conf.current_lvl_min);

    let percentage_to_lvl_up = (percentage__solved_to_lvl_up * 100) / percentage__to_lvl_up;
    percentage_to_lvl_up = Math.floor(percentage_to_lvl_up);
    conf.lvl_progress_percentage = percentage_to_lvl_up > 100 ? "100%" : percentage_to_lvl_up + "%";
                                
                                
                                
                                filter = {
                                    ...profession_list_item,
                                    ...active_profession,
                                    lvl_info: conf
                                };
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return {};
                    }

                },
                
                find_in_inventory_glossary: function (what_to_find_id, $store) {
                        let inv_short_category = what_to_find_id.split('_');
                        let data_from_inv = $store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.resources[0];
                        
                        switch(inv_short_category[0]) {
                            case 'res':
                                for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.resources)) {
                                    if(item_data.item_id == what_to_find_id) {
                                        data_from_inv = { ...item_data};
                                        break;
                                    }
                                }
                                
                                break;
                            case 'item':
                                for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.items)) {
                                    if(item_data.item_id == what_to_find_id) {
                                        data_from_inv = { ...item_data};
                                        break;
                                    }
                                }
                                break;
                                
                            case 'prof':
                                    if(inv_short_category[2] == "formula") {
                                        for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas)) {
                                            if(item_data.item_id == what_to_find_id) {
                                                data_from_inv = { ...item_data};
                                                break;
                                            }
                                        }
                                    }
                                    else if(inv_short_category[2] == "item") {
                                        for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_items)) {
                                            if(item_data.item_id == what_to_find_id) {
                                                data_from_inv = { ...item_data};
                                                break;
                                            }
                                        }
                                    }
                                    else if(inv_short_category[2] == "booster") {
                                        for (const [item_index, item_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_boosters)) {
                                            if(item_data.item_id == what_to_find_id) {
                                                data_from_inv = { ...item_data};
                                                break;
                                            }
                                        }
                                    }    
                                break;
                        }
                                
                        if(data_from_inv) {
                            return data_from_inv;
                        } else {
                            return $store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.resources[0];
                        }
                        
                },
                
                find_in_player_inventory: function (what_to_find_id, category_name, player_inventory) {
                    if(what_to_find_id){
                        let data_from_inv = null;
                        
                        switch(category_name) {
                            case 'Boosters':
                                data_from_inv = player_inventory['prof_boosters'].find(inv_res => inv_res.item_id == what_to_find_id);
                                break;
                            case 'Items':
                                data_from_inv = player_inventory['prof_items'].find(inv_res => inv_res.item_id == what_to_find_id);
                                break;
                            case 'Formulas':
                                data_from_inv = player_inventory['prof_formulas'].find(inv_res => inv_res.item_id == what_to_find_id);
                                break;
                        }
                        console.log('inv');
                        console.log(what_to_find_id);
                        console.log(player_inventory['prof_items']);
                        console.log(player_inventory['prof_items'].find(inv_res => inv_res.item_id == what_to_find_id));
                                
                        if(data_from_inv) {
                            return data_from_inv;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
                
                sort_player_inventory: function ($store, cdata, is_loaded_player_inventory) {

                    if (is_loaded_player_inventory === "fin")
                    {
                        let pre_filter = {};
                        let filter = {};
                        
                        $store.state['state_tables'].state_game_stats.player_inventory.forEach(inventory_item => {
                            pre_filter[inventory_item['item_id']] = inventory_item;
                        });
                        
                        for (const [inventory_glossary_index, inventory_glossary_category] of Object.entries($store.state['state_tables'].state_game_settings.global__inventory_glossary.glossary)){
                            
                            for (const [inventory_index, inventory_item] of Object.entries(inventory_glossary_category)){
                                if(inventory_item.display_in_inv){
                                    if(!filter[inventory_glossary_index]) { filter[inventory_glossary_index] = []; }
                                    
                                    
                                    if(inventory_glossary_index != "prof_boosters"){
                                        if(pre_filter[inventory_item.item_id]) {
                                            filter[inventory_glossary_index].push({
                                                    ...inventory_item,
                                                    ...pre_filter[inventory_item.item_id]
                                                });
                                        } else {
                                            let params_for_inventory = {
                                                item_count: "0"
                                            };
                                            filter[inventory_glossary_index].push({
                                                    ...params_for_inventory,
                                                    ...inventory_item
                                                });
                                        }
                                    } else {
                                        if(pre_filter[inventory_item.item_id]) {
                                            let inventory_item_copy = {...pre_filter[inventory_item.item_id]};
                                            inventory_item_copy.item_count = (Number(inventory_item_copy.item_count) - 1).toString();
                                            filter[inventory_glossary_index].push({
                                                    ...inventory_item,
                                                    ...inventory_item_copy
                                                });
                                        } else {
                                            let params_for_inventory = {
                                                item_count: "-1"
                                            };
                                            filter[inventory_glossary_index].push({
                                                    ...params_for_inventory,
                                                    ...inventory_item
                                                });
                                        }
                                    }
                                    
                                    
                                    
                                }
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return {};
                    }

                },
                
                
                add_joker_to_state_desks_by_lvl: function ($store, cdata, level_props__joker_by_tasks) {

                    if (level_props__joker_by_tasks === "fin")
                    {
                        let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                        let lid = $store.state.state_tables.state_auth.r_auth['lid'];

                        let filter = {};
                        if (window._OBJ().is_not_empty($store.state['state_tables'].state_game_settings))
                        {
                            
                            for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_game_settings.level_props__joker_by_tasks.layouts))
                            {
                                if (class_.toString() === "class_" + classnum)
                                {
                                        for (const [key, value] of Object.entries($store.state['state_tables'].state_game_settings.level_props__joker_by_tasks.layouts["class_" + classnum].layout))
                                        {
    
                                            if (key.toString() === lid.toString())
                                            {
                                                for (const [index, value2] of Object.entries($store.state['state_tables'].state_game_settings.level_props__joker_by_tasks.layouts["class_" + classnum].layout[key])) {
                                                    filter['json_' + value2['desk_id']] = value2;
                                                }
                                            }

                                        }
                                }
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return {};
                    }

                },
                
                
                add_gold_to_state_desks_by_lvl: function ($store, cdata, level_props__gold_by_tasks) {

                    if (level_props__gold_by_tasks === "fin")
                    {
                        let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                        let lid = $store.state.state_tables.state_auth.r_auth['lid'];

                        let filter = {};
                        if (window._OBJ().is_not_empty($store.state['state_tables'].state_game_settings))
                        {
                            
                            for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_game_settings.level_props__gold_by_tasks.layouts))
                            {
                                if (class_.toString() === "class_" + classnum)
                                {
                                        for (const [key, value] of Object.entries($store.state['state_tables'].state_game_settings.level_props__gold_by_tasks.layouts["class_" + classnum].layout))
                                        {
    
                                            if (key.toString() === lid.toString())
                                            {
                                                for (const [index, value2] of Object.entries($store.state['state_tables'].state_game_settings.level_props__gold_by_tasks.layouts["class_" + classnum].layout[key])) {
                                                    filter['json_' + value2['desk_id']] = value2;
                                                }
                                            }

                                        }
                                }
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return {};
                    }

                },
                
                add_exp_to_state_desks_by_lvl: function ($store, cdata, level_props__exp_by_tasks) {

                    if (level_props__exp_by_tasks === "fin")
                    {
                        let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                        let lid = $store.state.state_tables.state_auth.r_auth['lid'];

                        let filter = {};
                        if (window._OBJ().is_not_empty($store.state['state_tables'].state_game_settings))
                        {
                            
                            for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_game_settings.level_props__exp_by_tasks.layouts))
                            {
                                if (class_.toString() === "class_" + classnum)
                                {
                                        for (const [key, value] of Object.entries($store.state['state_tables'].state_game_settings.level_props__exp_by_tasks.layouts["class_" + classnum].layout))
                                        {
    
                                            if (key.toString() === lid.toString())
                                            {
                                                for (const [index, value2] of Object.entries($store.state['state_tables'].state_game_settings.level_props__exp_by_tasks.layouts["class_" + classnum].layout[key])) {
                                                    filter['json_' + value2['desk_id']] = value2;
                                                    filter['json_' + value2['desk_id']]['multiplier_value'] = $store.state['state_tables'].state_game_settings.level_props__exp_by_tasks.layouts["class_" + classnum].multiplier_value;
                                                }
                                            }

                                        }
                                }
                            }
                        }
                        
                        return filter;
                    } else
                    {
                        return {};
                    }

                },
                
                
                add_limit_to_state_desks_limit: function ($store, cdata, level_props__solve_limit) {
                    
                    
//                    Math.floor(new Date().getTime() / 1000.0);
//                    shift['tsh_close'] = new Date(shift.close_timestamp_MSK).getTime() / 1000;

                    if (level_props__solve_limit === "fin")
                    {
                        let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                        let lid = $store.state.state_tables.state_auth.r_auth['lid'];

                        let filter = {};
                        if (window._OBJ().is_not_empty($store.state['state_tables'].state_game_settings))
                        {
//                            USE MOMENT.JS
                            let solved_by_level = {};
//                            let currentData = {
//                                day: new Date().getDate(),
//                                month: new Date().getMonth() + 1,
//                                year: new Date().getFullYear()
//                            };
                            
                            let currentData = {
                                day: moment.tz(moment(), 'Europe/Moscow').format('DD'),
                                month: moment.tz(moment(), 'Europe/Moscow').format('MM'),
                                year: moment.tz(moment(), 'Europe/Moscow').format('YYYY')
                            };
                            
                            
                            let admin_controls__daily_limit__local = localStorage.getItem("daily_limit__admin_controls");

                            
                            for (const [key, solved_card] of Object.entries($store.state.state_tables.state_st.st_cards)) {
                                if(solved_card.is_solved == 1 && solved_card['lid'] == lid && 
                                   new Date(solved_card.date_time).getDate() == currentData.day &&
                                   new Date(solved_card.date_time).getMonth() + 1 == currentData.month &&
                                   new Date(solved_card.date_time).getFullYear() == currentData.year
                                   ) {
                                    let card_lvl = solved_card.pos;
                                    
                                    if(window._OBJ().is_empty(solved_by_level[card_lvl])){
                                        solved_by_level[card_lvl] = 0;
                                    }
                                    solved_by_level[card_lvl]++;
                                }
                            }
                            
                            for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_game_settings.level_props__solve_limit.layouts))
                            {
                                if (class_.toString() === "class_" + classnum)
                                {
                                        for (const [key, value] of Object.entries($store.state['state_tables'].state_game_settings.level_props__solve_limit.layouts["class_" + classnum]))
                                        {
    
                                            if (key.toString() === lid.toString())
                                            {
//                                                filter = value;
                                                for (const [index, value2] of Object.entries($store.state['state_tables'].state_game_settings.level_props__solve_limit.layouts["class_" + classnum][key])) {
                                                    filter['json_' + value2['desk_id']] = value2;
                                                    filter['json_' + value2['desk_id']].block_by_limit = false;
                                                    
                                                    
                                                    let current_lvl = window._STR().pos00('json_' + value2['desk_id']);
                                                    
                                                    filter['json_' + value2['desk_id']].solved_count = solved_by_level[current_lvl] ? solved_by_level[current_lvl] : 0;
                                                    
                                                    let percent_limit = (filter['json_' + value2['desk_id']].solved_count * 100) / filter['json_' + value2['desk_id']].correct_task_limit_per_day;
                                                    percent_limit = 100 - percent_limit;
                                                    
                                                    filter['json_' + value2['desk_id']].percent_limit = percent_limit + '%';
//                                                    if (percent_limit <= 100 && percent_limit > 75) { filter['json_' + value2['desk_id']].percent_limit = "100%"; }
//                                                    if (percent_limit <= 75 && percent_limit > 50)  { filter['json_' + value2['desk_id']].percent_limit = "75%"; }
//                                                    if (percent_limit <= 50 && percent_limit > 25)  { filter['json_' + value2['desk_id']].percent_limit = "50%"; }
//                                                    if (percent_limit <= 25 && percent_limit > 0)  { filter['json_' + value2['desk_id']].percent_limit = "25%"; }
                                                    
                                                    if(window._OBJ().is_not_empty(solved_by_level[current_lvl]) && solved_by_level[current_lvl] >= value2.correct_task_limit_per_day){
                                                        filter['json_' + value2['desk_id']].block_by_limit = true;
                                                    }
                                                    
//                                                    admin_controls__daily_limit__local 
                                                    if(admin_controls__daily_limit__local == "true"){
                                                        filter['json_' + value2['desk_id']].block_by_limit = false;
                                                    }
//                                                    admin_controls__daily_limit__local
                                                }
                                            }

                                        }
                                }
                            }
                            filter['solved_by_level'] = solved_by_level;
                        }
                        
                        return filter;
                        
//                        return {'test': 'done'};
                    } else
                    {
                        return {};
                    }

                },

                add_desks_to_state_desks: function ($store, cdata, dynamic_desks_ready) {

                    if (dynamic_desks_ready === "fin")
                    {

                        let tables_filter = self.$root._LAYOUT().get_actual_layuot_filter($store, cdata);
                        let state_desks = {};
                        for (const [desk_name, desk_value] of Object.entries($store.state['state_tables'].state_desks)) {
                            for (const [filter_key, filter_value] of Object.entries(tables_filter)) {
                                if (desk_name.toString() === filter_value['table_name'].toString())
                                {
                                    state_desks[desk_name] = $store.state['state_tables'].state_desks[desk_name];
                                    
                                    let needed_to_learn_full_layout = 0;
                                    let solved_to_learn_full_layout = 0;
                                    
                                    let solved_tasks = 0;
                                    
                                    needed_to_learn_full_layout = Object.keys(state_desks[desk_name].theme_list).length;
                                    
                                    for (const [key, layout_theme] of Object.entries(state_desks[desk_name].theme_list)) {
                                         
                                        let rg_learning_theme = $store.state['state_tables'].state_rg.rg_learning.find(o => o.theme_id == layout_theme.theme_id);
                                        if(rg_learning_theme && (Number(rg_learning_theme.theme_learning_task_count) >= Number(layout_theme.theme_learning_task_limit))) {
                                            solved_to_learn_full_layout += 1;
                                        }
                                        
                                        if(rg_learning_theme){
                                            solved_tasks += Number(rg_learning_theme.theme_learning_task_count);
                                        }
                                    }
                                    
                                    let solved_cards = 0;
                                    let lid = $store.state.state_tables.state_auth.r_auth['lid'];
                                    let current_lvl = window._STR().pos00(desk_name.toString());
                                    
                                    for (const [key, solved_card] of Object.entries($store.state.state_tables.state_st.st_cards)) {
                                        if(solved_card.is_solved == 1 && solved_card['lid'] == lid && 
                                           current_lvl == solved_card['pos']
                                           ) {
                                            solved_cards++;
                                        }
                                    }
                                    
                                    
                                    
                                    
                                    
                                    state_desks[desk_name].needed_to_learn_full_layout = needed_to_learn_full_layout;
                                    state_desks[desk_name].solved_to_learn_full_layout = solved_to_learn_full_layout;
                                    
                                    state_desks[desk_name].solved_tasks = solved_tasks;
                                    
                                    state_desks[desk_name].solved_cards = solved_cards;
                                    
                                    break;
                                }

                            }
                        }
                        return state_desks;
                    } else
                    {
                        return {};
                    }

                },
                /*
                 get_desk_version_by_desk_table_name: function ($store, cdata, table_name) {
                 
                 let version = -1;
                 let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                 let lid = $store.state.state_tables.state_auth.r_auth['lid'];
                 //console.log("state_layouts");
                 //console.log($store.state['state_tables'].state_layouts);
                 if (window._OBJ().is_not_empty($store.state['state_tables'].state_layouts))
                 {
                 //console.log("RUNNNNNN!!!!");
                 
                 for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts))
                 {
                 if (class_.toString() === "class_" + classnum)
                 {
                 
                 for (const [key, value] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum]))
                 {
                 //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['lid']+"="+lid);
                 
                 if ($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['lid'].toString() === lid.toString())
                 {
                 //console.log("HERE!");
                 //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links']);
                 //соберем все имена фпйлов с задачами (desk_id)
                 for (const [json_links_key, json_links_value] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'])) {
                 
                 //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id']+"|=?|"+table_name);
                 
                 
                 if ("json_" + $store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id'] === table_name)
                 {
                 //console.log(table_name+"|YES");
                 
                 version = $store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['version_timestamp'];
                 //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id']);
                 //console.log(table_name+"|ver="+version);
                 return version;
                 }
                 
                 
                 }
                 
                 }
                 
                 }
                 }
                 
                 }
                 }
                 return version;
                 
                 },
                 */
                is_loaded_dynamic_tables_by_layuot_filter: function ($store, cdata, dynamic_desks_startload) {

                    if (dynamic_desks_startload === "fin")
                    {
                        let tables_filter = self.$root._LAYOUT().get_actual_layuot_filter($store, cdata);
                        //console.log(tables_filter);
                        let is_not_loaded_exist = false;
                        for (const [table_id, table_value] of Object.entries(tables_filter)) {
                            /* console.log($store.state.is_loaded_tables);
                             console.log(table_value['table_name']);
                             console.log($store.state.is_loaded_tables[table_value['table_name']]);
                             console.log($store.state.is_loaded_tables[table_value['table_name']].load_status);*/
                            if (!$store.state.is_loaded_tables[table_value['table_name']].load_status)
                            {
                                is_not_loaded_exist = true;
                            }

                            // console.log("is_loaded_by_layuot_filter "+table_name+" is_loaded="+$store.state.is_loaded_tables[table_name].load_status); 

                        }

                        if (is_not_loaded_exist)
                        {
                            return false; //есть хотя бы одна незагруженная динамическая таблицы
                        } else
                        {
                            return true; //все динамические таблицы загрузились 
                        }


                    } else
                    {
                        //динамические таблицы даже не начинали загружаться
                        return false;
                    }
                },
                get_actual_layuot_filter: function ($store, cdata) {

                    let classnum = $store.state.state_tables.state_auth.r_auth['classnum'];
                    let lid = $store.state.state_tables.state_auth.r_auth['lid'];
                    //console.log(classnum+"|"+lid);

                    let filter = {};
                    //console.log("state_layouts");
                    //console.log($store.state['state_tables'].state_layouts);
                    if (window._OBJ().is_not_empty($store.state['state_tables'].state_layouts))
                    {
                        //console.log("RUNNNNNN!!!!");

                        for (const [class_, layouts_in_class] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts))
                        {
                            if (class_.toString() === "class_" + classnum)
                            {

                                for (const [key, value] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum]))
                                {
                                    //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['lid']+"="+lid);

                                    if ($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['lid'].toString() === lid.toString())
                                    {
                                        //console.log("HERE!");
                                        //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links']);
                                        //соберем все имена фпйлов с задачами (desk_id)
                                        for (const [json_links_key, json_links_value] of Object.entries($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'])) {

                                            //console.log($store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id']);

                                            //filter[json_links_key]="json_" + $store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id'];

                                            filter[json_links_key] = {};
                                            filter[json_links_key]['table_name'] = "json_" + $store.state['state_tables'].state_layouts.json_layouts.layouts["class_" + classnum][key]['json_links'][json_links_key]['desk_id'];
                                            filter[json_links_key]['table_version'] = json_links_value['version_timestamp'];
                                        }
                                        break;
                                    }

                                }
                            }

                        }
                    }
                    return filter;
                },

              
                find_next_layuot_reason_learning: function ($store, cdata) {

                    //     $store.state.state_tables.state_layouts.json_layouts.version_timestamp
                    let curr_class_name = "class_" + $store.state.state_tables.state_auth.r_auth['classnum'];
                    let curr_class = parseInt($store.state.state_tables.state_auth.r_auth['classnum'], 10);
                    let curr_lid = parseInt($store.state.state_tables.state_auth.r_auth['lid'], 10);
                    let next_lid = curr_lid + 1;
                    //пробегаем ищем next_lid  и берем новый класс (может остаться тот же...
                    let next_class = -1;
                    console.log("curr_class="+curr_class+",curr_lid="+curr_lid+",next_lid="+next_lid);


                    
                    for (const [key_class_x, value] of Object.entries($store.state.state_tables.state_layouts.json_layouts.layouts)) {
                        console.log("key_class_x="+key_class_x); 
 
                  
                            
                            
                            for (const [layouts_key, layouts_value] of Object.entries($store.state.state_tables.state_layouts.json_layouts.layouts[key_class_x])) {
                              console.log("layouts_key="+layouts_key+"=?="+next_lid); 
                                
                                if (layouts_key.toString()===next_lid.toString()){
                                    console.log("Выбран layout: " + layouts_key); 
                                    next_class =  window._STR().ltrim(key_class_x,"class_");  
                                    
                                }
                            }
                      



                    }
                    
                    let result={};
                    if (next_class!==-1){
                              result['is_gameover']=false;
                              result['new_classnum']=next_class;
                              result['new_lid']=next_lid;
                    }
                    else
                    {
                        result['is_gameover']=true; 
                        result['curr_classnum']=curr_class;
                        result['curr_lid']=curr_lid;
                    }
                     return result;
                },

                find_start_layuot_by_class: function ($store, cdata) {

                    //     $store.state.state_tables.state_layouts.json_layouts.version_timestamp
                    let curr_class = "class_" + $store.state.state_tables.state_auth.r_auth['classnum'];
                    for (const [key, value] of Object.entries($store.state.state_tables.state_layouts.json_layouts.layouts)) {
                        //console.log(key); 
                        if (key === curr_class)
                        {
                            console.log("Для старта игры выбран класс: " + key + ", лэйаут ???"); //В КАЖДОМ классе?

                            for (const [layouts_key, layouts_value] of Object.entries($store.state.state_tables.state_layouts.json_layouts.layouts[key])) {
                                //возьмем тупо первый....
                                console.log("Выбран layout: " + layouts_key);
                                return layouts_key;
                            }



                        }
                    }
                    console.log("Ошибка, не найден класс " + curr_class + " в layouts.json или в классе нет колод");
                    return -1;
                },
            }
        },
        _ADMIN: function () {
            var self = this;
            return {
                is_admin_active: function() {
//                    if(all fin)
                    let local_value = localStorage.getItem("admin_controls");
                    if(local_value) { return true; }
                    else { return false; }
                },
                show_admin_controls: function() {
                    localStorage.setItem("admin_controls", "true");
                    return true;
                },
                hide_admin_controls: function() {
                    localStorage.removeItem("admin_controls");
                    
                    localStorage.removeItem("right_answers__admin_controls");
                    localStorage.removeItem("daily_limit__admin_controls");
                    localStorage.removeItem("solved_info__admin_controls");
                    return false;
                },
                
                get_right_answers__admin_controls: function() {
                    let local_value = localStorage.getItem("right_answers__admin_controls");
                    if(local_value == "true") { return true; }
                    else { return false; }
                },
                get_daily_limit__admin_controls: function() {
                    let local_value = localStorage.getItem("daily_limit__admin_controls");
                    if(local_value == "true") { return true; }
                    else { return false; }
                },
                
                get_solved_info__admin_controls: function() {
                    let local_value = localStorage.getItem("solved_info__admin_controls");
                    if(local_value == "true") { return true; }
                    else { return false; }
                },
                
                toggle_solved_info__admin_controls: function(value) {
                    localStorage.setItem("solved_info__admin_controls", (value).toString());
                    window.location.reload();
                },
                
                toggle_right_answers__admin_controls: function(value) {
                    localStorage.setItem("right_answers__admin_controls", (value).toString());
                },
                toggle_daily_limit__admin_controls: function(value) {
                    localStorage.setItem("daily_limit__admin_controls", (value).toString());
                    window.location.reload();
                },
                
                
            }
        },
        _AUTH: function () {
            var self = this;
            return {

                _insert_new_kid: function (cdata, r_auth) {
                    //Если все впорядке на сервере авторизации - то делаем всавку в локальную базу... 
                    //сохраняем в localstorage.... строку авторизации и в игру
                    //
                    var params = {
                        method: "add_new_auth",
                        data: {
                            rid: r_auth['rid'],
                            gid: r_auth['gid'],
                            classnum: r_auth['classnum'],
                            pid: r_auth['pid'],
                            kid: r_auth['kid'],
                            name: r_auth['name'],
                            gender: r_auth['gender'],
                            utm_data: r_auth['utm_data'],
                        }
                    };
                    axios.post(self.$root.cnf['server_action'], params)
                            .then(response =>
                            {
                                console.log(response['data']);
                                switch (response['data']['answer_code'])
                                {
                                    case "READY":
                                        //response['data']['data']['r_auth'];
                                        self.$root._AUTH()._ls_set_rid(r_auth['rid']);
                                        
//                                         || !r_auth['pid']
                                        if(r_auth['classnum'] == null){
//                                            self.$router.push('/g1/setup/' + '?t=' + self._SYS().add_v());
                                            window.location.href = "/g1/setup/";
                                        } else {
                                            self.$router.push('/g1/desksweek/' + '?t=' + self._SYS().add_v());
//                                            self.$router.push('/g1/first/');
                                        }
                                        
                                        break;
                                    default:
                                        //какая-то ошибка в API - не вернул обрабатываемый ответ
                                        cdata['flags']['is_error']['api'] = true;
                                        break;
                                }
                                //разрешаем кликать на кнопку для дейтсвия 
                                cdata['flags']['is_act_enabled'] = true;
                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                cdata['flags']['is_error']['sending'] = true;
                                cdata['flags']['is_act_enabled'] = true;
                            }).then(function () { });
                },
                _auth_api_check_id: function (cdata) {


                    const config = {}

                    axios.get(self.$root.cnf['server_auth'] + "/child/child_validation?_id=" + cdata['_id'], config)
                            .then(response =>
                            {

                                console.log(response['data']);
                                if (response['data']['err'].toString() === "false")
                                {
                                    let r_auth = {};
                                    r_auth['rid'] = response['data']['child']['_id'];
                                    r_auth['gid'] = response['data']['child']['gid'];
                                    r_auth['classnum'] = response['data']['child']['classnum'];
                                    r_auth['pid'] = response['data']['child']['pid'];
                                    r_auth['kid'] = response['data']['child']['kid'];
                                    r_auth['name'] = response['data']['child']['username']; 
                                    r_auth['utm_data'] = response['data']['child']['utm_data']; 
                                    //USERNAME
                                    r_auth['gender'] = response['data']['child']['gender'];
                                    //Если все впорядке на сервере авторизации - то делаем всавку в локальную базу... 
                                    //сохраняем в localstorage.... строку авторизации и в игру
                                    self._AUTH()._insert_new_kid(cdata, r_auth);
                                } else
                                {
                                    console.log("Возвращена ошибка на сервере авторизации eo.events");
                                    cdata['flags']['is_error']['sending'] = true;
                                }




                            })
                            .catch(error => {
                                console.log("Ошибка при выполнении запроса на сервер авторизации eo.events");
                                console.log(error);
                                //Флаг ошибки отправки данных
                                cdata['flags']['is_error']['sending'] = true;
                            }).then(function () { });
                },
                _check_auth: function (cdata) {


                    //если есть в локальной базе.... то 
                    //сохраняем в localstorage.... строку авторизации и в игру
                    //
                    //если нету... то делаем запрос к Диме и если там все окей.... то добавляем в локальную базу данных
                    ////
                    //сохраняем в localstorage.... строку авторизации и в игру
                    //
                    
                    if(localStorage.getItem("rid") != cdata['_id']){
                        api_idb.deleteAllTables();
                        
                        localStorage.removeItem('intro_modal');
                        
                        self._ADMIN().hide_admin_controls();
//                        localStorage.removeItem('admin_controls');
//                        localStorage.removeItem('right_answers__admin_controls');
//                        localStorage.removeItem('daily_limit__admin_controls');
                    }
                    
                    var params = {
                        method: "get_r_auth",
                        data: {
                            rid: cdata['_id']
                        }
                    };
                    axios.post(self.$root.cnf['server_action'], params)
                            .then(response =>
                            {
                                console.log(response['data']);
                                switch (response['data']['answer_code'])
                                {
                                    case "READY":

                                        // response['data']['data']['r_auth'];
                                        //сохраняем в localstorage.... строку авторизации и в игру 
                                        console.log(response['data']['data']['table_data']['r_auth']);
                                        self.$root._AUTH()._ls_set_rid(response['data']['data']['table_data']['r_auth']['rid']);
                                        
//                                         || !response['data']['data']['table_data']['r_auth']['pid']
                                        if(!response['data']['data']['table_data']['r_auth']['classnum'] == null){
//                                            self.$router.push('/g1/setup/' + '?t=' + self._SYS().add_v());
                                            window.location.href = "/g1/setup/";
                                        } else {
                                            self.$router.push('/g1/desksweek/' + '?t=' + self._SYS().add_v());
//                                            self.$router.push('/g1/first/' + '?t=' + self._SYS().add_v());
                                        }
                                        
                                        break;
                                    case "NOTFIND":
                                        console.log("NOTFIND");
                                        //если нету... то делаем запрос к Диме и если там все окей.... то добавляем в локальную базу данных
                                        self.$root._AUTH()._auth_api_check_id(cdata);
                                        //сохраняем в localstorage.... строку авторизации и в игру



                                        break;
                                    default:
                                        //какая-то ошибка в API - не вернул обрабатываемый ответ
                                        cdata['flags']['is_error']['api'] = true;
                                        break;
                                }
                                //разрешаем кликать на кнопку для дейтсвия 
                                cdata['flags']['is_act_enabled'] = true;
                            })
                            .catch(error => {
                                console.log(error);
                                //Флаг ошибки отправки данных
                                cdata['flags']['is_error']['sending'] = true;
                                cdata['flags']['is_act_enabled'] = true;
                            }).then(function () { });
                },
                _ls_set_rid: function (rid) {
                    console.log(rid);
                    /*  r_auth['rid'] = response['rid'];
                     r_auth['gid'] = response['gid'];
                     r_auth['classnum'] = response['classnum'];
                     r_auth['pid'] = response['pid'];
                     r_auth['kid'] = response['kid'];
                     r_auth['name'] = response['name'];
                     r_auth['gender'] = response['gender'];*/

                    let crid = window._CRYPT().encrypt_json(rid);
                    localStorage.setItem("rid", crid); //r_auth

                },
                is_authed: function () {

                    let crid = localStorage.getItem("rid"); //r_auth

                    if (window._STR().is_empty(crid))
                    {
                        self.$root._AUTH().logout();
                    } else
                    {   //авторизован, есть crid в localStorage.getItem("rid")
                        return true;
                    }

                    return false;
                },
                do_auth: function (cdata) {
                    //_id
                    
                    
                    var url = new URL(window.location.href);
                    if (window._STR().is_not_empty(url.searchParams.get("_id")))
                    {
                        cdata['_id'] = url.searchParams.get("_id");
                        //Данные подкачаем в игре
                        //....

                        //TODO проверить токен.... и в игру
                        self.$root._AUTH()._check_auth(cdata);
                    } else
                    {
                        //AUTH FAIL - ПУСТОЙ cdata['_id'] - идентификатор ребенка
                        console.log("AUTH FAIL - ПУСТОЙ cdata['_id'] - идентификатор ребенка");
                        self.$router.push('/error/error_auth');
                    }






                },
                
                to_parent_profile: function() {
//                    console.log(cnf['link_to_auth_dashboard']);
                    window.location.href = cnf['link_to_auth_dashboard'] + '/login';
                },
                //НЕИСПОЛЬЗУЕТСЯ.... НЕ РАЗЛОГИНИВАЕМСЯ ВООБЩЕ!!!!
                logout: function () {
                    //console.log("DO_LOGOUT");
                    api_idb.deleteAllTables();
                    localStorage.removeItem('rid');
                    localStorage.removeItem('intro_modal');
                    localStorage.removeItem('admin_controls');
                    localStorage.removeItem('right_answers__admin_controls');
                    localStorage.removeItem('daily_limit__admin_controls');
                    
//                    self.$router.push('/');
                    window.location.href = cnf['link_to_auth_dashboard'] + '/login';
                },
                _onstorage: function (event) {
                    //РАБОТАЕТ КОГДА БОЛЕЕ 1-й ВКЛАДКИ!!! Если вкладка одна - ничего не происходит!!!!
                    console.log("LOCALSTORAGE "+event.key + ':' + event.oldValue+ '/' + event.newValue + " at " + event.url);
                    switch (event.key)
                    {
                        case 'rid':
                            //Если токен стерли - то произошел разлогин!
                            if (event.newValue === null || event.newValue === "")
                            {
                                console.log("DO REDIRECT TO MAIN");
                                self.$router.push('/');
                                //                            если это произошло в зоне lkteacher или lkparent то только перезагрузка страницы
//                                        if (!(window.location.pathname.toLowerCase().indexOf("action") > -1  window.location.pathname.toLowerCase().indexOf("lkchild") > -1 || window.location.pathname.toLowerCase() === "/landing/dummy"))
//                                        {window.location.reload();  } 
                            }

                            break;


                        //редирект на сброс во всех вкладках.
                        case 'last_reset_timestamp':
                            console.log("LOCALSTORAGE "+event.newValue+"= new|old ="+event.oldValue); 
                            if (event.newValue !== null && event.newValue !== "")
                            {
                                if (event.newValue.toString() !== event.oldValue.toString()) {
                                    console.log("LOCALSTORAGE "+"DO RESETT");
                                    
                                    self.$router.push('/g1/reset/' + '?t=' + self._SYS().add_v());
                                   // self._PAGE().move_to(this.$store, this.cdata, '/g1/reset/');
                                }
                            }

                            break;

                    }

                },
                redirect_to_login: function () {

                    var url = new URL(window.location.href);
                    let _id = url.searchParams.get("_id");
                    if (window._STR().is_not_empty(_id))
                    {
                        self.$router.push('/login/?_id=' + _id);
                    }

                },
            }
        },

        _TIMINGS: function () {
            var self = this;
            return {

                set_curr_event: function ($store, cdata) {

                    //проверить таймеры ... events_list

                    let is_event_game = false;
                    for (const [key_event, event] of Object.entries($store.state.state_tables.state_manage.events_list)) {
                        //console.log(event.start_timestamp_MSK + "|" + event.name);
                        event['ts_start'] = new Date(event.start_timestamp_MSK).getTime() / 1000;
                        event['ts_close'] = new Date(event.close_timestamp_MSK).getTime() / 1000;
                        //console.log(event['ts_start'] + " < " + cdata.system.curr_event.event_timings.curr_timestamp + " < " + event['ts_close']);
                        if (
                                event['ts_start'] < cdata.system.curr_event.event_timings.curr_timestamp &&
                                cdata.system.curr_event.event_timings.curr_timestamp < event['ts_close']
                                )
                        {

                            is_event_game = true;
                            cdata.system.curr_event['event'] = event;
                            break;
                        }
                    }

                    if (!is_event_game)
                    {
                        cdata.system.curr_event['event'] = {};
                        cdata.system.curr_event['event']['game_type'] = "DEFAULT";
                        cdata.system.curr_event['event']['name'] = "common_game";
                        cdata.system.curr_event['event']['id'] = "";
                    }

                },

                set_stage_flags: function ($store, cdata) {
                    //rldata, delta_s, gid


                    let event = cdata.system.curr_event['event'];
                    let game_type = cdata.system.curr_event['event']['game_type'];


                    let curr_timestamp = $store.state.curr_event.event_timings.curr_timestamp;


                    cdata.system.curr_event._game_type = cdata.system.curr_event['event'].game_type;
                    cdata.system.curr_event._name = cdata.system.curr_event['event'].name;
                    cdata.system.curr_event._id = cdata.system.curr_event['event'].id;

                    switch (game_type) {
                        case "DEFAULT":
                            cdata.system.curr_event.event_timings.game_types[game_type] = {};
                            break;

                        case "TYPE1":


                            cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_fin'] = parseInt(event['ts_close']);
                            cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_middle'] = parseInt(event['ts_start']) + (2 * 60);
                            cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_start'] = parseInt(event['ts_start']);

                            cdata.system.curr_event.event_timings.game_types[game_type].until_stages_end['rtm_duration_before_fin'] = parseInt(cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_fin']) - curr_timestamp;
                            cdata.system.curr_event.event_timings.game_types[game_type].until_stages_end['rtm_duration_before_middle'] = parseInt(cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_middle']) - curr_timestamp;
                            cdata.system.curr_event.event_timings.game_types[game_type].until_stages_end['rtm_duration_before_start'] = parseInt(cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_start']) - curr_timestamp;

                            //FIN STAGE
                            if (curr_timestamp > cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_fin'])
                            {
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_fin'] = true;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_middle'] = false;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_start'] = false;
                                return;
                            }

                            //MIDDLE STAGE
                            if (
                                    curr_timestamp > cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_middle'] &&
                                    curr_timestamp <= cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_fin']
                                    )
                            {
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_fin'] = false;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_middle'] = true;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_start'] = false;
                                return;
                            }



                            //START STAGE
                            if (
                                    curr_timestamp > cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_start'] &&
                                    curr_timestamp <= cdata.system.curr_event.event_timings.game_types[game_type].stages_start['timestamp_stage_middle']
                                    )
                            {
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_fin'] = false;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_middle'] = false;
                                cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_start'] = true;
                                return;
                            }

                            //OTHERCASE - ERROR
                            cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_fin'] = false;
                            cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_middle'] = false;
                            cdata.system.curr_event.event_timings.game_types[game_type].stages['is_stage_start'] = false;

                            break;

                    }




                },

            }
        },

        _PAGE: function () {
            var self = this;
            return {

                move_to: function ($store, cdata, new_route) {

                    let curr_route = new_route.replace(/\//g, '_');
                    curr_route = window._STR().trim(curr_route, "_"); 
                    
                    if (cdata.system._route_ !== curr_route) {
                        console.log('move to flag_error');
                        console.log(cdata.system)
                        
                        if(cdata.system._route_){
                            $store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': false, '_route_': cdata.system._route_ });
                            $store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': false, '_route_': cdata.system._route_ });
                        }


                        self.$router.push(new_route + '?t=' + self.$root._SYS().add_v());
                    }
                    else
                    {
                        console.log("same way: "+curr_route+"=?="+cdata.system._route_);
                    }

                },
                
                on_mounted: function ($store, cdata) {
                    //ОБНУЛИМ ФЛАЖКИ на MOUNTED  



                    for (const key of Object.keys(cdata['system']['is_section'])) {
                        cdata['system']['is_section'][key] = false;
                    }
                    /*for (const key of Object.keys(cdata['system']['is_stage'])) {
                     cdata['system']['is_stage'][key] = false;
                     }*/

                    for (const key of Object.keys(cdata['system']['buttons'])) {
                        cdata['system']['buttons'][key]['is_button_blocked'] = true;
                    }
                    console.log('on_mounted flag_error');
//                    cdata['system']['is_stages_fin_for_this_page'] = false;
                    $store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': false, '_route_': cdata.system._route_ });
                    
                    $store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': false, '_route_': cdata.system._route_ });
                    cdata['system']['cnf'] = self.cnf;
                    // console.log("SET|stage_value=is_all_stage_fin="+cdata['system']['is_all_stage_fin']);

                },
                
                on_mounted__book: function ($store, cdata) {
                    //ОБНУЛИМ ФЛАЖКИ на MOUNTED  



                    for (const key of Object.keys(cdata['system']['is_section'])) {
                        cdata['system']['is_section'][key] = false;
                    }
                    /*for (const key of Object.keys(cdata['system']['is_stage'])) {
                     cdata['system']['is_stage'][key] = false;
                     }*/

                    for (const key of Object.keys(cdata['system']['buttons'])) {
                        cdata['system']['buttons'][key]['is_button_blocked'] = true;
                    }
                    console.log('on_mounted flag_error');
                    
                    self.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_with_additional', {'is_all_fin_for_this_page_with_additional': false, '_route_': cdata.system._route_});
                    self.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page', {'is_all_fin_for_this_page': false, '_route_': cdata.system._route_});
                    self.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': false, '_route_': cdata.system._route_});
                    self.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_executed', {'is_all_fin_for_this_page_executed': false, '_route_': $store.state._route_});
                    self.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_with_additional_executed', {'is_all_fin_for_this_page_with_additional_executed': false, '_route_': $store.state._route_});
                    
                    cdata['system']['cnf'] = self.cnf;
                    // console.log("SET|stage_value=is_all_stage_fin="+cdata['system']['is_all_stage_fin']);

                },
            }
        },
        _SYS: function () {
            var self = this;
            return {

                declOfNum: function (number, titles) {
                    //   console.log("declOfNum call");
                    var cases = [2, 0, 1, 1, 1, 2];
                    return titles[
                            (number % 100 > 4 && number % 100 < 20)
                            ?
                            2
                            :
                            cases[(number % 10 < 5) ? number % 10 : 5]
                    ];
                },
                is_faild_ES6: function () {

                    try {

                        let xxr = Array.of(7);
                        //  console.log(xxr);       // [7]
                        let xxe = Array.of(1, 2, 3);
                        //  console.log(xxe); // [1, 2, 3]

                        let mmm = 2;
                        const myFunction = num => {
                            return num * 5;
                        }
                        let res = myFunction(mmm);
                        // console.log("myFunction(mmm) = " + res);

                        const printName = (first, last) => console.log(`${first} ${last}`);
                        printName("ES6", "TEST");
                        const fruits = ['apple', 'banana', 'grapes', 'mango', 'orange'];
                        /**
                         * Элементы массива фильтруется на основе критериев поиска (query)
                         */
                        const filterItems = (query) => {
                            return fruits.filter((el) =>
                                el.toLowerCase().indexOf(query.toLowerCase()) > -1
                            );
                        }

                        let zz1 = filterItems('ap');
                        let zz2 = filterItems('an')
                        // console.log(zz1); // ['apple', 'grapes']
                        // console.log(zz2); // ['banana', 'mango', 'orange'] 
                        return false;
                    } catch (err) {
                        return true;
                    }


                },
                
                
               
              
                
                page_v_update: function ($store, cdata) {
                    
                    //если неустановлена в LS версия клиента, установим
                    if (localStorage.getItem("last_reset_timestamp")===null )
                            {
                            //    console.log(this.$store.state.state_tables.state_manage.json_version.version_timestamp_utc);
                            localStorage.setItem("last_reset_timestamp", parseInt($store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10));
                            }
                    
                    
                    //проверим версию страницы
                    let name_localstorage = "__" + cdata.system._route_;
                    let last_page_timestamp = localStorage.getItem(name_localstorage);
                    if (last_page_timestamp === null)
                    {
                        localStorage.setItem(name_localstorage, $store.state.state_tables.state_manage.json_version.version_timestamp_utc);
                        $store.dispatch('_system/vuex_set__page_v', {"page_v": $store.state.state_tables.state_manage.json_version.version_timestamp_utc});
                        
                    } else
                    {
                        if (parseInt($store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10) > parseInt(last_page_timestamp, 10))
                        {
                            localStorage.setItem(name_localstorage, $store.state.state_tables.state_manage.json_version.version_timestamp_utc);
                            $store.dispatch('_system/vuex_set__page_v', {"page_v": $store.state.state_tables.state_manage.json_version.version_timestamp_utc});
                            
                            window.location.reload();
                        }
                        else
                        {
                            console.log(last_page_timestamp);
                            $store.dispatch('_system/vuex_set__page_v', {"page_v": parseInt($store.state.state_tables.state_manage.json_version.version_timestamp_utc, 10)});
                        }

                    }
                },
                
                
                
                add_v: function () {

                    let cjson_version_timestamp_utc = localStorage.getItem('version_timestamp_utc');
                    if (cjson_version_timestamp_utc === null)
                    {
                        let ts = Math.floor(new Date().getTime() / 1000.0);
                        return (ts.toString()); //+"_");
                    } else
                    {
                        let json_version_timestamp_utc = window._CRYPT().decrypt_json(cjson_version_timestamp_utc);
                        return json_version_timestamp_utc['version_timestamp_utc'];
                    }
                },
                check_is_stages_fin_for_this_page: function ($store, cdata) {
                    // console.log("CALC is_stages_fin_for_THIS_page ...INSIDE");

                    let is_exist_false_stage = false;
                    let _route_ = cdata.system._route_;
                    for (const [div_name, div_value] of Object.entries($store.state.is_required_stages[_route_])) {
                        //console.log("empty_ stage_name="+stage_name+"|stage_value="+stage_value);
                        for (const [stage_name, stage_value] of Object.entries($store.state.is_required_stages[_route_][div_name])) {
                            //console.log(stage_name+ " ... "+stage_value);
                            if (stage_value !== "fin")
                            {
                                is_exist_false_stage = true;
                            }
                        }

                    }

                    if (!is_exist_false_stage) {
                        return true;
                    } else {
                        return false;
                    }

                },
                watch_force_called_on_mounted_execute: function ($store, cdata, is_log) {

                    if (is_log)
                    {
                        console.log("FORCE WATCH START~~~~~~~~~[" + cdata.system._route_ + "]");
                    }

                    /*
                     НЕЗАГРУЖЕНА: r_auth
                     НЕЗАГРУЖЕНА: json_layouts
                     */

                    try {

                        //_route_
                        self.$root._SYS().watch_blockings($store, cdata, is_log, true);
                        
                        if ($store.state.system[cdata.system._route_].is_all_fin_for_this_page)
//                        if (cdata.system.is_stages_fin_for_this_page)
                        {
                            //_route_
                            self.$root._SYS().ckeck_blockings_for_button($store, cdata, is_log, true);
                        }

                        //_route_
                        self.$root._SYS().watch_sections($store, cdata, is_log, false);
                        //_route_
                        self.$root._SYS().watch_stage($store, cdata, is_log, false);
                        // NO _route_
                        self.$root._SYS().watch_is_errors($store, cdata, is_log, false);
                    } catch (ex) {
                        console.log(ex);
                    }

                    if (is_log) {
                        console.log("FORCE WATCH END~~~~~~~~~[" + cdata.system._route_ + "]");
                    }

                },
                watch_is_errors: function ($store, cdata, is_log, is_log_errors) {
                    //   let _route_ = cdata['system']['_route_'];

                    let is_actions_error = {};
                    let was_error_gloabal = false;
                    let was_error = false;
                    for (const [key, value] of Object.entries($store.state.is_loaded_tables)) {

                        was_error = false;
                        let row = {};
                        if (value['error_api'])
                        {
                            row['error_api'] = value['error_api'];
                            was_error = true;
                            was_error_gloabal = true;
                        }

                        if (value['error_idb_load'])
                        {
                            row['error_idb_load'] = value['error_idb_load'];
                            was_error = true;
                            was_error_gloabal = true;
                        }

                        if (value['error_idb_save'])
                        {
                            row['error_idb_save'] = value['error_idb_save'];
                            was_error = true;
                            was_error_gloabal = true;
                        }

                        if (value['error_send'])
                        {
                            row['error_send'] = value['error_send'];
                            was_error = true;
                            was_error_gloabal = true;
                        }

                        row['load_status'] = value['load_status'];
                        if (was_error)
                        {
                            is_actions_error[key] = row;
                        }

                    }


                    cdata['errors']['is_errors'] = was_error_gloabal;
                    cdata['errors']['actions_error'] = is_actions_error;
                },
                watch_sections: function ($store, cdata, is_log, is_log_sections) {
                    let _route_ = cdata['system']['_route_'];
                    if (is_log && is_log_sections) {
                        console.log("watch_sections START");
                    }



                    /*      
                     g1_start__is_required_sections: 
                     center:Object
                     a_stress:true
                     a_test:true
                     a_test2:true   */

                    /*     [array...]
                     center footer header*/

                    let is_not_loaded_section = {};
                    for (const [key_page, value_page] of Object.entries($store.state.is_required_sections))
                    {

                        if (key_page === _route_)//.startsWith('g1_start')
                        {

                            for (const [key_section, value_section] of Object.entries($store.state.is_required_sections[key_page]))
                            {

                                is_not_loaded_section[key_section] = false; //Незагружена секция? - Нет по дефолту загружена!
                                //==========
                                //console.log(this.$store.state.is_required_sections[key_page][key_section]);
                                for (const [table_name, flag_value] of Object.entries($store.state.is_required_sections[key_page][key_section]))
                                {

                                    // console.log(table_name, flag_value);
                                    if (!flag_value)  //если нашелся незагруженный элемент для загрузки секции - зеачение false
                                    {
                                        if (is_log && is_log_sections) {
                                            console.log(key_section + "| НЕЗАГРУЖЕНА: " + table_name); }
                                        is_not_loaded_section[key_section] = true; //ДА секция-таки не загруждена
                                    }

                                }
                                //==========

                                //console.log("key_section="+key_section);
                                //console.log("is_not_loaded_section[key_section]=" + is_not_loaded_section[key_section]);
                                if (is_not_loaded_section[key_section])//Да секция незагружена 
                                {
                                    cdata['system']['is_section'][key_section] = false; //- выставим FALSE
                                } else
                                { //секция загружена 
                                    cdata['system']['is_section'][key_section] = true; //- выставим TRUE
                                }





                            }
                            if (is_log && is_log_sections) {
                                console.log("watch_sections END");
                            }


                        }




                    }

                },
                watch_stage: function ($store, cdata, is_log, is_log_stage) {
                    let _route_ = cdata['system']['_route_'];
                    if (is_log && is_log_stage) {
                        console.log("watch_stage START");
                    }
                    //console.log(_route_);
                    //console.log(oldValue);
                    //console.log(newValue);


                    /*      
                     g1_start__is_required_stages: 
                     center:Object
                     a_stress:true
                     a_test:true
                     a_test2:true   */

                    /*     [array...]
                     center footer header*/

                    let is_not_loaded_stage = {};
                    for (const [key_page, value_page] of Object.entries($store.state.is_required_stages))
                    {

                        if (key_page === _route_)//.startsWith('g1_start')
                        {   // obj[key]=value
                            //console.log("FINDED! EEE");
                            //console.log(key_page);
                            //console.log(value);
                            //console.log("=====");

                            for (const [key_div, value_div] of Object.entries($store.state.is_required_stages[key_page]))
                            {

                                is_not_loaded_stage[key_div] = false; //Незагружена секция? - Нет по дефолту загружена!
                                //==========
                                //console.log(this.$store.state.is_required_stages[key_page][key_div]);
                                for (const [stage_name, flag_value] of Object.entries($store.state.is_required_stages[key_page][key_div]))
                                {

                                    // console.log(stage_name, flag_value);
                                    if (!flag_value)  //если нашелся незагруженный элемент для загрузки секции - зеачение false
                                    {
                                        if (is_log && is_log_stage) {
                                            console.log(key_div + "|НЕЗАГРУЖЕНА: " + stage_name); }
                                        is_not_loaded_stage[key_div] = true; //ДА секция-таки не загруждена
                                    }

                                }
                                //==========

                                //console.log("key_div="+key_div);
                                //console.log("is_not_loaded_stage[key_div]=" + is_not_loaded_stage[key_div]);
                                if (is_not_loaded_stage[key_div])//Да секция незагружена 
                                {
                                    cdata['system']['is_stage'][key_div] = false; //- выставим FALSE
                                } else
                                { //секция загружена 
                                    cdata['system']['is_stage'][key_div] = true; //- выставим TRUE
                                }



                            }

                            if (is_log && is_log_stage) {
                                console.log("watch_stage END");
                            }

                        }




                    }

                },
                watch_blockings: function ($store, cdata, is_log, is_log_blockings) {
                    let _route_ = cdata['system']['_route_'];
                    if (is_log && is_log_blockings)
                    {
                        console.log("BLOCKING CHECK START");
                    }
                    //console.log("CHANGE");
                    //console.log(oldValue);
                    //console.log(newValue);

                    let is_button_common_blocked = false;
                    for (const [key, value] of Object.entries($store.state.blocked_actions)) {

                        if (key === _route_)//.startsWith('g1_start')
                        {

                            //==========
                            for (const [table_name, flag_value] of Object.entries($store.state.blocked_actions[key])) {
                                if (is_log && is_log_blockings)
                                {
                                    console.log(table_name, flag_value);
                                }
                                if (flag_value)
                                {
                                    if (is_log && is_log_blockings)
                                    {
                                        console.log("В блокировке " + table_name + "");
                                    }
                                    is_button_common_blocked = true;
                                }

                            }
                            //==========

                        }
                    }

                    cdata['system']['buttons']['common']['is_button_blocked'] = is_button_common_blocked;
                    if (is_log && is_log_blockings)
                    {
                        console.log("is_button_common_blocked=" + cdata['system']['buttons']['common']['is_button_blocked']);
                        console.log("BLOCKING CHECK END");
                    }
                },
                ckeck_blockings_for_button: function ($store, cdata, is_log, is_log_blocking_buttons) {
                    let _route_ = cdata['system']['_route_'];
                    if (is_log && is_log_blocking_buttons) {
                        console.log("CHANGE_ ckeck_blockings_for_button");
                    }
                    //console.log(oldValue);
                    //console.log(newValue);



                    for (const [key, value] of Object.entries($store.state.blocked_actions)) {

                        if (key === _route_)//.startsWith('g1_start')
                        {   // obj[key]=value
                            //console.log("FINDED!");
                            //console.log(key);
                            //console.log(value);
                            //console.log("=====");

                            //==========

                            //перебираем все кнопки....
                            for (const [button_name, button_value] of Object.entries(cdata.system.buttons)) {

                                //   let button1_exeption_list = {0: "r_auth",}; buttons['button1'] = 
                                let button_blocking_exeption_list = button_value['button_blocking_exeption_list'];
                                if (is_log && is_log_blocking_buttons) {

                                    // console.log(button_name);
                                    // console.log(button_value);

                                    console.log(button_blocking_exeption_list);
                                }

                                let is_button_blocked = false;
                                for (const [table_name, is_blocked] of Object.entries($store.state.blocked_actions[key])) {

                                    //console.log(table_name, is_blocked);
                                    if (is_blocked)
                                    {
                                        if (is_log && is_log_blocking_buttons) {
                                            console.log(table_name + "BLOCKED");
                                            console.log("BLOCK LIST for " + button_name);
                                            console.log(button_blocking_exeption_list);
                                        }
                                        //exeption_list
                                        //проверим есть ли таблица в списке исключений


                                        //перебираем список исключений для кнопки и смотрим is_table_in_exeption_list
                                        let is_table_in_exeption_list = false;
                                        for (const [exeption_list_key, exeption_list_table_name] of Object.entries(button_blocking_exeption_list)) {
                                            //console.log(exeption_list_table_name + "|" + table_name);
                                            if (exeption_list_table_name === table_name)
                                            {
                                                if (is_log && is_log_blocking_buttons) {
                                                    console.log("в стоп листе:" + exeption_list_table_name); }
                                                is_table_in_exeption_list = true;
                                            }
                                        }
                                        //заблокированная таблица не в списке исключений для конпки? - БЛОКИРУЕМ КНОПКУ
                                        if (!is_table_in_exeption_list)
                                        {
                                            if (is_log && is_log_blocking_buttons) {
                                                console.log("Кнопка в блокировке из-за " + table_name + "");
                                            }
                                            is_button_blocked = true;
                                        }
                                    }

                                }

                                cdata.system.buttons[button_name]['is_button_blocked'] = is_button_blocked;
                                if (is_log && is_log_blocking_buttons) {
                                    console.log(button_name + " is_button_blocked?=" + is_button_blocked);
                                }
                                //==========


                            }




                        }
                    }



                },
            }
        },
        _CARD_LOGIC: function () {
            var self = this;
            return {

                choose_next_card_stopochkanum_and_cardnum: function ($store, cdata) {



//Если в cdata.business_logic['list_cards_notlearned_themes'] ПУСТО, то берем из cdata.business_logic['list_cards_learned_themes']

                    if (
                            Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length === 0 &&
                            Object.keys(cdata.business_logic['list_cards_learned_themes']).length === 0
                            )
                    {
                        //TODO карточчки закончались!
                        cdata.business_logic.card_mode = 'form_end_cards';
                        cdata.business_logic.curr_card.stopochka_num = -1;
                        cdata.business_logic.curr_card.card_num = -1;
                    } else
                    {
                        //1 - 10
                        let rand_number = Math.floor(Math.random() * 10) + 1;
                        let what_stopochka_to_use = ((rand_number <= 6) ? 'list_cards_notlearned_themes' : 'list_cards_learned_themes');
                        console.log("RAND STOP");
                        console.log(rand_number);
                        console.log(what_stopochka_to_use);
                        console.log("====================");
                        
                        
//                        if (Object.keys(cdata.business_logic['list_cards_learned_themes']).length == 0 || (Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length != 0 && what_stopochka_to_use == "list_cards_notlearned_themes"))
                        if (Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length != 0)
                        {
                            //берем случайную карточку ИЗ НЕВЫУЧЕННЫХ ТЕМ cdata.business_logic['list_cards_notlearned_themes']
                            let len_list = Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length;
                            let rand_choose = window._MATH().rand(0, len_list - 1);

                            
                            cdata.business_logic.curr_card.stopochka_num = cdata.business_logic['list_cards_notlearned_themes'][rand_choose]['stopochka_num'].toString();
                            cdata.business_logic.curr_card.card_num = cdata.business_logic['list_cards_notlearned_themes'][rand_choose]['card_num'].toString();
                            console.log(cdata.business_logic.curr_card.stopochka_num + "=st|card_num=" + cdata.business_logic.curr_card.card_num);
                        } else {
                            //берем случайную карточку ИЗ ВЫУЧЕНЫХ ТЕМ из cdata.business_logic['list_cards_learned_themes']
                            let len_list = Object.keys(cdata.business_logic['list_cards_learned_themes']).length;
                            let rand_choose = window._MATH().rand(0, len_list - 1);
                            
                            cdata.business_logic.curr_card.stopochka_num = cdata.business_logic['list_cards_learned_themes'][rand_choose]['stopochka_num'].toString();
                            cdata.business_logic.curr_card.card_num = cdata.business_logic['list_cards_learned_themes'][rand_choose]['card_num'].toString();
                            console.log(cdata.business_logic.curr_card.stopochka_num + "=st|card_num=" + cdata.business_logic.curr_card.card_num);
                        }
                        
                        
//                        if (Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length === 0)
//                        {
//
//                            //берем случайную карточку ИЗ ВЫУЧЕНЫХ ТЕМ из cdata.business_logic['list_cards_learned_themes']
//                            let len_list = Object.keys(cdata.business_logic['list_cards_learned_themes']).length;
//                            let rand_choose = window._MATH().rand(0, len_list - 1);
//                            
//                            cdata.business_logic.curr_card.stopochka_num = cdata.business_logic['list_cards_learned_themes'][rand_choose]['stopochka_num'].toString();
//                            cdata.business_logic.curr_card.card_num = cdata.business_logic['list_cards_learned_themes'][rand_choose]['card_num'].toString();
//                            console.log(cdata.business_logic.curr_card.stopochka_num + "=st|card_num=" + cdata.business_logic.curr_card.card_num);
//                        } else {
//                            //берем случайную карточку ИЗ НЕВЫУЧЕННЫХ ТЕМ cdata.business_logic['list_cards_notlearned_themes']
//                            let len_list = Object.keys(cdata.business_logic['list_cards_notlearned_themes']).length;
//                            let rand_choose = window._MATH().rand(0, len_list - 1);
//
//                            
//                            cdata.business_logic.curr_card.stopochka_num = cdata.business_logic['list_cards_notlearned_themes'][rand_choose]['stopochka_num'].toString();
//                            cdata.business_logic.curr_card.card_num = cdata.business_logic['list_cards_notlearned_themes'][rand_choose]['card_num'].toString();
//                            console.log(cdata.business_logic.curr_card.stopochka_num + "=st|card_num=" + cdata.business_logic.curr_card.card_num);
//                        }

                    }





                },
                make_list_cards_learned_or_not: function ($store, cdata) {


                    //------------------------------СВЕРНУТЬ В ФУНКЦИЮ 
                    let summ_solved_cnt = 0;
                    let summ_all_cnt = 0;
                    for (const [stopochka_key, stopochka] of Object.entries(cdata.business_logic['desk'])) {

                        //ДЛЯ КАЖДОЙ СТОПОЧКИ
                        //stopochka_key

                        //выставим, решена ли карточка
                        let solved_cnt = 0;
                        for (const [card_key, card] of Object.entries(stopochka.card_list)) {

                            //проверим по регистру st_cards 

                            let card_num = card.card_num.toString();
                            //   console.log(card_num + "=card_num!!!!!");
                            //   console.log(card);
                            //смотрим по данным, есть ли карточка с  curr_card_is_solved
                            let is_find_solved_card_in_st_cards = false;
                            for (const [key, rgcard] of Object.entries($store.state.state_tables.state_st.st_cards)) {
                                /* console.log("rgcard");
                                 console.log(rgcard);
                                 console.log(cdata.business_logic);
                                 console.log(card_num);*/
                                // console.log(rgcard.is_solved + "|" + (rgcard.is_solved.toString() === "1") + "|" + rgcard.card_num.toString()+"||rgcard.card_num="+rgcard.card_num.toString()+"~"+ card_num.toString()+"=card_num");
                                if (
                                        // layout_version для st_card одинаковая! 
                                        rgcard.desk_version.toString() === cdata.business_logic['desk_version'].toString() &&
                                        rgcard.lid.toString() === cdata.business_logic['lid'].toString() &&
                                        rgcard.pos.toString() === cdata.business_logic['pos'].toString() &&
                                        rgcard.card_num.toString() === card_num.toString() &&
                                        rgcard.is_solved.toString() === "1"
                                        )
                                {
                                    console.log("OK");
                                    is_find_solved_card_in_st_cards = true;
                                    card['already_solved'] = true;
                                    solved_cnt = solved_cnt + 1;
                                    summ_solved_cnt = summ_solved_cnt + 1;
                                    break;
                                }

                            }

                            if (!is_find_solved_card_in_st_cards)
                            {
                                card['already_solved'] = false;
                            }




                        }




                        //TODO проверить, если в стопочке все карточки решены?
                        stopochka['cards_solved_cnt'] = solved_cnt;
                        stopochka['cards_all_cnt'] = Object.keys(stopochka.card_list).length;
                        if (solved_cnt === Object.keys(stopochka.card_list).length)
                        {
                            stopochka['is_stopochka_full_solved'] = true;
                        } else {
                            stopochka['is_stopochka_full_solved'] = false;
                        }
                        summ_all_cnt = summ_all_cnt + stopochka['cards_all_cnt'];




                    }











                    cdata.business_logic['desk_summ_solved_cnt'] = summ_solved_cnt;
                    cdata.business_logic['desk_summ_all_cnt'] = summ_all_cnt;
                    if (summ_solved_cnt === summ_all_cnt)
                    {
                        cdata.business_logic['is_desk_full_solved'] = true;
                    } else {
                        cdata.business_logic['is_desk_full_solved'] = false;
                    }



                    //проставим лимиты в регистр выученности... (регистр обновляется, так что делаем каждый раз)
                    cdata.business_logic['rg_learning'] = {};
                    let l = 0;
                    for (const [rglearning_key, rglearning] of Object.entries($store.state.state_tables.state_rg.rg_learning)) {

                        let is_theme_in_desk = false;
                        let theme_learning_task_limit = -1;
                        for (const [theme_key, theme] of Object.entries(cdata.business_logic['desk_theme_list'])) {

                            if (theme.theme_id.toString() === rglearning.theme_id.toString())
                            {
                                theme_learning_task_limit = theme.theme_learning_task_limit.toString();
                                is_theme_in_desk = true;
                                break;
                            }
                        }
                        if (is_theme_in_desk)
                        {
                            cdata.business_logic['rg_learning'][l] = rglearning;
                            cdata.business_logic['rg_learning'][l].theme_learning_task_limit = theme_learning_task_limit;
                            l = l + 1;
                        }

                    }



                    //создать список карточек где выученность  недостаточна
                    //создать список карточек где выученность  превышена - все в одном цикле
                    //карточки со статусом решеные!
                    //с указанием в какой стопочке...!!!
                    cdata.business_logic['list_cards_learned_themes'] = {};
                    cdata.business_logic['list_cards_notlearned_themes'] = {};
                    let num_lt = 0;
                    let num_notlt = 0;
                    for (const [stopochka_num, stopochka] of Object.entries(cdata.business_logic['desk'])) {
                        console.log('stopochka_num: ' + stopochka_num);
                        if (!stopochka.is_stopochka_full_solved)
                        {
                            console.log('stopochka_num_solved: ' + stopochka_num);
                            for (const [card_num, card] of Object.entries(stopochka.card_list)) {

                                if (!card.already_solved)
                                {



                                    let is_exist_not_learned_theme = false;
                                    for (const [task_num, task] of Object.entries(card.task_list)) {
                                        //перебераем таски в карточке и смотрим, 
                                        //полностью ли все таски в карточке
                                        //принадлежат к выученным темам?


                                        for (const [theme_key, rg_learning_el] of Object.entries(cdata.business_logic['rg_learning'])) {
                                            if (
                                                    rg_learning_el.theme_id.toString() === task.theme_id.toString() &&
                                                    parseInt(rg_learning_el.theme_learning_task_count) >= parseInt(rg_learning_el.theme_learning_task_limit))
                                            {
                                                is_exist_not_learned_theme = true;
                                                break;
                                            }
                                        }
                                        //task.theme_id  - найти в выученности, если есть и лимит привышен - is_exist_not_learned_theme=true 
                                        // если нет, или лимит не привышен - все окей, дальше

                                        //если да - то в лист1
                                        //иначе в лист2
                                    }

                                    if (is_exist_not_learned_theme)
                                    {
                                        //все темы для всех тасков выучены - карточка относится к "выученным"
                                        //добавиться в в лист1
                                        cdata.business_logic['list_cards_learned_themes'][num_lt] = {};
                                        cdata.business_logic['list_cards_learned_themes'][num_lt]['num'] = num_lt;
                                        cdata.business_logic['list_cards_learned_themes'][num_lt]['stopochka_num'] = stopochka_num;
                                        cdata.business_logic['list_cards_learned_themes'][num_lt]['card_num'] = card_num;
                                        num_lt = num_lt + 1;
                                    } else
                                    {
                                        //добавиться в в лист1
                                        cdata.business_logic['list_cards_notlearned_themes'][num_notlt] = {};
                                        cdata.business_logic['list_cards_notlearned_themes'][num_notlt]['num'] = num_notlt;
                                        cdata.business_logic['list_cards_notlearned_themes'][num_notlt]['stopochka_num'] = stopochka_num;
                                        cdata.business_logic['list_cards_notlearned_themes'][num_notlt]['card_num'] = card_num;
                                        num_notlt = num_notlt + 1;
                                    }


                                }

                            }

                        }
                    }

//---------------------СВЕРНУТЬ В ФУНКЦИЮ!!!




                },
                check_is_card_solved: function ($store, cdata) {

                    let is_all_task_solved = true;
                    for (const [task_key, task_value] of Object.entries(cdata.business_logic.curr_card.task_list)) {

                        if (task_value.task_answer.indexOf('#') > -1)
                        {
                            //ответы - КНОПКИ

                            if (task_value.task_answer.indexOf('&&') > -1)
                            {
                                //НЕСКОЛЬКО КНОПОК - несколько правильных кнопок
                                
                                let ckeckbox_answers = task_value.task_answer.split("#")[1].split("&&");
                                let is_all_correct = true;

                                    for (const [key_vanws, value_vanws] of Object.entries(task_value.variansts_for_answer)) {
                                        
                                        let is_option_correct = ckeckbox_answers.find(variant => variant == value_vanws.value);
                                        let is_this_answer = task_value.selected_ckeckbox.find(variant => variant == value_vanws.index);
                                        
                                        if(is_option_correct) {
                                            if(is_this_answer) {
//                                                let correct_answer = document.querySelector('[for = "checkbox_task_index_'+task_key+'_'+value_vanws.index+'"]');
//                                                    correct_answer.style.color = '#55D18E';
//                                                    correct_answer.style.background = '#C7FFC2';
//                                                    correct_answer.style.border = '2px solid #55D18E';
                                            } else {
//                                                let correct_answer = document.querySelector('[for = "checkbox_task_index_'+task_key+'_'+value_vanws.index+'"]');
//                                                    correct_answer.style.border = '2px solid #55D18E';
                                            }
                                        } else if (!is_option_correct && is_this_answer) {
//                                            let incorrect_answer = document.querySelector('[for = "checkbox_task_index_'+task_key+'_'+value_vanws.index+'"]');
//                                                incorrect_answer.style.color = '#FF7697';
//                                                incorrect_answer.style.background = '#FFE3E3';
//                                                incorrect_answer.style.border = '2px solid #FF7697';
                                                
                                            is_all_correct = false;
                                        }
                                    }

                                console.log(ckeckbox_answers.length + "=|?|=" + Object.keys(task_value.selected_ckeckbox).length);
                                if (is_all_correct && ckeckbox_answers.length === Object.keys(task_value.selected_ckeckbox).length)
                                {
                                    console.log("CORRECT!!!");
                                    task_value.is_task_answer_correct = true;
                                } else
                                {
                                    console.log("INCORRECT!!!");
                                    task_value.is_task_answer_correct = false;
                                    is_all_task_solved = false;
                                }



                                //   task_value.is_task_answer_correct = false;
                            } else
                            {
                                //ОДНА КНОПКА - правильная кнопка
                                let task_answer = task_value.task_answer.split("#")[1];
                                if (task_value.selected_radio && (window._STR().clean(task_answer) === window._STR().clean(task_value.variansts_for_answer[task_value.selected_radio].value)))
                                {
                                    //верный ответ
//                                    let correct_answer = document.querySelector('[for = "radios_task_index_'+task_key+'_'+task_value.selected_radio+'"]');
//                                        correct_answer.style.color = '#55D18E';
//                                        correct_answer.style.background = '#C7FFC2';
//                                        correct_answer.style.border = '2px solid #55D18E';
                                    
                                    task_value.is_task_answer_correct = true;
                                } else
                                {
                                    //неверный ответ
//                                    for (const [key_correct_answer, value_correct_answer] of Object.entries(task_value.variansts_for_answer)) {
//                                        if(window._STR().clean(task_answer) === value_correct_answer.value) {
//                                            let correct_answer = document.querySelector('[for = "radios_task_index_'+task_key+'_'+key_correct_answer+'"]');
//                                                correct_answer.style.border = '2px solid #55D18E';
//                                            
//                                            break;
//                                        }
//                                    }

                                        
//                                    let incorrect_answer = document.querySelector('[for = "radios_task_index_'+task_key+'_'+task_value.selected_radio+'"]');
//                                        incorrect_answer.style.color = '#FF7697';
//                                        incorrect_answer.style.background = '#FFE3E3';
//                                        incorrect_answer.style.border = '2px solid #FF7697';
                                    
                                    task_value.is_task_answer_correct = false;
                                    is_all_task_solved = false;
                                }




                            }

                        } else
                        {
                            //просто ответы БЕЗ КНОПОК

                            if (window._STR().clean(task_value.task_answer) === window._STR().clean(task_value.task_input))
                            {
//                                let correct_answer = document.querySelector('#input_text_answer_'+task_key);
//                                    correct_answer.style.color = '#2E3B4B';
//                                    correct_answer.style.background = '#C7FFC2';
//                                    correct_answer.style.border = '2px solid #55D18E';
                                    
                                //верный ответ
                                task_value.is_task_answer_correct = true;
                            } else
                            {
//                                let incorrect_answer = document.querySelector('#input_text_answer_'+task_key);
//                                    incorrect_answer.style.color = '#FF7697';
//                                    incorrect_answer.style.border = '2px solid #FF7697';
//                                    
//                                let show_correct_answer = document.querySelector('#input_correct_answer_'+task_key);
//                                    show_correct_answer.style.display = 'flex';
                                    
                                //неверный ответ
                                task_value.is_task_answer_correct = false;
                                is_all_task_solved = false;
                            }

                        }




                    }



                    return is_all_task_solved;
                },

                get_card: function ($store, cdata, stopochka_num, card_num) {
//                    let show_admin_answers = false;
                    let show_admin_answers = localStorage.getItem("right_answers__admin_controls");
                    
                    
                    
                    cdata.business_logic.show_card = false;
                    
                    cdata.business_logic.curr_card = JSON.parse(JSON.stringify(cdata.business_logic.desk[stopochka_num].card_list[card_num]));
                    

cdata.business_logic['curr_card']['max_width_img'] = 0;

                    let task_cnt = 0;
                    for (const [task_key, task] of Object.entries(cdata.business_logic['curr_card'].task_list)) {
                        
if(task.task_img){
    
    let image = new Image();
    image.onload = function () {
        console.log('aaaaaaaaaaaaaaaaff');
        console.log(image);
        if(image.width >  cdata.business_logic['curr_card']['max_width_img']) {
            cdata.business_logic['curr_card']['max_width_img'] = image.width;
            let obj_length = Object.keys(cdata.business_logic['curr_card'].task_list).length;
            
            if(obj_length == 1){
                cdata.business_logic['curr_card']['display_view'] = "one_image";
            } else {
                cdata.business_logic['curr_card']['display_view'] = "two_images_horisontal";
            }
//            else if(obj_length == 2 && cdata.business_logic['curr_card']['max_width_img'] <= 410){
//                cdata.business_logic['curr_card']['display_view'] = "two_images_horisontal";
//            } else if (obj_length == 2 && cdata.business_logic['curr_card']['max_width_img'] > 410){
//                cdata.business_logic['curr_card']['display_view'] = "two_images_vertical";
//            }
            cdata.business_logic.show_card = true;
            
        }

    };
    image.src = cdata['system']['cnf']['server_cloud'] + task.task_img;
} else {
    cdata.business_logic['curr_card']['display_view'] = "default";
    cdata.business_logic.show_card = true;
}
//искать тему для каждой задачи и расположение ответа в ней

    
                        //let temparr=task['task_answer'].split("#");

                        //TODO make EMPTY task_input
                        task['task_answer'] = window._STR().clean_with_space(task['task_answer'].toString());
//                        task['task_answer'] = window._STR().clean(task['task_answer'].toString());
                        task['is_task_answer_correct'] = false;
                        task['is_buttons'] = (task.task_answer.indexOf('#') > -1) ? true : false;
                        task['is_multi_buttons'] = (task.task_answer.indexOf('&&') > -1 && task['is_buttons']) ? true : false;
                        if (task['is_buttons'])
                        {
                            task['task_input'] = "";
                            let variansts_for_answer = task['task_answer'].split("#")[0].split("|");
                            task['variansts_for_answer'] = {};
                            for (let j = 0, len = variansts_for_answer.length; j < len; j++) {
                                task['variansts_for_answer'][j] = {
                                    value: window._STR().clean(variansts_for_answer[j]),
                                    index: j.toString(),
                                };
                            }




                        } else
                        {
                            //task['task_input'] = task['task_answer'];
                            if(show_admin_answers == "true") { Vue.set(cdata.business_logic.curr_card.task_list[task_key], 'task_input', task['task_answer']); }
                            
                        }





                        task_cnt++;
                    }
                    cdata.business_logic['curr_card']['task_cnt'] = task_cnt;
                    
                    
//добавим кнопочки

                    for (const [task_key, task] of Object.entries(cdata.business_logic['curr_card'].task_list)) {

                        if (task['is_buttons']) {


                            let variansts_for_answer = task['task_answer'].split("#")[0].split("|");
                            if (!task['is_multi_buttons'])
                            {
                                //RADIO
                                //task['selected_radio']={};
                                task['selected_radio']= "";
                                let radio_answer = task['task_answer'].split("#")[1];
                                for (let l = 0, len = variansts_for_answer.length; l < len; l++) {
                                    // console.log(window._STR().clean(variansts_for_answer[l])+"=|="+window._STR().clean(radio_answer));
                                    if (window._STR().clean(variansts_for_answer[l]) === window._STR().clean(radio_answer))
                                    {
                                        if(show_admin_answers == "true") { Vue.set(cdata.business_logic.curr_card.task_list[task_key], 'selected_radio', l.toString()); }
                                        
                                    }
                                }

                                task['selected_ckeckbox'] = "";
                            } else
                            {
                                //CHECKBOX

                                task['selected_radio'] = "";
                                Vue.set(cdata.business_logic.curr_card.task_list[task_key], 'selected_ckeckbox', []);
                                let ckeckbox_answers = task['task_answer'].split("#")[1].split("&&");
                                let z = 0;
                                for (let l = 0, len = variansts_for_answer.length; l < len; l++) {
                                    for (let n = 0, len2 = ckeckbox_answers.length; n < len2; n++) {

                                        if (ckeckbox_answers[n] === variansts_for_answer[l])
                                        {
                                            if(show_admin_answers == "true") { cdata.business_logic.curr_card.task_list[task_key].selected_ckeckbox[z] = l.toString(); }
                                            z++;
                                            break;
                                            //Vue.set(, 'selected_radio', l.toString());
                                        }
                                    }
                                }

                                //ckeckbox_answers.sort(); //!!!сортируем и то и то, только при проверке ответов!!!

                            }

                        }
                    }








                    //Заполним подсказки, претаски, озвучку претасков  
                    let last_theme_pretask = "NOTSET";
                    //let last_theme_pretask_audio=""; 
                    for (const [task_key, task] of Object.entries(cdata.business_logic['curr_card'].task_list)) {

                        //task.theme_id
                        //переберем все темы
                        for (const [theme_key, theme] of Object.entries(cdata.business_logic.desk_theme_list)) {

                            if (theme.theme_id === task.theme_id)
                            {

                                if (window._STR().clean(theme.theme_pretask) === window._STR().clean(last_theme_pretask.toString().trim()))
                                {
                                    task.theme_pretask = "";
                                    task.theme_pretask_audio = "";
                                    task.theme_answer_format = "TASK_ANSWER_FORMAT_BOTTOM";
                                } else
                                {
                                    cdata.business_logic.curr_card.theme_pretask = window._STR().clean(theme.theme_pretask);
                                    
                                    task.theme_pretask = window._STR().clean(theme.theme_pretask);
                                    task.theme_pretask_audio = window._STR().clean(theme.theme_pretask_audio);
                                    task.theme_answer_format = window._STR().clean(theme.theme_answer_format);

                                    last_theme_pretask = window._STR().clean(theme.theme_pretask);
                                    //last_theme_pretask_audio=theme.theme_pretask_audio;
                                }
                                
                                task.theme_answer_format = theme.theme_answer_format;
                                task.theme_hint_audio = theme.theme_hint_audio;
                                task.theme_hint_image = theme.theme_hint_image;

                                break;
                            }

                        }

                        //

                    }


//                                REWARDS
                            let child_lid = $store.state.state_tables.state_auth.r_auth.lid;
                            let child_classnum = $store.state.state_tables.state_auth.r_auth.classnum;
                        // GOLD
                        let gold_value = 0;
                        let gold_multiplier = (($store.state.state_tables.state_desks[cdata.business_logic['desk_name']].solved_cards < 3) ? 2 : 1);
                        for (const [desk_index, desk] of Object.entries($store.state.state_tables.state_game_settings.level_props__gold_by_tasks.layouts["class_" + child_classnum].layout[child_lid])) {
                            let desk_name = "json_" + desk.desk_id;
                            if(desk_name == cdata.business_logic['desk_name']){
                                gold_value = desk.gold_per_task;
                            }
                        }
                        cdata.business_logic['card_gold'] = (Number(gold_value)) * gold_multiplier;
                        // GOLD
                        
                        
                        // JOKER
                        let joker_value = 0;
                        let joker_multiplier = $store.state.state_tables.state_game_settings.level_props__joker_by_tasks.layouts["class_" + $store.state.state_tables.state_auth.r_auth.classnum].multiplier_value;
                        for (const [desk_index, desk] of Object.entries($store.state.state_tables.state_game_settings.level_props__joker_by_tasks.layouts["class_" + child_classnum].layout[child_lid])) {
                            let desk_name = "json_" + desk.desk_id;
                            if(desk_name == cdata.business_logic['desk_name']){
                                joker_value = desk.joker_per_task;
                            }
                        }
                        cdata.business_logic['card_joker'] = (Number(joker_value)) * joker_multiplier;
                        // JOKER
                        
                        //EXP
                        let exp_value = 0;
                        let exp_multiplier = 1;
                        
                        for (const [task_index, task_data] of Object.entries(cdata.business_logic.curr_card.task_list)) {
                            if(Object.keys(cdata.business_logic.rg_learning).length == 0) { exp_multiplier = $store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + $store.state.state_tables.state_auth.r_auth.classnum].multiplier_value; }
                            else {
                                let found_theme = false;
                                let allow_exp_multiplier = false;
                                
                                for (const [theme_index, theme_data] of Object.entries(cdata.business_logic.rg_learning)) {
                                    if(Number(theme_data.theme_id) == Number(task_data.theme_id)){
                                        found_theme = true;
                                        if(Number(theme_data.theme_learning_task_count) <= Number(theme_data.theme_learning_task_limit)){
                                            allow_exp_multiplier = true;
                                        }
                                    }
                                }
                                if(!found_theme) { exp_multiplier = $store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + $store.state.state_tables.state_auth.r_auth.classnum].multiplier_value; }
                                else if(found_theme && allow_exp_multiplier) { exp_multiplier = $store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + $store.state.state_tables.state_auth.r_auth.classnum].multiplier_value; }
                            }
                        }
                        
                        for (const [desk_index, desk] of Object.entries($store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + child_classnum].layout[child_lid])) {
                            let desk_name = "json_" + desk.desk_id;
                            if(desk_name == cdata.business_logic['desk_name']){
                                exp_value = desk.exp_per_task;
                            }
                        }
                        cdata.business_logic['card_exp'] = (Number(exp_value)) * exp_multiplier;
                        
                        //EXP

//                                REWARDS




                }


            }
        },
        
        _REQUEST_TO_PARENT_DASHBORD: function(){
            //orange console
            var self = this;
            return {
                
                update__child_grade: function($store, classnum){
                    let data = {
                        child_id: $store.state.state_tables.state_auth.r_auth.rid,
                        grade: classnum
                    };
                    axios.post(self.$root.cnf['server_auth'] + "/child/update/child_grade", data)
                        .then((response) => {
                            console.log("%c|||", 'color: orange; ');
                            console.log(response);
                            console.log("%c|||", 'color: orange; ');
                        })
                        .catch((errors) => {
                            console.log("%c|||", 'color: orange; ');
                            console.log(errors);
                            console.log("%c|||", 'color: orange; ');
                        })
                },
                
            }
        },
        
        _UPDATE: function () {
            var self = this;
            return {

                //business_logic
                
                user_stats: function ($store, cdata, is_loaded_player_inventory) {
                    if(is_loaded_player_inventory === "fin"){
                        let output = {};
                        
                        output['joker'] = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_joker_coin");
                        if(!output['joker']) { output['joker'] = {'item_count': 0}; }
                        
                        output['gold'] = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_gold");
                        if(!output['gold']) { output['gold'] = {'item_count': 0}; }
                        
                        output['exp'] = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_exp");
                        if(!output['exp']) { output['exp'] = {'item_count': 0}; }
                        
                        return output;
                    } else {
                        return {};
                    }
                },
                
                
                current_player_lvl: function ($store, cdata, is_loaded_global__level_settings) {
                    if (is_loaded_global__level_settings === "fin"){
                        let conf = {
                            "current_lvl": 1,
                            "current_exp": 0,
                            "current_lvl_min": 0,
                            "current_lvl_max": 100,
                            "percentage_to_lvl_up": "0",
                            "max_lvl_from_settings": 1
                        };
                        
                        conf.current_exp = $store.state.state_tables.state_game_stats.current_exp.value;

                        for (const [key_level, level_info] of Object.entries($store.state.state_tables.state_game_settings.global__level_settings.levels)) {
                            if(Number(conf.max_lvl_from_settings) < Number(key_level)) {
                                conf.max_lvl_from_settings = key_level;
                            }
                            if(Number(conf.current_exp) >= Number(level_info.exp_min)) {
                                conf.current_lvl = key_level;
                                conf.current_lvl_max = level_info.exp_max;
                                conf.current_lvl_min = level_info.exp_min;
                            }
                        }
                        
                        let percentage__to_lvl_up = Number(conf.current_lvl_max) - Number(conf.current_lvl_min);
                        let percentage__solved_to_lvl_up = Number(conf.current_exp) - Number(conf.current_lvl_min);
                        
                        let percentage_to_lvl_up = (percentage__solved_to_lvl_up * 100) / percentage__to_lvl_up;
                        percentage_to_lvl_up = Math.floor(percentage_to_lvl_up);
                        conf.percentage_to_lvl_up = percentage_to_lvl_up > 100 ? "100" : percentage_to_lvl_up + "";

                        return conf;
                    } else {
                        return "1";
                    }
                },

                on_slide_add: function ($store, cdata) {
                    let a_conf = {};
                    a_conf['layout_version'] = $store.state.state_tables.state_layouts.json_layouts.version_timestamp;
                    a_conf['lid'] = cdata.business_logic.lid;
                    a_conf['pos'] = cdata.business_logic.pos;
                    a_conf['desk_version'] = cdata.business_logic.desk_version;
                    a_conf['card_num'] = cdata.business_logic.curr_card.card_num;




                    //      cdata['system']['cnf']['server_action'];




                    return a_conf;
                },

                //AUTH
                r_auth_change_lid_and_classnum__multi_ls_and_back: function ($store, cdata, param ) {

                    let a_conf = {};
                    a_conf['table_names'] = {
                        r_auth: {module: "state_auth", updatetype: "owerwrite", keyfield: ""}/*, 
                         a_test:{module:"state_test", updatetype: "owerwrite", keyfield:""}, 
                         a_test2:{module:"state_test", updatetype: "owerwrite", keyfield:""}  */
                    };
                    console.log("RE+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "r_auth_change_lid_and_classnum",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth,
                            new_lid: param['new_lid'],
                            new_classnum: param['new_classnum'],
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                on_test_button__multi_ls_and_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
//                        a_test: {module: "state_test", updatetype: "updaterows", keyfield: "id"},
//                        a_test2: {module: "state_test", updatetype: "insertrows", keyfield: ""},
                        test3: {module: "state_test", updatetype: "deleterows", keyfield: "id"},
                    };
                    
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "on_test_button",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            a_test_id: 1,
                            test3_id: 1,
                            test2_info_value: 'test123'
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                
                on_select_classnum_button__multi_ls_and_back: function ($store, cdata, param) {

                    let a_conf = {};
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        r_auth: {module: "state_auth", updatetype: "updaterows", keyfield: ""}
                    };
                    
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "update_child_classnum",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            new_classnum: param,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                approve_story_quest__multi_ls_and_back: function ($store, cdata, story_quest, lvl_info, snotify_obj) {
                    let resources_to_update = {};
                    
//                    TEST
                    let new_exp_value = Number($store.state.state_tables.state_game_stats.current_exp.value);
                    let max_exp_allowed = null;
                    for (const [key_level, level_info] of Object.entries($store.state.state_tables.state_game_settings.global__level_settings.levels)) {
                        max_exp_allowed = level_info.exp_max;
                    }
//                    TEST
                    
                    if(!story_quest.quest_options || story_quest.quest_options.collect_on_complete == true){
                        story_quest.what_to_give.forEach(item => {
                            if(!resources_to_update[item.item_id]) { resources_to_update[item.item_id] = 0; }
                            resources_to_update[item.item_id] -= Number(item.amount);
                        });
                    }
                    
                    story_quest.what_to_get.forEach(item => {
                        if(!resources_to_update[item.item_id]) { resources_to_update[item.item_id] = 0; }
                        resources_to_update[item.item_id] += Number(item.amount);
                        
                        if(item.item_id == "res_exp") { new_exp_value += Number(item.amount); }
                    });
                    
//                    TEST
                    if(max_exp_allowed != null) {
                        if(new_exp_value > max_exp_allowed) { new_exp_value = max_exp_allowed; }
                    }
                    
                        //LVL UP NOTIFIFY
                        if(new_exp_value >= Number(lvl_info.current_lvl_max) &&
                           Number(lvl_info.current_lvl) < Number(lvl_info.max_lvl_from_settings) &&
                           new_exp_value > Number(lvl_info.current_exp)){
                       
                            snotify_obj.info("You've just leveled up!", 'Good news!', {
                                timeout: 3000,
                                showProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        }
                        //END LVL UP NOTIFIFY
//                    TEST
                    
                    let a_conf = {};
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        quest_manager: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        player_inventory: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        current_exp: {module: "state_game_stats", updatetype: "owerwrite", keyfield: ""},
                    };
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "approve_story_quest",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid
                            story_quest__id: story_quest.id,
                            resources_to_update,
                            current_exp: new_exp_value
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                update_story_quest__multi_ls_and_back: function ($store, cdata, story_quest) {
                    let a_conf = {};
                    
                    console.log("JSON.stringify(story_quest)");
                    console.log(JSON.stringify(story_quest));
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        player_professions: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        quest_manager: {module: "state_game_stats", updatetype: "owerwrite", keyfield: "id"},
                    };
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "set_new_story_quest",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid
                            story_quest: JSON.stringify(story_quest)
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                update_chain_and_prof__multi_ls_and_back: function ($store, cdata, profession_id, chain_quest) {
                    let a_conf = {};
                    
                    console.log("mjlknmlnlm");
                    console.log(chain_quest);
                    console.log("mjlknmlnlm");
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        player_professions: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        quest_manager: {module: "state_game_stats", updatetype: "owerwrite", keyfield: "id"},
                    };
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "update_chain_profession",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            profession_id: profession_id,
                            new_chain: JSON.stringify(chain_quest)
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                
                unlock_extension__multi_ls_and_back: function ($store, cdata) {
                    let a_conf = {};
                    
                    let resources_to_update = {};
                    let extension_usage_limit = null;
                    if(cdata.business_logic.selected_extension_type == "Booster") {
                        extension_usage_limit = cdata.business_logic.selected_extension.usage_limit;
                    }
                    
                    resources_to_update[cdata.business_logic.selected_extension.item_id] = "1";
                    resources_to_update["res_exp"] = -(Number(cdata.business_logic.selected_extension.unlock_cost));
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        player_inventory: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        //перегружается при смене колоды!
                    };
                    //    console.log("RE+++0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "shop_operations_by_rid",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            resources_to_update,
                            extension_usage_limit
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                
                buy_item__multi_ls_and_back: function ($store, cdata) {
                    let a_conf = {};
                    
                    let resources_to_update = {};
                    
//                    let extension_usage_limit = null;
//                    if(cdata.business_logic.selected_extension_type == "Booster") {
//                        extension_usage_limit = cdata.business_logic.selected_extension.usage_limit;
//                    }
                    
                    resources_to_update[cdata.business_logic.selected_resource.item_id] = cdata.business_logic.shop_range_amount;
                    if(!resources_to_update[cdata.business_logic.selected_resource.item_id]){ resources_to_update[cdata.business_logic.selected_resource.item_id] = 0; }
                    
                    let buy_price = Number(cdata.business_logic.shop_range_amount) * Number(cdata.business_logic.selected_resource.buy_price);

                    resources_to_update["res_gold"] = -buy_price;
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        player_inventory: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        //перегружается при смене колоды!
                    };
                    //    console.log("RE+++0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "shop_operations_by_rid",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            resources_to_update
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                sell_item__multi_ls_and_back: function ($store, cdata) {
                    let a_conf = {};
                    
                    let resources_to_update = {};
                    
                    
                    let current_amount_of_buy_res = $store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == cdata.business_logic.selected_resource.item_id);
                    if(current_amount_of_buy_res){ resources_to_update[cdata.business_logic.selected_resource.item_id] = -cdata.business_logic.shop_range_amount; }
                    else { resources_to_update[cdata.business_logic.selected_resource.item_id] = 0; }
                    
                    let buy_price = Number(cdata.business_logic.shop_range_amount) * Number(cdata.business_logic.selected_resource.buy_price);

                    resources_to_update["res_gold"] = buy_price;
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        player_inventory: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        //перегружается при смене колоды!
                    };
                    //    console.log("RE+++0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "shop_operations_by_rid",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            resources_to_update
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                
//                GLOBAL GAME SETTINGS

//                tet: function ($store, cdata) {
//
//                    let a_conf = {};
//                    a_conf['table_names'] = {
//                        //owerwrite insertrows updaterows deleterows
//                        rg_learning: {module: "state_rg", updatetype: "updaterows", keyfield: "id"}, //агрегация выученности по темам для колоды
//                        st_cards: {module: "state_st", updatetype: "insertrows", keyfield: ""}, //какие карты в каких колодах выучаны 
//                        //перегружается при смене колоды!
//                    };
//                    //    console.log("RE+++0");
//                    a_conf['microtime'] = performance.now();
//                    a_conf['params'] = {
//                        method: "on_card_solved",
//                        data: {
//                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
//                            layout_version: $store.state.state_tables.state_layouts.json_layouts.version_timestamp, //layout_version
//                            curr_card: cdata.business_logic.curr_card,
//                            curr_card_is_solved: cdata.business_logic.curr_card_is_solved,
//                            desk_version: cdata.business_logic.desk_version,
//                            pos: cdata.business_logic.pos,
//                            slides: $store.state.slides
//                        }
//                    },
//                            a_conf['server'] = cdata['system']['cnf']['server_action'];
//                    return a_conf;
//                },
                
//                GLOBAL GAME SETTINGS
                

                //CARD_LOGIC

                on_card_solved__multi_ls_and_back: function ($store, cdata, quest_manager, player_current_profession, snotify_obj, lvl_info, player_inventory) {
                    let a_conf = {};
                    
                    
                    let resources_to_update = {
                        "res_exp": Number(cdata.business_logic.card_exp),
                        "res_gold": Number(cdata.business_logic.card_gold)
                    };
                    
                    let extension_usage_limit = null;
                    let chain_to_update = null;
                    
                    if(Number(cdata.business_logic.card_joker) > 0) { resources_to_update['res_joker_coin'] = Number(cdata.business_logic.card_joker); }
                    
                    let current_exp = cdata.business_logic.card_exp + Number($store.state.state_tables.state_game_stats.current_exp.value);
                    let max_exp_allowed = null;
                    
                    for (const [key_level, level_info] of Object.entries($store.state.state_tables.state_game_settings.global__level_settings.levels)) {
                        max_exp_allowed = level_info.exp_max;
                    }
                    
                    if(max_exp_allowed != null) {
                        if(current_exp > max_exp_allowed) { current_exp = max_exp_allowed; }
                    }
                    
                    
//                    QUEST MANAGER
                    let profession_exp = {
                        earned: "0",
                        current: "0",
                        max: "0"
                    };
                    let quests_to_update = {};
                    
                    for (const [item_id, item_amount] of Object.entries(resources_to_update)) {
                        console.log(quest_manager.resources_to_give);
                        if(quest_manager.resources_to_give[item_id]){
                            quest_manager.resources_to_give[item_id].forEach(quest_id => {
                                let quest_to_update = $store.state.state_tables.state_game_stats.quest_manager.find(all_child_quests => all_child_quests.id == quest_id);
                                if(quest_to_update){
                                    let quest_to_update__quest_progress = JSON.parse(quest_to_update.quest_progress);
                                    let quest_to_update__what_to_give = JSON.parse(quest_to_update.what_to_give);
                                    
                                    quest_to_update__quest_progress[item_id] = Number(quest_to_update__quest_progress[item_id]) + Number(item_amount);
                                    if(quest_to_update__quest_progress[item_id] >= quest_to_update__what_to_give[item_id]) {
                                        
            do{
                                        
                                        //DONE QUEST NOTIFIFY
                                        if(cdata.business_logic.curr_card_is_solved){
                                            snotify_obj.success('You just triggered the energy circuit!', 'Good job', {
                                                timeout: 3000,
                                                showProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true
                                            });
                                        }
                                        //END DONE QUEST NOTIFIFY
                                        
//                                        BOOSTER LIMIT UPDATE && CHAIN UPDATE
                                        let quest_to_update__ancestor_data = { ...JSON.parse(quest_to_update.ancestor_data) };
//                                        extension_usage_limit
                                        if(quest_to_update__ancestor_data.Boosters) {
                                            let booster_from_inv = {...self.$root._LAYOUT().find_in_player_inventory(quest_to_update__ancestor_data.Boosters, 'Boosters', player_inventory)};
                                            if(booster_from_inv) {
                                                extension_usage_limit = (booster_from_inv.item_usage_limit == null) ? Number(booster_from_inv.usage_limit) - 1 : Number(booster_from_inv.item_usage_limit) - 1;
                                                resources_to_update[booster_from_inv.item_id] = 0;
                                                if(extension_usage_limit <= 0) {
                                                    if((Number(booster_from_inv.item_count) - 1) > 0){
//                                                        >1 ???
                                                        resources_to_update[booster_from_inv.item_id] = -1;
                                                    } else {
                                                        //BOOSTER ENDED NOTIFIFY
                                                        if(cdata.business_logic.curr_card_is_solved){
                                                            snotify_obj.warning('Booster has ended!', 'Attention', {
                                                                timeout: 3000,
                                                                showProgressBar: true,
                                                                closeOnClick: true,
                                                                pauseOnHover: true
                                                            });
                                                        }
                                                        //END BOOSTER ENDED NOTIFIFY
                                                        
                                                        resources_to_update[booster_from_inv.item_id] = -1;
                                                        quest_to_update__ancestor_data.Formulas = self.$root._LAYOUT().find_in_player_inventory(quest_to_update__ancestor_data.Formulas, 'Formulas', player_inventory);
                                                        quest_to_update__ancestor_data.Items = self.$root._LAYOUT().find_in_player_inventory(quest_to_update__ancestor_data.Items, 'Items', player_inventory);
                                                        quest_to_update__ancestor_data.Boosters = null;
                                                        
                                                        chain_to_update = {...self.$root._LAYOUT().form_chain_format_with_ids_for_db(quest_to_update__ancestor_data)};
                                                        
                                                        chain_to_update = JSON.stringify(chain_to_update);
                                                    }
                                                    extension_usage_limit = booster_from_inv.usage_limit;
                                                    

                                                }
                                                //item_usage_limit //players counter
                                                //usage_limit //general settings
                                                //item_count// number of booster in inv
                                                
                                            }
                                        }
//                                        END BOOSTER LIMIT UPDATE && CHAIN UPDATE
                                        
                                        let quest_to_update__what_to_get = JSON.parse(quest_to_update.what_to_get);
                                        for (const [item_id, item_amount] of Object.entries(quest_to_update__what_to_get)) {
                                            if(item_id == 'prof_exp'){
                                                profession_exp["earned"] = (Number(profession_exp["earned"]) + Number(item_amount)).toString();
                                            } else {
                                                if(!resources_to_update[item_id]) { resources_to_update[item_id] = 0; }
                                                resources_to_update[item_id] += Number(item_amount);
                                            }
                                            
                                        }
                                        
                                        quest_to_update__quest_progress[item_id] = Number(quest_to_update__quest_progress[item_id]) - Number(quest_to_update__what_to_give[item_id]);
        } while(quest_to_update__quest_progress[item_id] >= quest_to_update__what_to_give[item_id])
                                        if(quest_to_update__quest_progress[item_id] < 0) { quest_to_update__quest_progress[item_id] = 0; }
                                    }
                                    
                                    
                                    quest_to_update__quest_progress[item_id] = quest_to_update__quest_progress[item_id].toString();
                                    quest_to_update.quest_progress = JSON.stringify(quest_to_update__quest_progress);
                                    
                                    quests_to_update[quest_to_update.id] = { ...quest_to_update };
                                }
                                
                            });
                        }
                    }
                    
//                    check proffesion max_exp
                        profession_exp["current"] = Number(profession_exp["earned"]) + Number(player_current_profession.lvl_info.current_exp);
                        
                        if(player_current_profession.lvl_info.current_lvl == player_current_profession.max_level){
                            if(Number(profession_exp["current"]) >= Number(player_current_profession.lvl_info.current_lvl_max)) {
                                profession_exp["current"] = player_current_profession.lvl_info.current_lvl_max;
                            }
                        }
                    
//                    END QUEST MANAGER


                        //LVL UP NOTIFIFY
                        let new_res_exp = Number(resources_to_update.res_exp) + Number(lvl_info.current_exp);
                        if(new_res_exp >= Number(lvl_info.current_lvl_max) &&
                           Number(lvl_info.current_lvl) < Number(lvl_info.max_lvl_from_settings) &&
                           cdata.business_logic.curr_card_is_solved){
                       
                            snotify_obj.info("You've just leveled up!", 'Good news!', {
                                timeout: 3000,
                                showProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        }
                        //END LVL UP NOTIFIFY
                        
                        //PROF LVL UP NOTIFIFY
                        if(profession_exp["current"] >= Number(player_current_profession.lvl_info.current_lvl_max) &&
                            Number(player_current_profession.lvl_info.current_lvl) < Number(player_current_profession.max_level) &&
                           cdata.business_logic.curr_card_is_solved){
                        
                            snotify_obj.info("Profession lvl " + (Number(player_current_profession.lvl_info.current_lvl)+1) + ' achieved!', player_current_profession.name + ' LVL UP!', {
                                timeout: 3000,
                                showProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        }
                        //END PROF LVL UP NOTIFIFY
                        
                        console.log("bhjbkjhbkjvtyuc");
                        console.log(resources_to_update);
                        console.log(quests_to_update);
                        console.log(extension_usage_limit);
                        console.log(chain_to_update);
                        console.log("bhjbkjhbkjvtyuc");
                    
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        rg_learning: {module: "state_rg", updatetype: "updaterows", keyfield: "id"}, //агрегация выученности по темам для колоды
                        st_cards: {module: "state_st", updatetype: "insertrows", keyfield: ""}, //какие карты в каких колодах выучаны 
                        current_exp: {module: "state_game_stats", updatetype: "owerwrite", keyfield: ""},
                        player_inventory: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        quest_manager: {module: "state_game_stats", updatetype: "owerwrite", keyfield: "id"},
                        player_professions: {module: "state_game_stats", updatetype: "updaterows", keyfield: "id"},
                        //перегружается при смене колоды!
                    };
                    //    console.log("RE+++0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "on_card_solved",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid lid 
                            layout_version: $store.state.state_tables.state_layouts.json_layouts.version_timestamp, //layout_version
                            curr_card: cdata.business_logic.curr_card,
                            curr_card_is_solved: cdata.business_logic.curr_card_is_solved,
                            desk_version: cdata.business_logic.desk_version,
                            pos: cdata.business_logic.pos,
                            slides: $store.state.slides,
                            current_exp: current_exp,
                            resources_to_update,
                            quests_to_update,
                            profession_exp,
                            extension_usage_limit,
                            chain_to_update
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                delete_st_and_rg__multi_ls_and_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_names'] = {
                        //owerwrite insertrows updaterows deleterows
                        rg_learning: {module: "state_rg", updatetype: "deleterows", keyfield: "id"}, //агрегация выученности по темам для колоды
                        st_cards: {module: "state_st", updatetype: "deleterows", keyfield: "id_st_card"}, //какие карты в каких колодах выучаны 
                        //перегружается при смене колоды!
                    };
                    //    console.log("RE+++0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "delete_st_and_rg",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth, // rid   lid
                            layout_version: $store.state.state_tables.state_layouts.json_layouts.version_timestamp, //layout_version
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
            }
        },
        
        _AUTH_LOGIC: function () {
            var self = this;
            return {
                
                get_word_color: function ($store, cdata, word_color) {

                    let res = " ";
                    switch (word_color) {
                        case "main":
                            res = " color: #ffb699; ";
                            break;
                        case "unit":
                            res = " color: #9ACD32; ";
                            break;
                        case "common":
                            res = " color: #FFE4C4; ";
                            break;
                    }

                    return res;
                },
                
                set_pair_qr_by_curr_slide: function ($store, cdata) {

                    for (const [key, value] of Object.entries(cdata.business_logic.all_cards)) {
                        if (value.num === cdata.business_logic.curr_card.curr_slide)
                        {
                            cdata.business_logic.pair_qr = key;
                            cdata.business_logic.curr_card.pair_qr = key;

                            return;
                        }
                    }
                },
                
                check_is_chapter_qr_purchased_out: function ($store, cdata) {

                   // cdata.business_logic.is_purchased = false;
                    for (const [serie_code, obj_serie] of Object.entries(cdata.business_logic['json_catalog'].series)) {


                        for (const [part_code, obj_parts] of Object.entries(obj_serie.parts)) {

                            //по всем эпизодам
                            for (const [episod_key, r_episod] of Object.entries(obj_parts.episods)) {


                                for (const [chapter_key, r_chapter] of Object.entries(r_episod.chapters)) {


                                    if (cdata.business_logic.chapter_qr.toString() === r_chapter.chapter_qr.toString())
                                    {
                                        console.log(cdata.business_logic.chapter_qr.toString());
                                        console.log(r_chapter.chapter_qr.toString());

                                        console.log("is_purchased");
                                        cdata.business_logic.book_info.chapter_qr = cdata.business_logic.chapter_qr;
                                        cdata.business_logic.book_info.chapter_name = r_chapter.chapter_name;
                                        cdata.business_logic.book_info.chapter_code = r_chapter.chapter_code;
                                        cdata.business_logic.book_info.episod_name = r_episod.episod_name;
                                        cdata.business_logic.book_info.episod_code = r_episod.episod_code;
                                        
                                        cdata.business_logic.book_info.part_num = obj_parts.part_num;
                                        cdata.business_logic.book_info.part_name = obj_parts.part_name;
                                        cdata.business_logic.book_info.part_code = obj_parts.part_code;
                                        cdata.business_logic.book_info.serie_name = obj_serie.serie_name;
                                        cdata.business_logic.book_info.serie_code = obj_serie.serie_code;

                                      /*  if (r_chapter.is_chapter_purchased)
                                        {*/
                                            cdata.business_logic.is_purchased = true;
                                      /*  }*/

                                        return;
                                    }


                                }



                            }





                        }

                    }


                },
                
                watcher__curr_card__curr_slide_change: function ($store, cdata) {



                  /*  if (!cdata.business_logic.is_reversed)
                    {//сбросим если не фиксируется режим перевода
                        cdata.business_logic.mode = "el";
                    } else
                    {
                        cdata.business_logic.mode = "bl";
                    }*/


                    console.log("load:" + cdata.business_logic.curr_card.curr_slide);

                    if (cdata.business_logic.curr_card.curr_slide !== -1)
                    {
                        //наоборот выставим нужный слайд pair_qr по curr_slide 
                        self._AUTH_LOGIC().set_pair_qr_by_curr_slide($store, cdata);
                        //подобрался pair_qr для curr_slide
                        console.log("curr_slide watcher|" + cdata.business_logic.curr_card.pair_qr);
                        
                        
                 /*       
                        console.log("LOOK!"); 
                        let bg_audio_link_pstfx=cdata.business_logic.all_cards[cdata.business_logic.curr_card.pair_qr].bg_audio_link_pstfx;
                        let way= '/../../dataset/'+cdata.business_logic.book_info.serie_code+'/'+cdata.business_logic.book_info.episod_code+'/'+ cdata.business_logic.book_info.chapter_code +'/book/sound/'+cdata.business_logic.book_info.chapter_code+'_m'+bg_audio_link_pstfx+'.mp3'+'?t='+self._SYS().add_v();
                        cdata.business_logic.track="";
                        cdata.business_logic.track=way;
                        console.log(way);
                        console.log(self.$refs);
                        self.$refs.myAudio[0].play();
                    */
                    }
                },
                
                set_all_cards_for_book: function ($store, cdata) {


                    cdata.business_logic['all_cards'] = JSON.parse(JSON.stringify($store.state.state_tables.state_cards.json_cards.cards));


                        //заминусить те, что не приобретены!


                    //количество всего   
                    cdata.business_logic.cnt_all_cards = Object.keys(cdata.business_logic['all_cards']).length;


                        //проставить дополнительные данные для cdata.business_logic.all_cards
                    let num = 0;
                    for (const [key_card, value_card] of Object.entries(cdata.business_logic.all_cards)) {

                        Vue.set(value_card, "pair_qr", key_card);
                        Vue.set(value_card, "num", num);



                        for (const [key_block, value_block] of Object.entries(value_card.blocks)) {

                            //"sdfsdf sdf sdf (text2) sdfdsf (text3)(text4) sdfsdf (text5)"
                            let text_el_parse = value_block['text_' + cdata.system.cnf.el].split(/[{}]+/).filter(function (e) {
                                return e; 
                            });
                           
                            let text_el_parse_fin=[];
                            for (let v of text_el_parse) {  
                               // console.log("-----");
                               // console.log(v);
                                v=window._STR().trim(v, " "); 
                               // console.log(v);
                                if (window._STR().is_not_empty(v))
                                {
                                    if (v.includes("|"))
                                    {
                                       text_el_parse_fin.push(v); 
                                    }
                                    else
                                    {
                                        const words = v.split(" ");
                                        for (let w of words) { 
                                            text_el_parse_fin.push(w); 
                                            text_el_parse_fin.push(" "); 
                                        }
                                        
                                    } 
                                }
                                
                            }      
                            console.log(text_el_parse_fin);
                            
                            
                            let text_bl_parse = value_block['text_' + cdata.system.cnf.bl].split(/[{}]+/).filter(function (e) {
                                return e;
                            });
                            
                            let text_bl_parse_fin=[];
                            for (let v of text_bl_parse) {  
                               // console.log("-----");
                               // console.log(v);
                                v=window._STR().trim(v, " "); 
                               // console.log(v);
                                if (window._STR().is_not_empty(v))
                                {
                                    if (v.includes("|"))
                                    {
                                       text_bl_parse_fin.push(v); 
                                    }
                                    else
                                    {
                                        const words = v.split(" ");
                                        for (let w of words) { 
                                            text_bl_parse_fin.push(w); 
                                            text_bl_parse_fin.push(" "); 
                                        }
                                        
                                    } 
                                }
                                
                            }      
                            console.log(text_bl_parse_fin);

                            Vue.set(value_block, "text_el_parse", text_el_parse_fin);
                            Vue.set(value_block, "text_bl_parse", text_bl_parse_fin);
                            Vue.set(value_block, "block_mode", 'el');
                            
                            
                            if(value_block.quest_data){
                                console.log("AAAAAAAAA+CHECK QUEST");
                                console.log(value_block.quest_data.what_to_give);
                                let quest_data__for_display = { ...self._LAYOUT().form_story_quests__for_display(value_block.quest_data, cdata.business_logic.chapter_qr, $store) };
//                                value_block.quest_data__for_display = quest_data__for_display;
                                Vue.set(value_block, "quest_data__for_display", quest_data__for_display);
                                
                                
//                                add ancestor => quest id
//                                    check if quest id exists in state.quest_manager (means that person has taken this quest)
//                                        if true => set complete button, and check inv (quest_progress => dinamicly check inv status)
                            }
                        }



                        num++;
                    }


                    //потом отмечать выученность... пока необязательно!



                    // = set;


                },
            }
        },
        
        _LOAD: function () {
            var self = this;
            return {
                
                json_cards__back: function ($store, cdata) {
                    let a_conf = {};
                    a_conf['table_name'] = "json_cards";
                    a_conf['table_module'] = "state_cards";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: cdata.business_logic.url_cards_prefix + "?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                json_catalog__back: function ($store, cdata) {
                    let a_conf = {};
                    a_conf['table_name'] = "json_catalog";
                    a_conf['table_module'] = "state_books";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/story_settings/catalog.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },

                json_layout_xxxx__pos_xx__ls_or_back: function ($store, cdata, table_name, version) {

                    let a_conf = {};
                    a_conf['table_name'] = table_name;
                    a_conf['table_module'] = "state_desks";
                    a_conf['table_version'] = version;
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud'];
                    a_conf['params'] = {
                        link: "/json_layouts_output/" + $store.state.state_tables.state_layouts.json_layouts['version_timestamp'] + "/" + table_name.substring(5) + ".json?v=" + self._SYS().add_v(), /*  + "__v_" + version + " */
                    };
                    return a_conf;
                },
                json_version__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "json_version";
                    a_conf['table_module'] = "state_manage";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    console.log("LLL "+cdata['system']['cnf']['server_cloud2']);
                    a_conf['params'] = {
//                        link: "/json_layouts_output/version.json?v=" + self._SYS().add_v(),
                        link: "/version.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                json_layouts__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "json_layouts";
                    a_conf['table_module'] = "state_layouts";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud'];
                    a_conf['params'] = {
                        link: "/json_layouts_output/layouts.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
  
//                GLOBAL_SETTINGS
//                GLOBAL_SETTINGS

                json___inventory_glossary__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "global__inventory_glossary";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/inventory_glossary.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                json___profession_list__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "global__profession_list";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/profession_list.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                json___level_props__exp_by_tasks__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "level_props__exp_by_tasks";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/level_props/exp_by_tasks.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                
                json___level_props__gold_by_tasks__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "level_props__gold_by_tasks";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/level_props/gold_by_tasks.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                json___level_props__joker_by_tasks__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "level_props__joker_by_tasks";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/level_props/joker_by_tasks.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                
                json___level_props__solve_limit__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "level_props__solve_limit";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/level_props/solve_limit.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                
                json___global__level_settings__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "global__level_settings";
                    a_conf['table_module'] = "state_game_settings";
                    a_conf['microtime'] = performance.now();
                    a_conf['server_cloud'] = cdata['system']['cnf']['server_cloud2'];
                    a_conf['params'] = {
                        link: "/global_eo_settings/level_settings.json?v=" + self._SYS().add_v(),
                    };
                    return a_conf;
                },
                
                quest_manager__ls_or_back: function ($store, cdata) {
                    
                    let default_formula_id = $store.state.state_tables.state_game_settings.global__profession_list.profession_list['0'].prof_extensions.Formulas['1'];
                    let default_chain = null;
                    for (const [formula_index, formula_data] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas)) {
                        if(default_formula_id == formula_data.item_id) {
                            let quest_progress = {};
                            let ancestor_data = {
                                "Boosters":null,
                                "Formulas":default_formula_id,
                                "Items":null
                            };

                            for (const [item_name, item_amount] of Object.entries(formula_data.what_to_give)) {
                                quest_progress[item_name] = "0";
                            }
                            default_chain = {
                                ...{"what_to_get": formula_data.what_to_get},
                                ...{"what_to_give": formula_data.what_to_give},
                                ...{"quest_progress": quest_progress},
                                ...{"ancestor_data": ancestor_data}
                            };
                            
                            break;
                        }
                    }
                    
                    if(default_chain == null){
                        let quest_progress = {};
                        
                        let ancestor_data = {
                            "Boosters":null,
                            "Formulas":$store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas['0'].item_id,
                            "Items":null
                        };
                        
                        for (const [item_name, item_amount] of Object.entries($store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas['0'].what_to_give)) {
                            quest_progress[item_name] = "0";
                        }
                        default_chain = {
                            ...{"what_to_get": $store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas['0'].what_to_get},
                            ...{"what_to_give": $store.state.state_tables.state_game_settings.global__inventory_glossary.glossary.prof_formulas['0'].what_to_give},
                            ...{"quest_progress": quest_progress},
                            ...{"ancestor_data": ancestor_data}
                        };
                    }
                    
                    
                    let crid = localStorage.getItem("rid");
                    let rid = window._CRYPT().decrypt_json(crid);
                    let a_conf = {};
                    a_conf['table_name'] = "quest_manager";
                    a_conf['table_module'] = "state_game_stats";
                    a_conf['microtime'] = performance.now();

                    let client_timestamp_utc = Math.floor(new Date().getTime() / 1000.0);
                    console.log(client_timestamp_utc + "|client_timestamp_utc");

                    a_conf['params'] = {
                        method: "get_quest_manager",
                        data: {
                            rid: rid,
                            default_chain: JSON.stringify(default_chain),
                            client_timestamp_utc: client_timestamp_utc,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                player_professions__ls_or_back: function ($store, cdata) {
                    let crid = localStorage.getItem("rid");
                    let rid = window._CRYPT().decrypt_json(crid);
                    let a_conf = {};
                    a_conf['table_name'] = "player_professions";
                    a_conf['table_module'] = "state_game_stats";
                    a_conf['microtime'] = performance.now();

                    let client_timestamp_utc = Math.floor(new Date().getTime() / 1000.0);
                    console.log(client_timestamp_utc + "|client_timestamp_utc");

                    a_conf['params'] = {
                        method: "get_player_professions",
                        data: {
                            rid: rid,
                            default_profession: $store.state.state_tables.state_game_settings.global__profession_list.profession_list['0'].prof_id,
                            client_timestamp_utc: client_timestamp_utc,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                
                player_exp__ls_or_back: function ($store, cdata) {
                    let crid = localStorage.getItem("rid");
                    let rid = window._CRYPT().decrypt_json(crid);
                    let a_conf = {};
                    a_conf['table_name'] = "current_exp";
                    a_conf['table_module'] = "state_game_stats";
                    a_conf['microtime'] = performance.now();

                    let client_timestamp_utc = Math.floor(new Date().getTime() / 1000.0);
                    console.log(client_timestamp_utc + "|client_timestamp_utc");

                    a_conf['params'] = {
                        method: "get_player_exp",
                        data: {
                            rid: rid,
                            client_timestamp_utc: client_timestamp_utc,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    
                    return a_conf;
                },
                
                player_inventory__ls_or_back: function ($store, cdata) {
                    let crid = localStorage.getItem("rid");
                    let rid = window._CRYPT().decrypt_json(crid);
                    let a_conf = {};
                    a_conf['table_name'] = "player_inventory";
                    a_conf['table_module'] = "state_game_stats";
                    a_conf['microtime'] = performance.now();

                    let client_timestamp_utc = Math.floor(new Date().getTime() / 1000.0);
                    console.log(client_timestamp_utc + "|client_timestamp_utc");

                    a_conf['params'] = {
                        method: "get_player_inventory",
                        data: {
                            rid: rid,
                            client_timestamp_utc: client_timestamp_utc,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    
                    return a_conf;
                },
                
//                GLOBAL_SETTINGS
//                GLOBAL_SETTINGS
                
                r_auth__ls_or_back: function ($store, cdata) {


                    let crid = localStorage.getItem("rid");
                    let rid = window._CRYPT().decrypt_json(crid);
                    let a_conf = {};
                    a_conf['table_name'] = "r_auth";
                    a_conf['table_module'] = "state_auth";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();

                    //moment.locale('ru');
                    let client_timestamp_utc = Math.floor(new Date().getTime() / 1000.0);
                    console.log(client_timestamp_utc + "|client_timestamp_utc");

                    a_conf['params'] = {
                        method: "get_r_auth",
                        data: {
                            rid: rid,
                            client_timestamp_utc: client_timestamp_utc,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },

                shift_list__back: function ($store, cdata) {



                    let a_conf = {};
                    a_conf['table_name'] = "shift_list";
                    a_conf['table_module'] = "state_manage";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_shift_list",
                        data: {
                            subject: "math",
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },

                events_list__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "events_list";
                    a_conf['table_module'] = "state_manage";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_events_list",
                        data: {
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },
                
//                TEST DMITRY
                test1__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "a_test";
                    a_conf['table_module'] = "state_test";
                    
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_a_test",
                        data: {
                            
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },
                
                test2__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "a_test2";
                    a_conf['table_module'] = "state_test";
                    
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_a_test2",
                        data: {
                            
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },
                
                test3__back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "test3";
                    a_conf['table_module'] = "state_test";
                    
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_test3",
                        data: {
                            
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },
//                TEST DMITRY
                
                st_cards__ls_or_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "st_cards";
                    a_conf['table_module'] = "state_st";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_st_cards",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth,
                            layout_version: $store.state.state_tables.state_layouts.json_layouts.version_timestamp,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //console.log(a_conf['params']);


                    return a_conf;
                },
                rg_learning__ls_or_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "rg_learning";
                    a_conf['table_module'] = "state_rg";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_rg_learning",
                        data: {
                            r_auth: $store.state.state_tables.state_auth.r_auth,
                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    //   console.log(a_conf['params']);


                    return a_conf;
                },
                a_test__ls_or_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "a_test";
                    a_conf['table_module'] = "state_test";
                    //console.log("step_orger+++" + a_conf['table_name'] + "|0");
                    a_conf['microtime'] = performance.now();
                    a_conf['params'] = {
                        method: "get_a_test",
                        data: {

                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
                a_test2__ls_or_back: function ($store, cdata) {

                    let a_conf = {};
                    a_conf['table_name'] = "a_test2";
                    a_conf['table_module'] = "state_test";
                    a_conf['params'] = {
                        method: "get_a_test2",
                        data: {

                        }
                    },
                            a_conf['server'] = cdata['system']['cnf']['server_action'];
                    return a_conf;
                },
            }
        },
    },
    computed: {
    },
};
window._OBJ = function () {
    var self = this;
    return {

        is_empty: function (value) {
            return (
                    // null or undefined
                            (value == null) ||
                            // has length and it's zero
                                    (value.hasOwnProperty('length') && value.length === 0) ||
                                    // is an Object and has no keys
                                            (value.constructor === Object && Object.keys(value).length === 0)
                                            )
                        },
//Returns:
//true: undefined, null, "", [], {}
//false: true, false, 1, 0, -1, "foo", [1, 2, 3], { foo: 1 }

                        is_not_empty: function (value) {
                            return !window._OBJ().is_empty(value);
                        },
                    }
                };
                window._MATH = function () {
                    var self = this;
                    return {
                        rand: function (min, max) {
                            //включительно
                            min = Math.ceil(min);
                            max = Math.floor(max);
                            return Math.floor(Math.random() * (max - min + 1) + min);
                        },
                    }
                };
                window._STR = function () {
                    var self = this;
                    return {

                        ltrim: function (str, charlist) {
                            //  discuss at: https://locutus.io/php/ltrim/
                            // original by: Kevin van Zonneveld (https://kvz.io)
                            //    input by: Erkekjetter
                            // improved by: Kevin van Zonneveld (https://kvz.io)
                            // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
                            //   example 1: ltrim('    Kevin van Zonneveld    ')
                            //   returns 1: 'Kevin van Zonneveld    '
                            charlist = !charlist
                                    ? ' \\s\u00A0'
                                    : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
                            const re = new RegExp('^[' + charlist + ']+', 'g')
                            return (str + '')
                                    .replace(re, '')
                        },

                        trim: function (str, charlist) {
                            //  discuss at: https://locutus.io/php/trim/
                            // original by: Kevin van Zonneveld (https://kvz.io)
                            // improved by: mdsjack (https://www.mdsjack.bo.it)
                            // improved by: Alexander Ermolaev (https://snippets.dzone.com/user/AlexanderErmolaev)
                            // improved by: Kevin van Zonneveld (https://kvz.io)
                            // improved by: Steven Levithan (https://blog.stevenlevithan.com)
                            // improved by: Jack
                            //    input by: Erkekjetter
                            //    input by: DxGx
                            // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
                            //   example 1: trim('    Kevin van Zonneveld    ')
                            //   returns 1: 'Kevin van Zonneveld'
                            //   example 2: trim('Hello World', 'Hdle')
                            //   returns 2: 'o Wor'
                            //   example 3: trim(16, 1)
                            //   returns 3: '6'
                            let whitespace = [
                                ' ',
                                '\n',
                                '\r',
                                '\t',
                                '\f',
                                '\x0b',
                                '\xa0',
                                '\u2000',
                                '\u2001',
                                '\u2002',
                                '\u2003',
                                '\u2004',
                                '\u2005',
                                '\u2006',
                                '\u2007',
                                '\u2008',
                                '\u2009',
                                '\u200a',
                                '\u200b',
                                '\u2028',
                                '\u2029',
                                '\u3000'
                            ].join('')
                            let l = 0
                            let i = 0
                            str += ''
                            if (charlist) {
                                whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
                            }
                            l = str.length
                            for (i = 0; i < l; i++) {
                                if (whitespace.indexOf(str.charAt(i)) === -1) {
                                    str = str.substring(i)
                                    break
                                }
                            }
                            l = str.length
                            for (i = l - 1; i >= 0; i--) {
                                if (whitespace.indexOf(str.charAt(i)) === -1) {
                                    str = str.substring(0, i + 1)
                                    break
                                }
                            }
                            return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
                        },
                        clean: function (str) {
                            if (window._STR().is_empty(str))
                            {
                                str = "";
                            }

                            str = str.trim();
                            //str= window._STR().trim(str, "&nbsp;");
                            //var re = new RegExp(String.fromCharCode(160), "g");
                            //console.log(str);
                            str = str.replace(/&nbsp;/gi, '');
                            //console.log(str);  


                            return str;
                        },
                        clean_with_space: function (str) {
                            if (window._STR().is_empty(str))
                            {
                                str = "";
                            }

                            str = str.trim();
                            //str= window._STR().trim(str, "&nbsp;");
                            //var re = new RegExp(String.fromCharCode(160), "g");
                            //console.log(str);
                            str = str.replace(/&nbsp;/gi, ' ');
                            //console.log(str);  


                            return str;
                        },
                        is_empty: function (str) {

                            if (str !== null && str !== '' && str !== undefined)
                            {
                                return false;
                            }

                            return true;
                        },
                        is_not_empty: function (str) {

                            if (str !== null && str !== '' && str !== undefined)
                            {
                                return true;
                            }

                            return false;
                        },
                        lid0000: function (current_deskweek) {
                            //json_layout_0002__pos_02
                            let temp1 = current_deskweek.split('__');
                            let temp2 = temp1[0].split('_');
                            return temp2[2];
                        },
                        pos00: function (current_deskweek) {
                            //json_layout_0002__pos_02

                            let temp1 = current_deskweek.split('__');
                            let temp2 = temp1[1].split('_');
                            return temp2[1];
                        }
                    }
                };
                window._CRYPT = function () {
                    var self = this;
                    return {

                        encrypt_str: function (str) {
                            var crypto = require('crypto');
                            var mykey = crypto.createCipher('aes-128-cbc', cnf['pd_']);
                            var mystr = mykey.update(str, 'utf8', 'hex')
                            mystr += mykey.final('hex');
                            return mystr;
                        },
                        decrypt_str: function (cstr) {
                            var crypto = require('crypto');
                            var mykey = crypto.createDecipher('aes-128-cbc', cnf['pd_']);
                            var mystr = mykey.update(cstr, 'hex', 'utf8')
                            mystr += mykey.final('utf8');
                            return mystr;
                        },
                        encrypt_json: function (json_data) {
                            var str = JSON.stringify(json_data);
                            var crypto = require('crypto');
                            var mykey = crypto.createCipher('aes-128-cbc', cnf['pd_']);
                            var mystr = mykey.update(str, 'utf8', 'hex')
                            mystr += mykey.final('hex');
                            return mystr;
                        },
                        decrypt_json: function (cjson_data) {


                            var crypto = require('crypto');
                            var mykey = crypto.createDecipher('aes-128-cbc', cnf['pd_']);
                            var mystr = mykey.update(cjson_data, 'hex', 'utf8')
                            mystr += mykey.final('utf8');
                            var json = JSON.parse(mystr);
                            return json;
                        },
                    }
                };
                var game = new Vue(_gameObj).$mount('#app');
//                var game = new Vue(_gameObj);
//                router.onReady(() => game.$mount('#app'))
                /*
                 
                 */