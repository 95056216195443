//add dinamic tables
import axios from 'axios';
import api_idb from '@/api/idb' 
 
const state = {
  
};
  
 const getters = { }; 
  
const mutations = {
     
    
    
};
  
const actions = {
    
    vuex_set__is_all_fin_for_this_page_with_additional( {commit}, conf) { 
        commit('setvx__is_all_fin_for_this_page_with_additional', conf, {root: true}); 
    },


    vuex_set__is_all_fin_for_this_page_with_additional_executed( {commit}, conf) { 
        commit('setvx__is_all_fin_for_this_page_with_additional_executed', conf, {root: true}); 
    },

    vuex_set__is_all_fin_for_this_page_executed( {commit}, conf) { 
        commit('setvx__is_all_fin_for_this_page_executed', conf, {root: true}); 
    },
    
    
    
    
    
    
    vuex_set__is_all_fin_for_this_page( {commit}, conf) { 
        commit('setvx__is_all_fin_for_this_page', conf, {root: true}); 
    },


    vuex_set__is_mounted_execute( {commit}, conf) { 
        commit('setvx__is_mounted_execute', conf, {root: true}); 
    },
    
    vuex_set__page_v ({ commit, rootState }, conf) { 
        commit('setvx__page_v', conf['page_v'], { root: true }); 
    },
    
     
    vuex_set__is_active_timer_check_shift ({ commit, rootState }, is_active_timer_check_shift) {
       
       commit('setvx__is_active_timer_check_shift', is_active_timer_check_shift, { root: true }); 
   },  
     
 vuex_set__timings ({ commit, rootState }, curr_event) {
       
       commit('setvx__curr_event', curr_event, { root: true }); 
   },
     
 vuex_set__curr_timestamp ({ commit, rootState }, conf) {
       
        let curr_timestamp={}
             curr_timestamp['curr_timestamp']=conf['curr_timestamp']; 
       commit('setvx__curr_timestamp', curr_timestamp, { root: true }); 
   },
     
     
 vuex_set__lock_clear ({ commit, rootState }, conf) {
       
        let lock={}
             lock['method']=conf['method'];
             lock['value']='';  
       commit('setvx__lock_action', lock, { root: true }); 
   },
   
      
  async  vuex_set__route_({commit}, _route_) {
      commit('setvx__route_', _route_, { root: true });
  },
 
 vuex_set__default_table_flags({commit}, conf) {
        
      let table={};
      //table['_route_'] = conf['_route_'];
      table['table_name'] = conf['table_name'];
      table['is_loaded_tables'] = {};
      table['is_loaded_tables']['load_status']=false;
      table['is_loaded_tables']['error_send']=false;
      table['is_loaded_tables']['error_api']=false;
      table['is_loaded_tables']['error_idb_load']=false;
      table['is_loaded_tables']['error_idb_save']=false; 
      
      commit('setvx__flag_a_data', table, { root: true });
     
    },
 
     
    vuex_set__stage({commit}, conf) {
    
      commit('setvx__stage', conf, { root: true });
     
    },
 
    
    vuex_set__cnt_plus_plus({commit}, conf) {
        
      let element={};
      commit('setvx__cnt_plus_plus', element, { root: true });
     
    },
    
     vuex_set__is_all_stage_fin({commit}, conf) {
        
      let element={};
      element['is_all_stage_fin']=conf['is_all_stage_fin']
      commit('setvx__is_all_stage_fin', element, { root: true });
     
    },
 
    
    
     
    
};
 
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}