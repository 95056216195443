//add dinamic tables
import axios from 'axios';
import api_idb from '@/api/idb'

        const state = {

        };

const getters = {};

const mutations = {
  


};

const actions = {

    async  vuex_set__current_deskweek( {commit}, conf) { 
            commit('setvx__current_deskweek', conf, {root: true}); 
        },
        
        
    async  vuex_add__slide( {commit}, conf) {  
            commit('setvx__add_slide', conf, {root: true});  
        },   
        
    async  vuex_clear__slide( {commit}, conf) {  
            commit('setvx__clear_slide', conf, {root: true});  
        },     





   

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}